export interface ChipProps {
  children: React.ReactNode | string;
  chipType?: ChipType;
  primary?: boolean;
  secondary?: boolean;
  success?: boolean;
  error?: boolean;
  selected?: boolean;
  className?: string;
  as?: any;
  onClick?: (event?: any) => void;
}
export interface StyledChipProps {
  primary?: boolean;
  secondary?: boolean;
  success?: boolean;
  error?: boolean;
  selected?: boolean;
}

export enum ChipType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  ERROR = 'error',
  OUTLINE = 'outline',
  COVERAGE = 'coverage',
}
