/* eslint-disable react/display-name */
import React, { FC } from 'react';
import { HeadingProps } from './types';

export const Heading: FC<HeadingProps> = ({ children, level, className, color }) => {
  const HeadingTag: any = level ? `h${level}` : 'h4';
  return (
    <div>
      <HeadingTag
        className={`heading heading--h${level} heading--${color || ''} ${className || 'mb-4'}`}
      >
        {children}
      </HeadingTag>
    </div>
  );
};
