import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';
import { Button, Icon, TrustBox } from '@components/Atoms';
import { mockTrustBoxDataMini } from '@components/Atoms/TrustBox/__mockData__';
import useBasketContext from '@hooks/useBasketContext';
import { ButtonType } from '@components/Atoms/Button/types';
import checkIcon from '@public/icons/Check-3.svg';
import { ChoosePackage } from '../ChoosePackage/ChoosePackage';
import BennyLeftHead from '@public/benny-left-head.png';
import { StickyCheckoutFooter } from '@components/Molecules/StickyCheckoutFooter/StickyCheckoutFooter';
import usePostcodeContext from '@hooks/usePostcodeContext';
import { ProductT } from '@lib/types';
import { handleConditionalExecution } from '@lib/utils/conditionalExecution';

interface AddonsI {
  display: boolean;
  addonName: string;
  title: string;
  subtitle?: string;
  bodyContent?: string;
  trustpilot?: boolean;
  addonOne: string;
  addonTwo: string;
  products: ProductT[];
}

const STYLEDADDON = {
  wrapper: styled.div`
    background: var(--color-white);
    box-shadow: rgba(116, 116, 196, 0.4) 0px 2px 10px inset;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 2rem;
  `,
  content: styled.div`
    .addon {
      &__title {
        font-size: ${rem(20)};
        color: var(--color-primary);
        font-weight: 700;
        margin-bottom: 0;

        @media ${breakpoints.tablet} {
          font-size: ${rem(24)};
          line-height: 1;
        }
      }

      &__subtitle {
        color: var(--color-grey);
        opacity: 0.7;
        margin-bottom: 0;
      }

      &__price {
        font-size: ${rem(60)};
        color: var(--color-black);
        font-weight: 700;
        margin-bottom: 0;

        span {
          font-size: ${rem(22)};
          color: var(--color-grey);
          font-weight: 400;
        }
      }
    }

    hr {
      border-color: var(--color-grey);
      margin-bottom: 1rem;
    }
  `,
  button: styled.div`
    margin-top: auto;
    button {
      div {
        padding: 0 3rem;
      }

      &.selected {
        color: var(--color-white);

        div {
          background-color: var(--color-primary);
          transition: none;
        }
      }
    }
  `,
};

const STYLED = {
  wrapper: styled.section`
    display: grid;
    grid-template-columns: 1rem 1fr;
    padding-top: 0;
    overflow: hidden;
  `,
  content: styled.div`
    background-color: var(--color-blue);
    box-shadow: rgba(116, 116, 196, 0.4) 0px 5px 19px;
    border-radius: 20px 0 0 20px;
    padding: 2rem;
    grid-column: 2 / 3;

    @media ${breakpoints.desktop} {
      display: flex;
      flex-direction: row;
      gap: 4rem;
      align-items: flex-start;
      position: relative;
      box-shadow: rgba(116, 116, 196, 0.4) 4px 5px 19px;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--color-blue);
        right: -99%;
        top: 0;
        box-shadow: rgba(116, 116, 196, 0.4) 20px 5px 19px;
      }
    }

    @media ${breakpoints.desktopLarge} {
      padding: 4rem;
    }

    @media ${breakpoints.desktopXL} {
      padding: 6rem;
    }

    @media (min-width: 1680px) {
      &::before {
        content: '';
        background-image: url(${BennyLeftHead.src});
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        width: 10rem;
        height: 10rem;
        left: -126px;
        top: 25px;
        z-index: -1;
      }
    }
  `,
  info: styled.div``,
  title: styled.h2`
    font-size: ${rem(30)};
    color: var(--color-primary);
    font-weight: normal;
    margin-bottom: 0;
    line-height: 1;

    @media ${breakpoints.tablet} {
      font-size: ${rem(40)};
    }

    @media ${breakpoints.desktopLarge} {
      font-size: ${rem(53)};
    }
  `,
  subtitle: styled.p`
    font-size: ${rem(20)};
    color: var(--color-black) !important;
    font-weight: bold;

    @media ${breakpoints.tablet} {
      font-size: ${rem(24)} !important;
    }
  `,
  body: styled.p``,
  addons: styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media ${breakpoints.tablet} {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  `,
  trustpilot: styled.div`
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: 150px;
    width: 300px;
    margin-left: -3.75rem;
    display: flex;
    justify-content: start;

    &.desktop {
      display: none;
      @media ${breakpoints.desktop} {
        display: block;
      }
    }

    &.mobile {
      @media ${breakpoints.desktop} {
        display: none;
      }
    }
  `,
  choosepackage: styled.div`
    padding-right: 1rem;
    padding-left: 1rem;
    section {
      margin-top: 0;
    }
  `,
};

const AddonCard = ({ addon, addonName }) => {
  const { addons, setAddons } = useBasketContext();
  const [selectedAddonId, setSelectedAddonId] = useState<string | null>(
    addons.includes(addon.id_product) ? addon.id_product : null,
  );

  useEffect(() => {
    if (addons.includes(addon.id_product)) {
      setSelectedAddonId(addon.id_product);
    }
  }, [addons, addon.id_product]);

  const handleAddToBasket = useCallback(
    (id_product) => {
      setAddons((prev) => {
        if (prev.includes(id_product)) {
          setSelectedAddonId(null);
          return prev.filter((id) => id !== id_product);
        } else {
          setSelectedAddonId(id_product);
          return [...prev, id_product];
        }
      });
    },
    [setAddons, setSelectedAddonId],
  );

  return (
    <STYLEDADDON.wrapper>
      <STYLEDADDON.content className="addon">
        <p className="addon__title">Monthly costs</p>
        <p className="addon__subtitle">on each device</p>
        <p className="addon__price">
          £{addon.monthly_price}
          <span>/month</span>
        </p>
        <p>{addon.months}-month contract</p>
        <hr />
        <p>
          Choose a {addon.months}-month contract and get{' '}
          <span
            dangerouslySetInnerHTML={{
              __html: addonName,
            }}
          />{' '}
          for £{addon.monthly_price}
          /month
          {addon.first_charge !== null ? ` PLUS ${addon.display_upfront_cost}.` : '.'}
        </p>
      </STYLEDADDON.content>
      <STYLEDADDON.button>
        <Button
          buttonType={ButtonType.SECONDARY}
          onClick={() => handleAddToBasket(addon.id_product)}
          className={selectedAddonId === addon.id_product ? 'selected' : ''}
        >
          {selectedAddonId === addon.id_product ? (
            <>
              <span>Selected</span>
              <Icon xs icon={checkIcon.src} />
            </>
          ) : (
            'Add to basket'
          )}
        </Button>
      </STYLEDADDON.button>
    </STYLEDADDON.wrapper>
  );
};

const Trustpilot = ({ className }) => {
  return (
    <STYLED.trustpilot className={className}>
      <TrustBox data={mockTrustBoxDataMini} />
    </STYLED.trustpilot>
  );
};

export const Addons: FC<AddonsI> = ({
  display,
  addonName,
  title,
  subtitle,
  bodyContent,
  trustpilot,
  addonOne,
  addonTwo,
  products,
}) => {
  const { addons, basketItem } = useBasketContext();
  const { postcodeItem } = usePostcodeContext();

  if (!display) return null;

  const addonProducts = products.filter((product) =>
    [addonOne, addonTwo].includes(product.id_product),
  );

  const AddonsComponent = addonProducts.length ? (
    addonProducts.map((addon, key) => <AddonCard key={key} addon={addon} addonName={addonName} />)
  ) : (
    <div>No addons available</div>
  );

  // Disable OTS check
  const trueCallback = () => {
    if (!postcodeItem) {
      return '/address-finder';
    }
    return '/service-property-confirmation';
  };

  const falseCallback = () => {
    if (!postcodeItem) {
      return '/address-finder';
    }
    return '/ots';
  };

  const redirect = handleConditionalExecution(
    process.env.NEXT_PUBLIC_DISABLE_OTS === 'true',
    trueCallback,
    falseCallback,
  )();

  return (
    <>
      <STYLED.wrapper className="slice">
        <STYLED.content className="slice__inner">
          <STYLED.info>
            <STYLED.title
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
            {subtitle && (
              <STYLED.subtitle
                dangerouslySetInnerHTML={{
                  __html: subtitle,
                }}
              />
            )}
            {bodyContent && (
              <STYLED.body
                dangerouslySetInnerHTML={{
                  __html: bodyContent,
                }}
              />
            )}
            {/* trustpilot desktop */}
            {trustpilot && <Trustpilot className="desktop" />}
          </STYLED.info>
          <STYLED.addons>{AddonsComponent}</STYLED.addons>
          {/* trustpilot mobile */}
          {trustpilot && <Trustpilot className="mobile" />}
        </STYLED.content>
      </STYLED.wrapper>
      <STYLED.choosepackage className="slice__inner">
        {(addons.length > 0 || (basketItem && basketItem.length > 0)) && (
          <ChoosePackage products={products} heading={'Choose your package'} />
        )}
      </STYLED.choosepackage>
      {basketItem && (
        <StickyCheckoutFooter
          primaryText={'Proceed'}
          primaryHref={redirect}
          show={true}
          isAlwaysOnScreen={true}
          isSticky={true}
        />
      )}
    </>
  );
};
