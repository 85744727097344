import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';

export default styled.div<{ $steps: number; $progressvalue: number }>`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(${(props) => props.$steps}, minmax(0, 1fr));
  max-width: 35rem;
  position: relative;
  padding: 0 0.25rem;

  @media ${breakpoints.desktop} {
    gap: 1rem;
  }

  :before {
    content: '';
    height: 13px;
    background: #f7f7ff 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    opacity: 1;
    position: absolute;
    border-radius: 70px;
    width: 104%;
    z-index: -1;
    top: 42px;
    left: -2%;
  }

  :after {
    content: '';
    height: 7px;
    background: transparent linear-gradient(270deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
      padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 70px;
    opacity: 1;
    position: absolute;
    border-radius: 70px;
    width: ${(props) => props.$progressvalue}%;
    z-index: -1;
    top: 45px;
  }
`;
