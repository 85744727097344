import { FriendlyTitle, ProductDataT } from './types';

/**
 * Parses product title according to input data
 *
 * @param {ProductDataT} productData The product input data
 * @returns {FriendlyTitle | string} The parsed title if a category is provided else
 *                                   the default display name
 */
export const getFriendlyProductTitle = ({
  name,
  display_name,
  category,
}: ProductDataT): FriendlyTitle | string => {
  const isHomePhone = name.includes('Home Phone');

  if (isHomePhone) {
    return name?.replace('[Addon]', '');
  }

  if (display_name) {
    return display_name;
  }

  if (category) {
    return (
      {
        GIGA: FriendlyTitle.GIGA,
        MEGA: FriendlyTitle.MEGA,
        SUPER: FriendlyTitle.SUPER,
      }[category] || name
    );
  }

  return name;
};
