import React, { FC, useCallback } from 'react';
import { useSelect } from 'downshift';

import { DropdownItem } from '@components/Atoms';
import { NativeSelect } from '@components/Molecules';

import { DropdownT } from './types';
import { DropdownItemT } from '@components/Atoms/DropdownItem/types';

import { StyledDropdown } from './styled/StyledDropdown';

const itemToString = (item: DropdownItemT | null) => item?.value ?? '';

export const Dropdown: FC<DropdownT> = ({
  id,
  items,
  className,
  onChange,
  placeholderLabel = 'Choose your option',
  defaultSelectedItem,
  required = false,
  ...rest
}) => {
  const {
    isOpen,
    selectedItem,
    highlightedIndex,
    selectItem,
    closeMenu,
    openMenu,
    getMenuProps,
    getItemProps,
    getToggleButtonProps,
  } = useSelect({ id, itemToString, items, onStateChange: onChange, defaultSelectedItem });

  const handleNativeSelectChange = useCallback(
    (selectedItem) => {
      selectItem(selectedItem);
      onChange(selectedItem);
      openMenu();
    },
    [openMenu, selectItem, onChange],
  );

  const renderDropdownItems = useCallback(
    () =>
      items.map((item: DropdownItemT, index: number) => (
        <DropdownItem
          item={item}
          key={`dropdown-item-${index}`}
          selected={selectedItem === item}
          highlighted={highlightedIndex === index}
          {...getItemProps({ item, index })}
        />
      )),
    [highlightedIndex, getItemProps, selectedItem, items],
  );

  const hasSelectedItem = Object.keys(selectedItem || {}).length;

  return (
    <StyledDropdown {...rest} className={className}>
      <span className={hasSelectedItem ? 'drop-down--selected' : ''}>{`${placeholderLabel}${
        required ? '*' : ''
      }`}</span>
      <button
        type="button"
        className={hasSelectedItem ? 'drop-down--selected' : ''}
        {...getToggleButtonProps()}
      >
        {selectedItem?.label ? selectedItem?.label : `${placeholderLabel}${required ? '*' : ''}`}
      </button>

      {/* NOTE:   Use media queries to display the native select if you want the custom dropdown to render. */}
      <NativeSelect
        items={items}
        onBlur={closeMenu}
        selected={selectedItem}
        onChange={handleNativeSelectChange}
        placeholderLabel={placeholderLabel}
        className="absolute top-0 left-0 w-full h-full opacity-0"
        required={required}
      />

      <ul {...getMenuProps()}>{isOpen && renderDropdownItems()}</ul>
    </StyledDropdown>
  );
};
