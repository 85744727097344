import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export default styled.div`
  background: #f7f9ff;
  box-shadow: 0 2px 26px #4986d845;
  border-radius: 5px;
  padding: 2.25rem 3.25rem;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${breakpoints.desktop} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
  }

  > div:first-child {
    margin-bottom: 1rem;
    @media ${breakpoints.desktop} {
      margin-bottom: 0;
    }
    h4 {
      font-size: 1.8rem;
      color: var(--colors-primary-bright);
      margin-bottom: 1.2rem;
    }
    p {
      font-size: 1.2rem;

      > a {
        font-weight: bold;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;
