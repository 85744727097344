import styled from 'styled-components';

export default styled.div<{ isHover: boolean }>`
  position: relative;
  width: 100%;
  padding: 1.5rem 0;

  > div {
    background-color: var(--colors-white);
    box-shadow: inset 0 3px 6px #00000029;
    width: 100%;
    height: 24px;
    border-radius: 12px;
    padding: 3px;
    > div {
      width: ${({ isHover }) => (isHover ? '100%' : '18px')};
      height: 100%;
      transition: width 0.3s;
      box-shadow: inset 0px 3px 6px #00000029;
      border-radius: 12px;
      background: transparent linear-gradient(270deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
        padding-box;
    }
  }
`;
