import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from '@theme/breakpoints';
import MaskRoundedXl from '@public/mask__rounded--xl.png';

const GenericContainerStyles = css`
  width: 100%;
  @media ${breakpoints.desktop} {
    padding-right: ${rem(30)};
  }
`;

export const StyledContactFormWrapper = styled.div`
  .contact-form {
    &__image {
      @media ${breakpoints.desktop} {
        padding-left: ${rem(30)};
      }
      filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
      img {
        object-fit: contain;
        mask-size: contain;
        mask-image: url(${MaskRoundedXl.src});
        mask-repeat: no-repeat;
        mask-position: center;
      }
    }
  }
`;

export const StyledContactForm = styled.form`
  ${GenericContainerStyles};
`;

export const StyledResponse = styled.div`
  ${GenericContainerStyles};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  min-height: 30rem;
  h3 {
    font-size: ${rem(32)};
    font-weight: 500;
    text-align: center;
    color: var(--colors-grey-100);
  }
`;
