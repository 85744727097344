export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ALTERNATE = 'alternate',
  ICON = 'icon',
  HYPERLINK = 'hyperlink',
  OUTLINE = 'outline',
  SUMMARY = 'summary',
  SECONDARY_ROUNDED = 'secondaryRounded',
  TERTIARY = 'tertiary',
  HYPERLINK_SECONDARY = 'hyperlinkSecondary',
  DARK = 'dark',
}

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  buttonType?: ButtonType;
  bgColor?: string;
  fgColor?: string;
  border?: string;
  onClick?: (event?: any) => void;
  disabled?: boolean;
  className?: string;
  fullWidth?: boolean;
  as?: any;
  href?: string;
  inline?: boolean;
  icon?: {
    src: string;
  };
  form?: string;
  type?: 'button' | 'submit' | 'reset' | '';
  size?: 'sm' | 'md' | 'lg';
}
