import { css } from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from '@theme/breakpoints';
import MaskRoundedXl from '@public/mask__rounded--xl.png';

export const DefaultHeroContent = css`
  .hero-content {
    &__title {
      padding-bottom: ${rem(8)};
      font-size: ${rem(65)};

      > p {
        font-family: var(--fonts-family-secondary);
        line-height: 1;
        padding-bottom: ${rem(8)};
        font-size: ${rem(90)};
      }

      > b {
        font-weight: inherit;
        color: var(--colors-primary);
      }
    }
    &__subtitle {
      padding-bottom: ${rem(8)};
      font-size: ${rem(18)};
      color: var(--colors-text-primary);
      font-family: var(--fonts-family-secondary);
      font-weight: 200;

      @media ${breakpoints.desktop} {
        padding-right: 25px;
        font-size: ${rem(27)};
      }
    }
    &__description {
      padding-bottom: ${rem(36)};
      font-size: 1.25rem;
      max-width: 36rem;
      &--no-image {
        padding-bottom: 0;
      }
    }
    &__address {
      text-transform: uppercase;
    }
    &__address-change {
      display: inline-block;
      text-decoration: underline;
      font-weight: bold;
      margin-left: ${rem(8)};
    }

    &__media-wrapper {
      bottom: 0%;
      z-index: 2;
      width: 100%;
      position: relative;

      &--double {
        @media ${breakpoints.desktopLarge} {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          transform: translate(0, 0);
          left: auto;
          margin-bottom: 0;
        }
      }

      &--desktop {
        display: none;
        @media ${breakpoints.desktopLarge} {
          display: flex;
        }
      }
      &--mobile {
        width: 100%;
        display: flex;
        @media ${breakpoints.desktopLarge} {
          display: none;
        }
      }
    }

    &__feature-image {
      margin: 0 auto;
      @media ${breakpoints.tablet} {
        max-width: 600px;
      }
      img {
        object-fit: contain;
        mask-size: contain;
        mask-image: url(${MaskRoundedXl.src});
        mask-repeat: no-repeat;
        mask-position: center;
      }
    }
    &__product-image {
      margin: 0 auto;
      padding-top: 30px;
      @media ${breakpoints.tablet} {
        max-width: 600px;
      }
    }
    &__trust-box {
      height: 24px;
      width: 300px;
      margin-left: 0;
      position: relative;
      color: var(--colors-text-primary) !important;
      iframe {
        left: -1rem;
      }
    }

    &__primary-cta {
      margin-bottom: 2rem;
      max-width: 30rem;
    }
  }

  .info-box {
    background-color: var(--colors-primary-3);
    border: 1px solid var(--colors-primary-15);
    border-radius: 8px;
    color: var(--colors-grey-50);
    padding: ${rem(16)};
    max-width: 400px;
    width: 100%;
    &__heading {
      font-size: ${rem(14)};
      line-height: 1.5;
      color: var(--colors-black);
    }
    p {
      color: inherit;
    }
    &__list {
      > li:first-of-type {
        margin-top: 0;
      }
      margin-bottom: ${rem(14)};
    }
    &__smallprint {
      font-size: ${rem(12)};
      line-height: 1.3;
    }
    &__body {
      font-size: ${rem(14)};
      line-height: 1.5;
      a {
        font-weight: bold;
        display: inline-block;
        color: var(--colors-primary);
        text-decoration: underline;
      }
    }
  }
`;
