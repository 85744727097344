/* eslint-disable react/display-name */
import React, { FC, forwardRef } from 'react';
import { format, add, intervalToDuration, addHours } from 'date-fns';
import styled from 'styled-components';
import useCheckoutContext from '@hooks/useCheckoutContext';
import usePostcodeContext from '@hooks/usePostcodeContext';

const Wrapper = styled.div`
  background-color: #fefdff;
  border: 1px solid #00208012;
  padding: 0.75rem 1.25rem;
  margin-bottom: 2rem;
  box-shadow: 0px 3px 7px #4c5ea822;
  border-radius: 5px;
`;

export const CheckoutTooltip: any = () => {
  const now = new Date();
  const { checkoutEndTime } = useCheckoutContext();
  const { isCurrentPostcodePreorder } = usePostcodeContext();

  const {
    installation: { slots },
  } = useCheckoutContext();

  if (!checkoutEndTime || isCurrentPostcodePreorder) {
    return null;
  }

  const endTimeAsDate = new Date(checkoutEndTime);

  const diff = intervalToDuration({
    start: now,
    end: endTimeAsDate,
  });

  if (
    diff.hours === undefined ||
    typeof diff.hours !== 'number' ||
    diff.minutes === undefined ||
    typeof diff.minutes !== 'number'
  ) {
    return null;
  }

  const timeSurpassed = now > endTimeAsDate;

  const hours = timeSurpassed ? 24 - diff.hours : diff.hours;
  const minutes = timeSurpassed ? 60 - diff.minutes : diff.minutes;

  return slots?.length ? (
    <Wrapper>
      <p>
        Checkout in{' '}
        <strong>
          {!!hours && `${hours}h and `}
          {minutes}m
        </strong>{' '}
        and you could be online as soon as{' '}
        <strong>{format(new Date(slots[0].day), 'do MMMM Y')}</strong>
      </p>
    </Wrapper>
  ) : null;
};
