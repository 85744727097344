import Image from 'next/image';
import { fromMarkdown, convertToSlug } from '@lib/utils';
import { FullLengthCard } from '@components/Molecules/FullLengthCard';

import { StyledContainer } from './styled/StyledContainer';
import { StyledHeader } from './styled/StyledHeader';
import { StyledTwoColumnText } from './styled/StyledTwoColumnText';
import { StyledColumns } from './styled/StyledColumns';

const TwoColumnText = ({ Title, LeftText, RightText, Icon, Card }) => {
  return (
    <StyledTwoColumnText id={convertToSlug(Title)}>
      <StyledContainer>
        <div className="flex flex-col justify-center items-center">
          <StyledHeader>
            <div>
              <Image src={Icon.url} alt="Icon" width={100} height={100} quality={100} />
            </div>
            <h2>{Title}</h2>
          </StyledHeader>
          <StyledColumns>
            <div>{fromMarkdown({ text: LeftText, isHeading: false })}</div>
            <div>{fromMarkdown({ text: RightText, isHeading: false })}</div>
          </StyledColumns>
          <FullLengthCard {...Card} />
        </div>
      </StyledContainer>
    </StyledTwoColumnText>
  );
};

export { TwoColumnText };
