import styled, { css } from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export default styled.div<any>`
  position: relative;
  padding: 2rem;
  max-width: 100%;
  background: var(--color-blue) 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 26px #676d9a29;
  opacity: 1;
  display: ${({ show }) => (show ? 'block' : 'none')};
  align-self: flex-start;

  @media ${breakpoints.tablet} {
    position: sticky;
    top: 1rem;
    display: block;
  }

  @media ${breakpoints.desktopLarge} {
    width: 100%;
    max-width: 26rem;
  }
`;
