import React, { FC } from 'react';

import { Props } from './types';
import { HeroMain, HeroDefault, HeroServices, HeroMSM } from './variants';

export const Hero: FC<Props> = ({ data }) => {
  const { heroType = 'heroDefault' } = data || {};

  const variantMapping = {
    heroMain: HeroMain,
    heroDefault: HeroDefault,
    heroPreOrder: HeroServices,
    heroRegisterInterest: HeroServices,
    heroMSM: HeroMSM,
  };

  const VariantToRender = variantMapping[heroType] || variantMapping['heroDefault'];

  if (VariantToRender) {
    return <VariantToRender data={data} />;
  }

  return null;
};
