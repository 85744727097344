const colors = {
  white: 'var(--color-white)',
  lightestBlue: 'var(--color-lightest-blue)',
  black: 'var(--color-black)',
  blue: 'var(--color-blue)',
  midBlue: 'var(--color-mid-blue)',
  grey: 'var(--color-grey)',
  primary: 'var(--color-primary)',
  selectedOffWhite: 'var(--color-selected-off-white)',
  danger: 'var(--color-danger)',
  gradient: 'var(--color-gradient)',
};

export const mapFieldColorToVar = (field: string | undefined) => {
  return colors[field || ''] || field;
};
