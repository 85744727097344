import styled from 'styled-components';

export default styled.a`
  color: var(--colors-black);
  font-size: 1rem;
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;
