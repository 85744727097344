import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export default styled.div`
  display: flex;
  align-items: flex-start;
  @media ${breakpoints.mobilelgMax} {
    flex-direction: column;
    align-items: center;
  }
`;
