import styled from 'styled-components';

const StyledRightImage = styled.div`
  display: none;
  @media (min-width: 1680px) {
    display: block;
    position: absolute;
    z-index: 10;
    top: 40%;
    right: calc(-9.5rem + 0.3rem); // 0.25rem accounts for the finger overlap
    width: 9.5rem;
    height: 24rem;
    img {
      object-position: left;
    }
  }
`;

export default StyledRightImage;
