import styled from 'styled-components';

export default styled.div`
  margin-top: 1rem;
  a {
    text-decoration: underline;
  }

  &.booleanInput {
    margin-left: 1.5rem;
    position: relative;

    &--1 {
      &:before {
        content: '1';
      }
    }

    &--2 {
      &:before {
        content: '2';
      }
    }

    &--3 {
      &:before {
        content: '3';
      }
    }

    &--4 {
      &:before {
        content: '4';
      }
    }

    &--1,
    &--2,
    &--3,
    &--4 {
      &:before {
        position: absolute;
        top: -3px;
        left: -1.5rem;
        color: var(--color-primary);
        font-family: var(--font-geomanist);
        font-size: 21px;
        font-weight: bold;
      }
    }
  }
`;
