// mb-10 font-semibold text-center text-black underline

import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';

const ChoosePackageSubheading = styled.div`
  margin-bottom: 2.5rem;
  padding: 0 1rem;
  font-weight: 600;
  text-align: center;
  color: #000;
  text-decoration: underline;

  @media ${breakpoints.tablet} {
    padding-right: 0;
    padding-left: 0;
  }
`;

export default ChoosePackageSubheading;
