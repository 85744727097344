import React, { FC } from 'react';
import { renderParagraphs, renderComponents } from '@lib/utils';
import { DynamicIcon } from '@components/Atoms/Icon/Icon';
import { StyledContactCards, StyledContactCard } from './styled';

import { Props, ContactCardT } from './types';

export const ContactCardList: FC<Props> = ({ data }) => {
  const { heading, contactCards } = data || {};

  const ContactCard: FC<ContactCardT> = ({ body, cta, icon }) => {
    return (
      <StyledContactCard>
        <div className="contact-card__image">
          <DynamicIcon icon={icon.icons} height={'56px'} width={'56px'} />
        </div>

        <div className="contact-card__content">
          {!cta.link && (
            <button
              className="contact-card__cta"
              onClick={() => {
                window.tidioChatApi?.show();
                window.tidioChatApi?.open();
              }}
            >
              {cta.label}
            </button>
          )}
          {cta.link && (
            <a className="contact-card__cta" href={cta.link} target="_blank" rel="noreferrer">
              {cta.label}
            </a>
          )}

          {renderParagraphs({ body, className: `contact-card__body` })}
        </div>
      </StyledContactCard>
    );
  };

  const ContactCards = contactCards?.map(({ body, icon, cta }) => {
    const Component = <ContactCard body={body} icon={icon} cta={cta} />;

    return Component;
  });

  return (
    <StyledContactCards id="contact_card" className="mx-auto container lg:w-4/5 my-10">
      {heading && <h2 className="large mb-8">{heading}</h2>}
      {contactCards.length > 0 && (
        <div className="flex flex-col md:flex-row justify-between">
          {renderComponents({ components: ContactCards })}
        </div>
      )}
    </StyledContactCards>
  );
};
