import styled from 'styled-components';

export default styled.div`
  margin: 0 0 1rem;
  line-height: 1.3;
  color: var(--colors-primary-bright);
  font-size: 1.8rem;
  font-weight: 900;
  font-family: var(--fonts-family-secondary);
`;
