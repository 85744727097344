import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: flex-end;
  > div:last-child {
    > span:first-child {
      font-size: 3rem;
      color: var(--colors-black);
      font-family: var(--fonts-family-secondary);
      font-weight: bold;
      line-height: 1;
      margin-right: 0.25rem;
    }
    > span:last-child {
      font-size: 1.2rem;
    }
  }
`;
