import styled, { css } from 'styled-components';
import { rem } from 'polished';

export default styled.div<{ isSymmetrical: boolean }>`
  display: flex;
  ${({ isSymmetrical }) =>
    isSymmetrical
      ? css`
          justify-content: space-between;
        `
      : ''}
  padding: 1.5rem 0;

  .speed-container {
    &:nth-child(1) {
      padding-right: 0.9rem;
    }

    &:nth-child(2) {
      border-left: solid 1px #dcdde2;
      padding-left: 0.9rem;
    }

    > div:nth-child(1) {
      margin-bottom: -7px;
      > * {
        margin-right: 0.3rem !important;
      }
    }

    > div:nth-child(2) {
      > span:first-child {
        font-size: 1.8rem;
        font-weight: bold;
        color: var(--colors-black);
      }
      > span:nth-child(2) {
        color: var(--colors-black);
        font-size: 0.813rem;
      }
    }
    > div:nth-child(3) {
      font-size: 0.625rem;
    }
  }

  .symmetrical-speed-container {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1rem;
    & > p {
      font-size: 0.625rem;
      font-weight: bold;
      text-align: center;
    }
    & > p:before {
      content: '';
      position: absolute;
      inset: 0;
      background: transparent linear-gradient(247deg, #f4111b 0%, #dc1031 41%, #0402fd 100%) 0 0
        no-repeat padding-box;
      opacity: 0.2;
    }
  }
`;
