/* eslint-disable react/display-name */
import React, { FC, useCallback, forwardRef } from 'react';

import { NativeSelectT } from './types';

export const NativeSelect: FC<NativeSelectT> = forwardRef(
  ({ items, selected, onChange, onBlur, className, placeholderLabel, ...rest }, ref) => {
    const renderOptions = useCallback(() => {
      const selectableOptions = items.map((item: any, index: number) => (
        <option value={item.value} key={`native-option-${index}`}>
          {item.label}
        </option>
      ));

      if (placeholderLabel) {
        const placeholderOption = (
          <option disabled value={placeholderLabel} key="native-option-disabled">
            {placeholderLabel}
          </option>
        );

        return [placeholderOption, ...selectableOptions];
      }

      return selectableOptions;
    }, [items, placeholderLabel]);

    const handleChange = useCallback(
      (event) => {
        const selectedItem = items.find((elem: any) => elem.value === event.target.value);

        onChange && onChange(selectedItem);
      },
      [items, onChange],
    );

    return (
      <select
        {...rest}
        ref={ref}
        onBlur={onBlur}
        className={className}
        onChange={handleChange}
        value={selected?.value ?? placeholderLabel}
      >
        {renderOptions()}
      </select>
    );
  },
);
