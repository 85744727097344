export enum FriendlyTitle {
  GIGA = 'Gigafast',
  MEGA = 'Megafast',
  SUPER = 'Superfast',
}

export type ProductDataT = {
  name: string;
  display_name: string;
  category: string | null;
};
