import { ImageDataT } from '@lib/types';
import { VideoData } from '@components/Atoms/VideoPlayer/types';
import { CtaT } from '@components/Molecules/Cta/types';

export interface Props {
  data: MediaBlockData;
}

export type MediaBlockData = {
  heading?: string;
  body?: string;
  media?: ImageDataT;
  video?: VideoData;
  mediaType: MediaType;
  cta?: CtaT;
  showWaves: boolean;
  image?: InfoSectionImageData;
  blueBackground?: boolean;
  blueHeading?: boolean;
};

export enum MediaType {
  VIDEO = 'video',
  IMAGE = 'image',
}

export type InfoSectionImageData = {
  display: boolean;
  image: ImageDataT;
  imagePositionDesktop: string;
  imageWidthDesktop: number;
  imagePositionMobile: string;
  imageWidthMobile: number;
};
