import React, { useRef, useEffect, FC, useState } from 'react';
import { WidgetWrapper } from './styled';
import { Props, WidgetType } from './types';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const TrustBox: FC<Props> = ({ data, className = '' }) => {
  const [isTrustpilotLoaded, setIsTrustpilotLoaded] = useState<boolean>(false);
  const microstarRef = useRef(null);
  const carouselRef = useRef(null);
  const miniRef = useRef(null);

  const { type, display, heading, headingStyles, backgroundColor } = data || {};

  const Heading = styled.h2`
    font-size: var(--fonts-sizing-h5);
    font-family: var(--fonts-family-secondary);
    line-height: 1.3;
    font-weight: 700;
    font-size: 1.25rem;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    padding-right: 0.75rem;
    width: 100%;
    text-align: center;

    ${headingStyles || ''}

    @media ${breakpoints.tablet} {
      width: 66.666667%;
      padding-right: 0;
    }
  `;

  const getWidget = () => {
    if (!display) {
      return null;
    }

    if (type === WidgetType.CAROUSEL) {
      return (
        <>
          <Heading>{heading}</Heading>
          <div
            ref={carouselRef}
            className="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id="5e833841bdfd8d0001011a98"
            data-style-width="100%"
            data-theme="light"
            data-stars="4,5"
            data-review-languages="en"
          >
            <a
              href="https://uk.trustpilot.com/review/heybroadband.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot
            </a>
          </div>
        </>
      );
    }
    if (type === WidgetType.MINI) {
      return (
        <>
          <Heading>{heading}</Heading>
          <div
            ref={miniRef}
            className="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id="5e833841bdfd8d0001011a98"
            data-style-width="100%"
            data-theme="light"
            data-review-languages="en"
          >
            <a
              href="https://uk.trustpilot.com/review/heybroadband.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot
            </a>
          </div>
        </>
      );
    }
    return (
      <>
        <div
          ref={microstarRef}
          className="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="5419b732fbfb950b10de65e5"
          data-businessunit-id="5e833841bdfd8d0001011a98"
          data-style-width="100%"
          data-theme="light"
        >
          <a
            href="https://uk.trustpilot.com/review/heybroadband.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trustpilot
          </a>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (window.Trustpilot) {
      setTimeout(() => {
        if (type === WidgetType.CAROUSEL) {
          window.Trustpilot.loadFromElement(carouselRef.current, true);
        } else if (type === WidgetType.MINI) {
          window.Trustpilot.loadFromElement(miniRef.current, true);
        } else {
          window.Trustpilot.loadFromElement(microstarRef.current, true);
        }
      }, 50);
    }
    setIsTrustpilotLoaded(true);
  }, [type]);

  if (!isTrustpilotLoaded) {
    return null;
  }

  return (
    <WidgetWrapper type={type} backgroundColor={backgroundColor} className={`${className}`}>
      <div className={`${type === WidgetType.CAROUSEL ? 'container mx-auto' : ''}`}>
        {getWidget()}
      </div>
    </WidgetWrapper>
  );
};
