/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FC } from 'react';
import Link from 'next/link';

import usePostcodeContext from '@hooks/usePostcodeContext';

import { ImageCardT, ImageCardListT, ImageCardWrapperT } from './types';

import { Card } from '@components/Molecules';
import { CardType } from '@components/Molecules/Card/types';

import { Button } from '@components/Atoms';

import { Format, Layout, ImagePosition, Theme, Event } from '@lib/types';
import { fromMarkdown, match, renderParagraphs, renderImage, renderComponents } from '@lib/utils';

import { CmsEventSubscriber } from '@hoc/index';
import { ButtonType } from '@components/Atoms/Button/types';
import { useRouter } from 'next/router';

//  TODO:  Extract Wrapper and Card into a Molecule component
export const ImageCardWrapper: FC<ImageCardWrapperT> = ({ children, onClick, link }) => {
  if (onClick) {
    return (
      <div role="button" onClick={onClick} className="w-full">
        {children}
      </div>
    );
  }

  if (link && link !== '') {
    return (
      <Link href={`/${link.replace(/^\//, '')}`}>
        <a className="mx-auto">{children}</a>
      </Link>
    );
  }

  return children;
};

export const ImageCard: FC<ImageCardT> = ({
  theme,
  heading,
  subheading,
  body,
  image,
  imagePosition,
  CTA,
  display = true,
  hexagonBackground = false,
}) => {
  const router = useRouter();

  if (!display) return null;

  const EnhancedImageCardWrapper = match(CTA)
    .on(
      (CTA: any) => CTA?.event === Event.POSTCODE_CHECKER,
      () =>
        CmsEventSubscriber(ImageCardWrapper, {
          onClick: () => router.push('/address-finder'),
        }),
    )
    .otherwise(() =>
      CmsEventSubscriber(ImageCardWrapper, {
        link: CTA?.link,
      }),
    );

  const { alternativeText, formats } = image || {};

  const block = `card-${CardType.IMAGE}`;

  const cardStyle = `slice__inner transition-all duration-300`;

  return (
    <EnhancedImageCardWrapper>
      <Card
        type={CardType.IMAGE}
        cmsTheme={theme}
        className={`${imagePosition === ImagePosition.RIGHT && 'row-reverse'}  ${cardStyle}`}
      >
        <div className={`${block}__wrapper`}>
          {hexagonBackground ? (
            <div className="hex_border">
              <div className="hex">
                {renderImage({
                  className: `${block}__image`,
                  alternativeText,
                  formats,
                  format: Format.MEDIUM,
                  layout: Layout.FIXED,
                })}
              </div>
            </div>
          ) : (
            // <div className="">
            renderImage({
              className: `${block}__image`,
              alternativeText,
              formats,
              format: Format.MEDIUM,
              layout: Layout.RESPONSIVE,
            })
            // </div>
          )}
        </div>
        <div className={`${block}__copy`}>
          <h2 className={`${block}__heading slice__title`}>{fromMarkdown({ text: heading })}</h2>
          {subheading && (
            <h3 className={`${block}__subheading slice__subtitle`}>
              {fromMarkdown({ text: subheading })}
            </h3>
          )}

          {renderParagraphs({ body, className: `${block}__body slice__text` })}

          {CTA && (
            <Button buttonType={ButtonType.PRIMARY} className="w-full lg:w-auto">
              {CTA.label}
            </Button>
          )}
        </div>
      </Card>
    </EnhancedImageCardWrapper>
  );
};

export const ImageCardList: FC<ImageCardListT> = ({ imageCards }) => {
  if (!imageCards.length) return null;

  const ImageCards = imageCards.map(
    ({
      theme,
      heading,
      subheading,
      body,
      image,
      imagePosition,
      CTA,
      display,
      hexagonBackground = false,
    }) => {
      const Component = (
        <li
          className="slice"
          style={
            theme === Theme.DARK
              ? { backgroundColor: '#ECF0FD' }
              : {
                  background:
                    'transparent linear-gradient(180deg, #FCCFD2 0%, #CDCCFE 100%) 0% 0% no-repeat padding-box',
                }
          }
        >
          <ImageCard
            theme={theme}
            heading={heading}
            subheading={subheading}
            body={body}
            image={image}
            imagePosition={imagePosition}
            CTA={CTA}
            display={display}
            hexagonBackground={hexagonBackground}
          />
        </li>
      );

      return Component;
    },
  );

  return (
    <section>
      <ul className="card-list max-w-full flex flex-col">
        {renderComponents({ components: ImageCards })}
      </ul>
    </section>
  );
};
