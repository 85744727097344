import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 2rem;
  margin: 1rem 0 1rem 1.5rem;

  background: var(--color-blue) 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 16px #676d9a29;
  border-radius: 10px;
`;
