import { Icon } from '@components/Atoms';
import ShareFacebookIcon from '@public/icons/share-facebook.png';
import ShareLinkedInIcon from '@public/icons/share-linkedin.png';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

interface ShareTabProps {
  className?: string;
}

export const ShareTab = ({ className }: ShareTabProps) => {
  const currentBlogUrl = typeof window !== 'undefined' ? window.location.href : '';

  const handleFacebookShare = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      currentBlogUrl,
    )}`;
    window.open(url, '_blank', 'width=600,height=400');
  };

  const handleLinkedInShare = () => {
    const url = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(currentBlogUrl)}`;
    window.open(url, '_blank', 'width=600,height=400');
  };

  return (
    <StyledShareTab className={className}>
      <div className="share-tab-wrapper">
        <div className="share-tab">
          <p>Share</p>
          <button onClick={handleFacebookShare}>
            <Icon sm icon={ShareFacebookIcon} />
          </button>
          <button onClick={handleLinkedInShare}>
            <Icon sm icon={ShareLinkedInIcon} />
          </button>
        </div>
      </div>
    </StyledShareTab>
  );
};

export const StyledShareTab = styled.div`
  &.mobile {
    @media ${breakpoints.tablet} {
      display: none;
    }
  }

  &.desktop {
    display: none;

    @media ${breakpoints.tablet} {
      display: block;
    }
  }

  .share-tab-wrapper {
    display: flex;

    @media ${breakpoints.tablet} {
      position: absolute;
      height: 100%;
      padding-bottom: 4.5rem;
    }

    @media ${breakpoints.desktop} {
      padding-bottom: 0;
    }
  }

  .share-tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;

    @media ${breakpoints.tablet} {
      position: sticky;
      top: 9rem;
      left: 0;
      z-index: 100;
      background-color: var(--color-white);
      width: fit-content;
      padding: 0.5rem 0.5rem 1.5rem;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      -webkit-box-shadow: 0px 3px 16px 0px rgba(74, 87, 128, 1);
      -moz-box-shadow: 0px 3px 16px 0px rgba(74, 87, 128, 1);
      box-shadow: 0px 3px 16px 0px rgba(74, 87, 128, 1);
      flex-direction: column;
      margin-top: 0;
      height: fit-content;
    }

    @media ${breakpoints.desktop} {
      top: 12.5rem;
    }

    p {
      font-weight: 600;
      color: var(--color-black);
    }

    button {
      transition: transform 0.2s ease-in-out;

      &:hover {
        transform: translateY(-3px);
      }
    }
  }
`;
