import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';

export const StyledInfoStep = styled.div<{ withIcon: boolean }>`
  color: #000000;
  font-size: 23px;
  font-weight: 500;
  font-family: var(--font-geomanist);
  text-align: center;
  min-width: calc(980px / 4);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 1.5rem;
  .infoStep {
    &__circle {
      background: #fff;
      text-align: center;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      margin-bottom: 25px;
      ${({ withIcon }) =>
        withIcon &&
        `
        height: 10rem;
        width: 10rem;
        &::before {
          display: none;
        }
      `}

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 80px;
        height: 80px;
        border-radius: 40px;
        background: rgb(244, 17, 27);
        background: linear-gradient(141deg, rgba(244, 17, 27, 1) 0%, rgba(0, 0, 255, 1) 100%);
        filter: blur(12px);
        opacity: 0.7;
        transform-origin: 55% 55%;
        transform: rotate(0deg);
        -webkit-animation: spin 3.5s linear infinite;
        -moz-animation: spin 3.5s linear infinite;
        animation: spin 2.5s linear infinite;
      }
    }
    @-moz-keyframes spin {
      100% {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    &__number {
      color: #0000ff;
      font-size: 44px;
      font-weight: 500;
      font-family: Geomanist, Segoe, 'Segoe UI', 'DejaVu Sans', 'Trebuchet MS', Verdana,
        'sans-serif';
      margin-top: 5px;

      ${({ withIcon }) =>
        withIcon &&
        `
        position: relative;
        height: 50%;
        width: 50%;
      `}
    }

    &__label {
      text-align: center;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      font-size: 1.25rem;
      font-weight: 600;
      font-family: var(--fonts-family-secondary);
      margin-bottom: 1rem;

      @media ${breakpoints.desktop} {
        font-size: 1.33rem;
      }

      span {
        color: var(--color-primary);
        margin-right: 0.5rem;
      }
    }

    &__instructions {
      text-align: center;
      letter-spacing: 0px;
      color: black;
      opacity: 1;
      font-size: var(--fonts-sizing-primary);
      font-weight: 300;
      font-family: var(--fonts-family-secondary);
      margin-bottom: 1rem;
      padding: 0 1rem;
    }
  }
`;
