import styled, { css } from 'styled-components';
import { Button } from '@components/Atoms';
import { breakpoints } from '@theme/breakpoints';
import { supportsSafariOnly } from '@lib/utils/styling/supportsSafariOnly';

const HoverStyles = css`
  border-bottom-color: var(--colors-text-secondary);
  outline: none;
  + .header-dropdown {
    display: flex;
  }
`;

export const HeaderButton = styled(Button)<{ color?: string }>`
  display: block;
  background-color: transparent;
  color: ${({ color }) => color || 'inherit'};
  height: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0 0.75rem;
  font-weight: lighter;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  transition: all 0.2s ease;
  position: relative;

  ${supportsSafariOnly(`margin: 0 0.5rem;`)}

  @media ${breakpoints.desktopXL} {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    ${supportsSafariOnly(`
      margin-left: 1rem;
      margin-right: 1rem;
  `)}
  }

  + .header-dropdown {
    display: none;
  }
  &:before {
    content: '';
    left: 0;
    bottom: 0;
    width: 0;
    display: block;
    position: absolute;
    height: 2px;
    background: transparent linear-gradient(99deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
      padding-box;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
  }
  &:focus-visible {
    ${HoverStyles}
  }
  &:hover {
    ${HoverStyles}
  }
  &:hover:before {
    content: '';
    width: 100%;
  }

  .primary-phone {
    display: flex;
    gap: 0.5rem;

    ${supportsSafariOnly(`
        span:first-child {
          display: none;
        }
        @media ${breakpoints.desktopXL} {
          span:first-child {
            display: inline;
          }
        }
    `)}
  }
`;
