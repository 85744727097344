import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from '@theme/breakpoints';
import Headroom from 'react-headroom';
import { Logo } from '@components/Atoms/Logo/Logo';

const HeaderLayout = css`
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 12;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  background-color: var(--colors-white);
`;

function invertedStyle({ $inverted = false }) {
  if ($inverted) {
    return css`
      .header__container {
        color: var(--colors-text-primary);
      }
      .header__navigation {
        > a {
          &:hover {
            border-color: var(--colors-text-primary);
          }
        }
      }

      .headroom--pinned {
        .header__container {
          color: var(--colors-white);
        }
        .header__navigation {
          > a {
            &:hover {
              border-color: var(--colors-white);
            }
          }
        }

        .header__aside {
          .get-HB {
            color: var(--colors-white);
          }
        }
      }
    `;
  }
}

export const StyledHeader = styled(Headroom)<any>`
  ${HeaderLayout};
  height: auto !important; // had to do this as the onUnfix callback was giving wrong default height
  transform-style: preserve-3d;
  box-shadow: 0 20px 11px -15px rgba(244, 17, 27, 0.08);
  &.hide {
    pointer-events: none;
  }
  .headroom {
    ${HeaderLayout};
    &--pinned {
      background-color: var(--colors-background);

      .header-banner {
        display: none;
      }

      .header__container {
        padding-bottom: ${rem(16)};
        @media ${breakpoints.desktop} {
          padding-top: ${rem(16)};
        }
      }
    }
  }
  .header {
    &__container {
      justify-content: space-between;
      padding: ${rem(20)} ${rem(16)};
      display: flex;
      color: var(--colors-text-primary);
      align-items: center;

      @media ${breakpoints.desktop} {
        padding-top: ${rem(33)};
        display: flex;
      }
    }
    &__logo {
      width: 96px;
      height: 31px;
    }

    &__more {
      :before {
        content: ' ';
        width: 200px;
        height: 150%;
        position: absolute;
        top: 50%;
        left: 132%;
        transform: translateX(-50%);
      }
      &:focus {
        .header-dropdown {
          display: flex;
        }
      }
      &:focus-visible {
        display: flex;
      }

      &:hover {
        .header-dropdown {
          display: flex;
        }
        .header-button {
          &:before {
            width: 100%;
          }
        }
      }
    }

    &__navigation {
      display: none;
      @media ${breakpoints.desktopLarge} {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        justify-content: flex-end;
        margin-right: ${rem(16)};
      }
    }

    &__aside {
      a.get-HB {
        display: none;
        & > div {
          padding: 0 0.75rem;
        }
        @media ${breakpoints.tablet} {
          display: block;
          height: 2.5rem;
          font: normal normal bold 16px/0px Geomanist;
          color: var(--color-white);
          margin-right: 1rem;
        }
      }
      .header__basket-button,
      > a {
        display: none;
        @media ${breakpoints.desktop} {
          display: block;
        }
      }
      a[href*='tel'] {
        display: flex;
        align-items: center;
        font-weight: 400;
        color: var(--colors-primary);
        gap: 0.5rem;
        position: relative;
        margin-right: 1.5rem;
        top: 0;
        span:last-child {
          display: none;
        }
        @media ${breakpoints.mobilelg} {
          span:last-child {
            display: inline;
          }
        }
        @media ${breakpoints.desktop} {
          top: 0.1rem;
        }
        @media ${breakpoints.desktopLarge} {
          position: static;
          display: none;
        }
      }

      &:has(.get-HB) a[href*='tel'] {
        @media ${breakpoints.tabletMax} {
          top: 0;
        }
        @media ${breakpoints.desktopMax} and ${breakpoints.tablet} {
          top: 0;
          margin-right: 3rem;
        }
      }

      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media ${breakpoints.desktopLarge} {
        flex-grow: unset;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        .get-HB,
        .header__basket-button,
        > a {
          display: block;
        }
      }
    }

    &__hamburger {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.25rem;
      > * {
        margin: 0 0.5rem;
      }
      @media ${breakpoints.desktopLarge} {
        display: none;
      }
    }
  }
  ${invertedStyle}
`;
export const StyledLogo = styled(Logo)<any>`
  display: block;
  width: 96px;
  height: 31px;
`;
