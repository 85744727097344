import React, { FC } from 'react';
import { InfoStepProps } from './types';
import { StyledInfoStep } from './styled';
import { fromMarkdown } from '@lib/utils';
import Image from 'next/image';

export const InfoStep: FC<InfoStepProps> = ({ number, label, instructions, icon }) => {
  return (
    <StyledInfoStep className="flex flex-col items-center mt-8 sm:mt-0 flex-1" withIcon={!!icon}>
      <div className="infoStep__circle">
        <div className="infoStep__number">
          {icon && icon?.url ? <Image src={icon.url} alt="" layout="fill" /> : number}
        </div>
      </div>
      <p className="infoStep__label">
        {icon ? <span>{`${number} `}</span> : ''}
        {label}
      </p>
      {instructions && (
        <div className="infoStep__instructions">
          {fromMarkdown({ text: instructions, isHeading: false })}
        </div>
      )}
    </StyledInfoStep>
  );
};
