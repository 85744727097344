import { createContext, FC } from 'react';

import { Theme } from '@lib/types';
import { HeaderDataI, HeaderProviderT } from './types';

export const HeaderContext = createContext<HeaderDataI>({
  theme: Theme.LIGHT,
  navInfo: [],
});

export const HeaderProvider: FC<HeaderProviderT> = ({ children, headerData }) => {
  const data: HeaderDataI = {
    theme: headerData?.theme,
    navInfo: headerData?.navInfo,
  };

  return <HeaderContext.Provider value={data}>{children}</HeaderContext.Provider>;
};
