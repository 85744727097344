import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import ErrorIcon from '@public/icons/Error-primary.svg';

export const StyledPreorderNote = styled.div`
  background: url('${ErrorIcon.src}') center right 0 no-repeat;
  border: 1px solid var(--color-primary);
  margin-bottom: 2.5rem;
  margin-top: 1rem;
  padding: 0.75rem 1rem;
  padding-right: 4rem;
  max-width: 30rem;
  border-radius: 0.5rem;
  color: var(--colors-primary);
  font-weight: bold;
`;
