import React, { FC } from 'react';
import { StyledCard } from './styled';

import { CardT } from './types';

export const Card: FC<CardT> = ({ children, type, className, cmsTheme }) => (
  <StyledCard type={type} cmsTheme={cmsTheme} className={`card card-${type} ${className || ''}`}>
    {children}
  </StyledCard>
);
