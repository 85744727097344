import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledAppLinks = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (${breakpoints.mobilelg}) {
    flex-direction: row;
  }
  @media (${breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (${breakpoints.desktop}) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;
