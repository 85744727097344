import styled from 'styled-components';
import { rem } from 'polished';

export const StyledButton = styled('a')`
  transition: all 0.2s;
  font-size: var(--fonts-sizing-button);

  position: relative;
  font-family: var(--fonts-family-secondary);
  font-weight: 500;
  width: fit-content;
  cursor: pointer;
  line-height: 48px;
  display: 'flex';
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;

  border: none;
  transform-style: preserve-3d;

  color: var(--colors-white);
  > div {
    transition: all 0.2s ease;
    background-color: var(--colors-primary-bright);
  }
  :hover,
  &.hovering {
    transform: scale(1.1);
    transition: all 0.2s ease;
  }
  &:before {
    content: '';
    position: absolute;
    inset: 10px;
    filter: blur(14px);
    transform: translate3d(0px, 12px, -1px);
    border-radius: inherit;
    pointer-events: none;
    background: transparent linear-gradient(99deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
      padding-box;
    width: 95%;
  }
  &:hover:before {
    background: transparent linear-gradient(99deg, #0402fd 0%, #f4111b 100%) 0% 0% no-repeat;
    transition: all 0.2s;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 0.1rem;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 1rem;
    border-radius: 6px;
  }

  img {
    margin: 0 0.5rem;
  }

  &.pill {
    border-radius: 16px;
    padding: ${rem(2)} ${rem(10)};
    font-size: ${rem(14)};
    font-weight: 500;
    min-width: 4rem;
    margin-right: 0.5rem;
    color: var(--colors-grey--50);
    outline-color: rgba(185, 199, 247, 1);
  }
`;
