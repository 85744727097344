import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledLeftSide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  padding-top: 2rem;

  @media (${breakpoints.tablet}) {
    align-items: flex-start;
  }
  @media (${breakpoints.desktop}) {
    width: 40%;
    justify-content: flex-start;
    padding-top: 0;
    padding-bottom: 0;
  }
`;
