import styled from 'styled-components';

export const StyledLateralInfoSection = styled.div`
  background: var(--color-lightest-blue) 0% 0% no-repeat padding-box;
  box-shadow: 0px 13px 36px #676d9a29;
  border-radius: 10px;
  opacity: 1;
`;

export const StyledBennyLeftHeadImage = styled.div`
  margin-right: -16px;
  margin-left: -105px;
`;
