import React, { FC } from 'react';
import { LateralInfoSectionT } from '@factories/RowFactory/components/LateralInfoSection/types';
import { InfoIcon } from '@components/Molecules/InfoIcon/InfoIcon';
import { renderComponents } from '@lib/utils';
import { Cta } from '@components/Molecules';
import { StyledBennyLeftHeadImage, StyledLateralInfoSection } from './styled';
import { ButtonType } from '@components/Atoms/Button/types';
import BennyLeftHead from '@public/benny-left-head.png';
import Image from 'next/image';

export const LateralInfoSection: FC<LateralInfoSectionT> = ({ title, infoIcon, body, cta }) => {
  const infoIconComponent = infoIcon?.map(({ display, icon, label }) => {
    return display && <InfoIcon icon={icon} label={label} />;
  });

  return (
    <section className="slice flex flex-row-reverse">
      <StyledLateralInfoSection className="w-4/5 py-10 sm:py-16 pl-8 pr-4 sm:pl-24 sm:pr-12">
        <div className="md:w-4/5">
          <h2 className="slice__title">{title}</h2>
          <div className="flex flex-col sm:flex-row justify-between align-center my-4">
            {renderComponents({ components: infoIconComponent })}
          </div>
          <p className="slice__text">{body}</p>
        </div>
        <Cta
          display={cta.display}
          link={cta.link}
          label={cta.label}
          buttonType={ButtonType.PRIMARY}
        />
      </StyledLateralInfoSection>
      <StyledBennyLeftHeadImage>
        <Image src={BennyLeftHead} alt="Rider" />
      </StyledBennyLeftHeadImage>
    </section>
  );
};
