import styled, { keyframes } from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

const { tablet } = breakpoints;

const wave = keyframes`
 0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
`;

const wave2 = keyframes`
 0% {
    margin-left: -1600px;
  }
  100% {
    margin-left: 0;
  }
`;

const interpolate = keyframes`
 0%, 100% {
    transform: translate3d(0,-10px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
`;

export const WavesInverted = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: 2rem;
  height: 0;
  background: #e2ccde;
  color: var(--colors-white);
  transform: rotate(180deg);

  @media ${tablet} {
    padding-top: 0;
  }
`;
export const Waves = styled.div`
  padding: 0;
  margin: 0;
  height: 0;
  background: #e2ccde;
  color: var(--colors-white);
`;

export const WaveWrapper = styled.div`
  width: 100%;
  height: 103px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  overflow: hidden;

  @media ${breakpoints.tablet} {
    height: auto;
    transform: translateY(0);
    overflow: unset;
  }
`;

export const Wave = styled.div`
  display: none;
  background: url('/backgrounds/wave-promo-desktop.svg') repeat-x;
  position: absolute;
  width: 6400px;
  height: 230px;
  animation: ${wave} 12s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  z-index: -1;

  &:nth-of-type(2) {
    animation: ${wave} reverse 24s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      ${interpolate} reverse 10s ease-in-out -1.25s infinite;
    opacity: 0.7;
    top: 10px;
  }

  &:nth-of-type(3) {
    animation: ${wave2} 12s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      ${interpolate} reverse 10s ease-in-out -1.25s infinite;
    opacity: 0.5;
    top: 10px;
  }
  @media ${breakpoints.tablet} {
    display: block;
  }
`;

export const BabyWave = styled.div`
  background: url('/backgrounds/wave-promo-mobile.svg') no-repeat;
  background-size: contain;
  position: absolute;
  width: 768px;
  left: 0;
  top: -1px;
  height: 103px;
  @media ${breakpoints.tablet} {
    display: none;
  }
`;
