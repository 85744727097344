import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .under-card-text {
    a,
    p {
      font-size: 0.75rem;
      text-decoration: underline;
    }
  }
`;
