import styled from 'styled-components';
import { rem } from 'polished';
import ArrowDown from '@public/icons/Arrow-Down-dark.svg';
import { DefaultHeroContent } from '@components/Molecules/Hero/variants/Default/styled';
import { breakpoints } from '@theme/breakpoints';

export const StyledHeroServices = styled.div`
  min-height: 600px;
  background-image: var(--colors-background);

  &,
  p {
    color: var(--colors-primary-bright);
  }
  ${DefaultHeroContent};
  .hero-content {
    &__title p {
      @media ${breakpoints.desktop} {
        font-size: ${rem(55)};
      }
    }
    &__description {
      opacity: 0.8;
    }
  }
  padding-top: 8rem;
  @media ${breakpoints.desktop} {
    padding-top: 13rem;
  }
`;
export const StyledDropdown = styled.select`
  padding: ${rem(4)} ${rem(30)} ${rem(2)} ${rem(10)};
  border: 1px solid var(--colors-primary);
  border-radius: 16px;
  color: var(--colors-grey-100);
  line-height: 1.8;
  appearance: none;
  position: relative;
  background: url(${ArrowDown.src}) no-repeat 98% 50% white;
  min-width: fit-content;
`;
