import styled from 'styled-components';

export const StyledToggle = styled.div`
  display: inline-grid;
  grid-auto-columns: 1fr;
  background-color: #f2f2f2;
  box-shadow: inset 0 3px 6px #00000029;
  padding: 3px;
  border-radius: 8px;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    display: grid;
  }
`;
