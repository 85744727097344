import React, { FC } from 'react';
import { InfoIconProps } from './types';
import { DynamicIcon } from '@components/Atoms/Icon/Icon';

export const InfoIcon: FC<InfoIconProps> = ({ icon, label }) => {
  return (
    <div className="flex flex-column my-4 mr-2">
      <DynamicIcon icon={icon} className="flex items-center" al />
      <span className="font-bold ml-4 mb-0 sm:text-lg flex items-center">{label}</span>
    </div>
  );
};
