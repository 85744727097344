import styled from 'styled-components';
import { DefaultHeroContent } from './DefaultHeroContent';
import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';

export const StyledHeroDefault = styled.div`
  background-color: transparent;
  ${DefaultHeroContent};
  padding-bottom: ${rem(40)};

  @media ${breakpoints.tablet} {
    padding-bottom: ${rem(60)};
  }

  .hero-content {
    @media ${breakpoints.tablet} {
      text-align: center;
      &__description {
        max-width: 60%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &__buttons {
      > :first-child {
        margin-bottom: ${rem(20)};
      }
      @media ${breakpoints.tablet} {
        display: flex;
        justify-content: center;
        margin-top: ${rem(25)};
        > :first-child {
          margin-bottom: 0;
          margin-right: ${rem(20)};
        }
      }
    }
  }
`;
