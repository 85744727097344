import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';

export const StyledFilters = styled.div`
  overflow-x: hidden;
  display: flex;
  padding: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;

  & > * {
    flex: 1;
  }

  @media ${breakpoints.mobilelg} {
    justify-content: center;

    & > * {
      flex: unset;
    }
  }
`;
