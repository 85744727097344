import React, { FC, forwardRef, useState } from 'react';
import { Icon } from '@components/Atoms';
import { HiddenCheckBox, StyledCheckBox } from './Styled';

import { CheckBoxProps } from './types';

import Checked from '@public/icons/Checkbox-Gradient.png';
import Unchecked from '@public/icons/Checkbox-Outline.png';

export const CheckBox: FC<CheckBoxProps> = forwardRef(
  ({ id, checked, label, onClick, value, ...props }, ref) => {
    // Internal state required for hidden checkbox
    const [checkboxState, setCheckboxState] = useState(checked);

    return (
      <>
        <StyledCheckBox
          id={id}
          onClick={onClick ? () => onClick(checked ? false : value) : undefined}
        >
          <Icon xxs icon={checked ? Checked : Unchecked} />
          <label className="checkbox-label">{label}</label>
        </StyledCheckBox>
        {/* Use a hidden Checkbox for forms & screenreaders*/}
        <HiddenCheckBox
          type="checkbox"
          value={value}
          checked={checkboxState}
          onChange={() => setCheckboxState(!checkboxState)}
          {...props}
          ref={ref as any}
        />
      </>
    );
  },
);

CheckBox.displayName = 'Checkbox';
