import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledPhone = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  h3 {
    font-size: 1.5rem;
    color: var(--colors-primary);
    font-family: var(--font-geomanist);
    font-weight: 500;
    text-align: left;

    @media (${breakpoints.tablet}) {
      font-size: 2rem;
    }
  }

  p {
    text-align: left;
    font-family: var(--font-geomanist);
    font-weight: 300;
  }

  img {
    width: 2rem;
    margin-right: 1rem;
  }
`;
