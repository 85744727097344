import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledCardIcon = styled.div`
  margin-bottom: 2rem;
  width: 100%;
  div {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  span {
    width: 100%;
  }
  img {
    display: block;
    font-size: 0;
    height: 5rem !important;
    width: auto !important;
    // object-fit: contain;
    @media ${breakpoints.mobilelg} {
      // max-width: inherit;
      // max-height: inherit;
    }
  }
`;
