import React, { useRef, useEffect, FC, useState } from 'react';

import { fromMarkdown } from '@lib/utils';

import { SectionHeading } from '@components/Molecules';

import {
  StyledTrustPilotBox,
  StyledTrustPilotBoxContainer,
  StyledTrustPilotBoxMiniLogo,
} from '@components/Organisms/TrustPilotBox/styled';
import { TrustPilotBoxT } from '@components/Organisms/TrustPilotBox/types';

import MiniLogoBlueImage from '@public/mini-logo-blue.png';

export const TrustPilotBox: FC<TrustPilotBoxT> = ({
  display,
  heading,
  description,
  reviewsHeading,
}) => {
  const [isTrustPilotLoaded, setIsTrustPilotLoaded] = useState<boolean>(false);

  const ratingElementRef = useRef(null);
  const reviewsElementRef = useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      setTimeout(() => {
        window.Trustpilot.loadFromElement(ratingElementRef.current, true);
        window.Trustpilot.loadFromElement(reviewsElementRef.current, true);
      }, 50);
    }
    setIsTrustPilotLoaded(true);
  }, []);

  if (!display) {
    return null;
  }

  if (!isTrustPilotLoaded) {
    return null;
  }

  return (
    <StyledTrustPilotBox className="slice">
      <StyledTrustPilotBoxContainer className="relative mx-4 bg-white rounded-xl py-12 px-6 md:mx-auto md:container md:px-8 lg:pt-20 lg:px-32">
        <div className="relative">
          <h2 className="slice__title text-center">{heading}</h2>

          {description && (
            <div className="text-center mb-4">
              {fromMarkdown({ text: description, isHeading: false })}
            </div>
          )}

          <div
            ref={ratingElementRef}
            className="trustpilot-widget"
            data-locale="en-US"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="5e833841bdfd8d0001011a98"
            data-style-height="8rem"
            data-style-width="100%"
            data-theme="light"
          >
            <a
              href="https://www.trustpilot.com/review/heybroadband.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot
            </a>
          </div>

          <StyledTrustPilotBoxMiniLogo src={MiniLogoBlueImage.src} alt="H!B" />

          {reviewsHeading && <h3 className="slice__subtitle text-center">{reviewsHeading}</h3>}

          <div
            ref={reviewsElementRef}
            className="trustpilot-widget"
            data-locale="en-US"
            data-template-id="54ad5defc6454f065c28af8b"
            data-businessunit-id="5e833841bdfd8d0001011a98"
            data-style-height="240px"
            data-style-width="100%"
            data-theme="light"
            data-stars="4,5"
            data-review-languages="en"
          >
            <a
              href="https://www.trustpilot.com/review/heybroadband.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot
            </a>
          </div>
        </div>
      </StyledTrustPilotBoxContainer>
    </StyledTrustPilotBox>
  );
};
