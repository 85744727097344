import styled from 'styled-components';

export default styled.div`
  padding: 0.5rem 0;
  align-self: flex-start;
  p,
  label {
    font-size: 0.6875rem;
    line-height: 1.7;
    color: var(--colors-grey-50);
  }

  p {
    margin-top: 1rem;
  }

  label {
    margin-top: 0.2rem;
  }
`;
