import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (${breakpoints.mobilelg}) {
    flex-direction: row;
  }
`;
