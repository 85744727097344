/* eslint-disable react/display-name */
import React, { ComponentProps, FC } from 'react';

/**
 * Folds a list of React components into a single organism
 *
 * @param {[FC, any][]} components The list of React components
 * @returns {FC} The organism component, comprising the components as children
 */
export const combineComponents = (...components: [FC, any][]): FC => {
  return components.reduce(
    (AccumulatedComponents, [CurrentComponent, props]) => {
      return ({ children }: ComponentProps<FC>): JSX.Element => (
        <AccumulatedComponents>
          <CurrentComponent {...props}>{children}</CurrentComponent>
        </AccumulatedComponents>
      );
    },
    ({ children }) => <>{children}</>,
  );
};
