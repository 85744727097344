import styled from 'styled-components';

export default styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 47px 1fr;
  align-items: start;

  & > div.progress__image {
    grid-row-start: 1;
    justify-self: center;
    align-self: center;
  }

  & > span {
    grid-row-start: 2;
    justify-self: center;
  }
`;
