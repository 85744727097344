import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export default styled.section<{ purchasePage: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 3.5rem auto 0;
  padding: 3.5rem 0;
  background-color: #ffffff;
  border-radius: 19px;
  position: relative;

  box-shadow: 0 0 26px #7474c438;

  @media ${breakpoints.desktop} {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`;
