import styled from 'styled-components';

import { breakpoints } from '@theme/breakpoints';

export default styled.div`
  @media ${breakpoints.tabletMax} {
    width: 100%;
    > button {
      width: 100%;
    }
  }

  p {
    font-size: 0.8rem;
    a {
      color: blue;
      text-decoration: underline;
    }
  }
`;
