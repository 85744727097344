import styled from 'styled-components';

export default styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  > * {
    margin-right: 5px;
  }
`;
