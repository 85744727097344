import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-items: center;

  @media ${breakpoints.desktop} {
    flex-direction: row;
    gap: 2rem;
  }

  & > p {
    font-family: Geomanist, Segoe, 'Segoe UI', 'DejaVu Sans', 'Trebuchet MS', Verdana, 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    margin: 1rem 0;
  }
`;
