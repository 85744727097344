import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledAppLink = styled.a`
  display: flex;
  text-decoration: none !important;
  padding: 6px;
  text-align: left;
  background: linear-gradient(to bottom, #ff0000, #1d00fc);
  position: relative;
  border-radius: 0.2rem;
  max-width: 14rem;
  min-width: 14rem;
  min-height: 6rem;

  @media (${breakpoints.mobilelg}) {
    flex-basis: 50%;
  }
  @media (${breakpoints.tablet}) {
    flex-basis: 100%;
  }
  @media (${breakpoints.desktop}) {
    flex-basis: 50%;
    max-width: 16rem;
  }
  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }

  p {
    font-size: 0.8rem;
    font-family: Geomanist, sans-serif;
    color: var(--color-primary);
    margin-bottom: 0;
    line-height: 1rem;
    flex-basis: 50%;
    padding-right: 12px;
  }

  & > div {
    background: var(--color-blue);
    padding: 1rem;
    display: flex;
    align-items: center;
    border-radius: 0.1rem;
  }

  img {
    width: 6rem;
  }
`;
