/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { init } from '@emailjs/browser';
import { StyledInput } from '@components/Atoms/Input/styled';
import { Button, CheckBox, Icon } from '@components/Atoms';
import { Props } from './types';
import { ButtonType } from '@components/Atoms/Button/types';
import SuccessIcon from '@public/icons/Featured.svg';
import { StyledResponse, StyledOutageFormWrapper, StyledOutageForm } from './styled';
import { renderParagraphs } from '@lib/utils';
import { outageServer } from '@controllers/ProductController/ProductController';
import FailureIcon from '@public/icons/Featured-1.svg';

init('user_kpWsSBhCezkaPPptoqvqP');
export const OutageFormForm: FC<Props> = ({ data }) => {
  const {
    conditionsText,
    successHeader,
    successMessage,
    errorMessage,
    name,
    surname,
    email,
    postcode,
    checkbox,
    submitButton,
  } = data || {};
  const [showResponse, setShowResponse] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>(successMessage);
  const [responseIcon, setResponseIcon] = useState(SuccessIcon);

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    surname: Yup.string(),
    email: Yup.string()
      .required(`${email.error}`)
      .matches(/^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/),
    postcode: Yup.string(),
  });

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const ResponseMessage = () => {
    return (
      <StyledResponse className="mb-8">
        <h2>{successHeader}</h2>
        <div className="mb-8">
          <Icon lg icon={responseIcon} />
        </div>
        <h3>{renderParagraphs({ body: responseMessage, hasMarkdown: true })}</h3>
      </StyledResponse>
    );
  };

  const onSubmit = async (data) => {
    try {
      await outageServer(data.email);
      reset();
    } catch (error: any) {
      setResponseIcon(FailureIcon);
      setResponseMessage(`${errorMessage} (${error.message})`);
    }

    reset();
    setShowResponse(true);
  };

  interface IFormInputs {
    name: string;
    surname: string;
    email: string;
    postcode: string;
    agree: boolean;
  }

  return (
    <StyledOutageFormWrapper className="container lg:w-1/2 mx-auto pb-10">
      {!showResponse && (
        <StyledOutageForm onSubmit={handleSubmit(onSubmit)}>
          {name && (
            <>
              <StyledInput fullWidth>
                <input placeholder={name.label} type={name.type} {...register('name')} />
                {errors.name && <p className="error">{name.error}</p>}
              </StyledInput>
            </>
          )}
          {surname && (
            <>
              <StyledInput fullWidth className="mt-4">
                <input placeholder={surname.label} type={surname.type} {...register('surname')} />
                {errors.surname && <p className="error">{surname.error}</p>}
              </StyledInput>
            </>
          )}
          {email && (
            <>
              <StyledInput fullWidth className="mt-4">
                <input placeholder={email.label} type={email.type} {...register('email')} />
                {errors.email && <p className="error">{email.error}</p>}
              </StyledInput>
            </>
          )}
          {postcode && (
            <>
              <StyledInput fullWidth className="mt-4">
                <input
                  placeholder={postcode.label}
                  type={postcode.type}
                  {...register('postcode')}
                />
                {errors.postcode && <p className="error">{postcode.error}</p>}
              </StyledInput>
            </>
          )}
          {checkbox && (
            <StyledInput className="mt-4">
              <CheckBox
                id={checkbox.id}
                value={checkbox.value}
                checked={isChecked}
                onClick={() => setIsChecked(!isChecked)}
                {...register('agree')}
                label={checkbox.label}
              />
            </StyledInput>
          )}
          {conditionsText && <p className="mt-4">{conditionsText}</p>}
          {submitButton && (
            <Button
              className="mt-4"
              disabled={!isChecked}
              buttonType={ButtonType.PRIMARY}
              type="submit"
            >
              {submitButton.label}
            </Button>
          )}
        </StyledOutageForm>
      )}
      {showResponse && <ResponseMessage />}
    </StyledOutageFormWrapper>
  );
};
