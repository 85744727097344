import React, { FC } from 'react';
import { StyledChip } from './styled';
import { ChipProps } from './types';

export const Chip: FC<ChipProps & React.AllHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => {
  return (
    <StyledChip {...props} className={props.className}>
      {children}
    </StyledChip>
  );
};
