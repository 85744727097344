import styled from 'styled-components';

export default styled.div`
  padding: 1.5rem 0;

  & + [class*='StyledCheckoutCheckboxContainer'] {
    padding-top: 0;
    margin-top: -0.5rem;
  }
`;
