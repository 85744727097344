import { ButtonType } from '@components/Atoms/Button/types';
import { Event } from '@lib/types';

export interface Props {
  data: AccordionData;
  showBackground?: boolean;
}

export interface AccordionData {
  heading: string;
  headingBlue?: boolean;
  FAQ: AccordionItemData[];
  CTA?: CallToActionData;
  categories?: CategoryChip[];
  showFilters?: boolean;
  showBackground?: boolean;
  isSimple?: boolean;
  alignment?: 'left' | 'center' | 'right';
  theme?: 'default' | 'bgFadedBlue';
}

export type CallToActionData = {
  label: string;
  link: string;
};

export interface AccordionItemData {
  question: string;
  answer: string;
  category: CategoryType[];
  display: boolean;
  ctaDisplay?: boolean;
  ctaLabel?: string;
  ctaButtonType?: ButtonType;
  ctaLink?: string;
  ctaEvent?: Event;
}

export interface CategoryChip {
  label: string;
  category: CategoryType;
}

export enum CategoryType {
  INSTALLATION = 'Installation',
  SPEED = 'Speed',
  EQUIPMENT = 'Equipment',
  PHONE = 'Phone',
}
