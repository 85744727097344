import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from '@theme/breakpoints';

export default styled.p`
  font-size: ${rem(12)};
  font-weight: 400;
  color: #fff !important;
  display: flex;
  justify-content: center;
  margin-top: -0.25rem;

  & > span {
    display: none;
  }

  @media ${breakpoints.mobilelg} {
    font-size: ${rem(14)};
  }

  @media ${breakpoints.tablet} {
    margin-top: -0.5rem;
    & > span {
      display: inline;
    }
  }
`;
