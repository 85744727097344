import React, { FC } from 'react';
import { StyledHeroMSM } from './styled';
import { Props } from './types';
import { fromMarkdown } from '@lib/utils';

export const HeroMSM: FC<Props> = ({ data }) => {
  const { headline, subHeadline, address, onChangeAddress } = data;

  return (
    <StyledHeroMSM>
      <div className="hero-content container mx-auto lg:flex">
        <div className={`hero-content__column`}>
          {headline && (
            <h1 className="hero-content__title x-large">{fromMarkdown({ text: headline })}</h1>
          )}
          {subHeadline && (
            <h2 className="hero-content__subtitle">{fromMarkdown({ text: subHeadline })}</h2>
          )}
          <div className="hero-content__description">
            {address && (
              <p className="hero-content__address">
                {address}
                <button className="hero-content__address-change" onClick={onChangeAddress}>
                  Change
                </button>
              </p>
            )}
          </div>
        </div>
      </div>
    </StyledHeroMSM>
  );
};

export default HeroMSM;
