import { getFormattedPrice } from '@lib/utils';

export const getMonthlyPriceCalculated = ({
  monthly_price,
  exclude_vat_price,
  hasHomePhone,
  homePhoneProduct,
  addressType,
  promo,
  formatPrice = true,
}: any) => {
  let monthlyPriceCalculated = parseFloat(monthly_price);
  // If it's a business package, show excluding VAT.
  if (addressType === 'BUS') {
    // If the exclude VAT price is provided, use that
    if (exclude_vat_price) {
      monthlyPriceCalculated = parseFloat(exclude_vat_price);
    } else {
      // Otherwise try and calculate it, but this is kinda dodgy and hopefully a fallback that is never needed.
      monthlyPriceCalculated = monthlyPriceCalculated * 0.83334;
    }
  }

  if (promo?.promotion_type === 'RECURAMTOFF') {
    monthlyPriceCalculated = monthlyPriceCalculated - promo.promotion_value;
  }

  if (promo?.promotion_type === 'RECUR%OFF') {
    monthlyPriceCalculated =
      monthlyPriceCalculated - (monthlyPriceCalculated * promo.promotion_value) / 100;
  }

  if (hasHomePhone) {
    monthlyPriceCalculated += parseFloat(homePhoneProduct?.monthly_price || 0);
  }

  return formatPrice ? getFormattedPrice(monthlyPriceCalculated) : monthlyPriceCalculated;
};
