import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledContainer = styled.div`
  margin: 0 auto;
  padding: 0 1rem;
  background: white;
  border-radius: 1rem;
  padding: 2rem;

  @media (${breakpoints.tablet}) {
    margin: 0 2rem;
  }
  @media (${breakpoints.desktop}) {
    padding: 4rem;
    margin: 0 2rem;
    max-width: 1200px;
  }
`;
