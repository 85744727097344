import React, { FC } from 'react';
import { StyledHeroDefault } from './styled';
import { Format } from '@lib/types';
import { fromMarkdown, renderImage } from '@lib/utils';
import { Props } from './types';
import { Cta } from '@components/Molecules';

export const HeroDefault: FC<Props> = ({ data }) => {
  const { headline, featureImage, description, subHeadline, mobileImage, cta } = data || {};

  return (
    <StyledHeroDefault className="flex pt-10 lg:pt-40 flex-col relative">
      <div className="hero-content container md:w-4/5 mx-auto">
        {headline && <h1 className="hero-content__everyday">{fromMarkdown({ text: headline })}</h1>}
        {subHeadline && (
          <h2 className="hero-content__subtitle">{fromMarkdown({ text: subHeadline })}</h2>
        )}

        {description && (
          <div
            className={`hero-content__description ${
              !featureImage ? 'hero-content__description--no-image' : ''
            }`}
          >
            {fromMarkdown({ text: description, isHeading: false })}
          </div>
        )}
        {cta && (
          <div className="hero-content__buttons">
            <Cta
              label={cta.label}
              link={cta.link}
              display={cta.display}
              buttonType={cta.buttonType}
              event={cta.event}
            />
          </div>
        )}
        {featureImage &&
          renderImage({
            className: `hero-content__feature-image`,
            alternativeText: featureImage.alternativeText,
            formats: featureImage.formats,
            format: Format.LARGE,
          })}
        {mobileImage &&
          renderImage({
            className: `hero-content__product-image`,
            alternativeText: mobileImage.alternativeText,
            formats: mobileImage.formats,
            format: Format.LARGE,
          })}
      </div>
    </StyledHeroDefault>
  );
};

export default HeroDefault;
