import { MDUStatus, UprnStatus } from '@lib/types';

export const chooseMonthlyPriceByPostcode = ({
  postcodeItem,
  isCurrentPostcodePreorder,
  preorder_montly_price,
  monthly_price,
}) => {
  if (!isCurrentPostcodePreorder) {
    return monthly_price;
  } else {
    if (!postcodeItem?.ismdu && postcodeItem?.coverage === UprnStatus.CERTIFIED) {
      return monthly_price;
    } else if (postcodeItem?.ismdu === true && postcodeItem.coverage === UprnStatus.INSTALLED) {
      switch (postcodeItem?.mdu_status) {
        case MDUStatus.ADVANCED:
        case MDUStatus.RFS:
          return monthly_price;
        case MDUStatus.STARTED:
        default:
          return preorder_montly_price || monthly_price;
      }
    } else {
      return preorder_montly_price || monthly_price;
    }
  }
};
