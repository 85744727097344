import styled from 'styled-components';
export const StyledCheckBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: start;
  label {
    cursor: pointer;
    user-select: none;

    p {
      font-size: 0.8rem;
    }

    p:first-child {
      margin-top: 0;
    }

    a {
      text-decoration: underline;
    }
  }
  img {
    margin-right: 0.5rem;
  }
`;
