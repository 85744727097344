import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledContainer = styled.div`
  margin: 0 auto;
  background: transparent;
  @media (${breakpoints.desktop}) {
    margin: 0 2rem;
    max-width: 1200px;
  }

  & > div:first-of-type {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;

    @media (${breakpoints.tablet}) {
      flex-direction: row;
      padding: 0 2rem;
    }
  }
`;
