import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';

export const StyledDescription = styled.p`
  font-size: 1rem;
  margin: 0 auto;
  margin-top: 1rem;
  font-family: var(--font-geomanist);
  font-weight: 300;

  @media (${breakpoints.tablet}) {
    font-size: 1.2rem;
    max-width: 500px;
  }

  @media (${breakpoints.desktop}) {
    max-width: 600px;
  }
`;
