interface OptionsI {
  tag: string;
  attributes?: string;
}

const replacePlaceholderWithTags = (text: string, { tag, attributes }: OptionsI) => {
  return text.replace('~~', `<${tag} ${attributes || ''}>`).replace('~~', `</${tag}>`);
};

export default replacePlaceholderWithTags;
