import React from 'react';
import { SwitchProps } from '@components/Atoms/Switch/types';
import { StyledSwitch, StyledSwitchLabel } from '@components/Atoms/Switch/styled';

export const Switch = ({ checked, onChange, label, className }: SwitchProps) => {
  return (
    <button
      role="checkbox"
      aria-checked={checked}
      className="flex items-center"
      onClick={() => onChange(!checked)}
    >
      <StyledSwitch className={className} checked={checked}>
        <div className="switch-thumb" />
      </StyledSwitch>
      {label && <StyledSwitchLabel checked={checked}>{label}</StyledSwitchLabel>}
    </button>
  );
};
