import React from 'react';
import { Icon } from '../Icon';
import { StyledList, ListItem } from './styled';
import CheckIcon from '../../../public/icons/Check-3.svg';

export interface ListItemType {
  name?: string;
  icon?: string | React.ReactNode;
}

export interface ListProps {
  children?: React.ReactNode;
  list: Array<ListItemType>;
  icon?: string | React.ReactNode;
  className?: string;
}

export const List = ({ children, list, icon = CheckIcon, ...props }: ListProps) => {
  return (
    <StyledList {...props}>
      {list.map((listEntry: ListItemType) => (
        // Uses the overall list icon if there's no custom icon for a given list item
        <ListItem key={listEntry?.name}>
          {listEntry?.icon ? null : <Icon xs icon={icon} />}
          {listEntry?.icon ? <Icon xs icon={listEntry?.icon} /> : null}
          {listEntry?.name}
        </ListItem>
      ))}
      {/* Optionally, pass children for lists not generated from json */}
      {children}
    </StyledList>
  );
};
