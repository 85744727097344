import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from '@theme/breakpoints';

import BennyFooterImage from '@public/benny-footer.png';

export const StyledFooter = styled.div`
  position: relative;
  background-color: var(--colors-white);
  padding: 1rem;
  box-shadow: 0 -20px 11px -15px rgba(244, 17, 27, 0.08);
  @media ${breakpoints.desktop} {
    padding: 0;
  }

  &.bundle-page {
    background-color: var(--color-light-grey);

    .footer {
      &__copyright {
        color: var(--color-black);
        font-family: var(--font-geomanist);
      }
    }
  }

  .footer {
    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 0;
      padding-right: 0;

      @media ${breakpoints.desktop} {
        justify-content: space-between;
        flex-direction: row;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;

      @media ${breakpoints.tablet} {
        width: 66.666667%;

        & > div {
          margin-right: 2rem;
        }
      }

      @media ${breakpoints.desktop} {
        flex-direction: row;
      }
    }

    &__voucher-scheme {
      display: none;

      @media ${breakpoints.desktop} {
        display: block;
      }
    }

    &__links-container {
      margin-top: ${rem(35)};
      margin-bottom: ${rem(40)};

      @media ${breakpoints.desktop} {
        display: flex;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &__links {
      margin-right: 2rem;
      max-width: 155px;

      &__header {
        font-weight: 300;
        display: flex;
        align-items: center;
        margin-bottom: ${rem(12)};

        @media ${breakpoints.desktop} {
          margin-bottom: ${rem(20)};
          font-weight: bold;
        }

        span {
          margin-right: 0.75rem;
          @media ${breakpoints.desktop} {
            display: block;
            margin-right: 0;
          }
        }

        &::after {
          content: '';
          width: 12px;
          height: 12px;
          border-bottom: solid 2px #0000ff;
          border-right: solid 2px #0000ff;
          transform-origin: center;
          transform: rotate(45deg);
          transition: all 0.3s;

          @media ${breakpoints.desktop} {
            display: none;
          }
        }

        &--open {
          font-weight: bold;

          &::after {
            content: '';
            transform: rotate(225deg);
          }
        }
      }

      &__content {
        display: none;

        @media ${breakpoints.tablet} {
          width: 66.666667%;
        }

        @media ${breakpoints.desktop} {
          display: block;
        }

        &--open {
          display: block;
        }

        li {
          margin-bottom: ${rem(12)};

          a {
            display: inline-flex;
            border-bottom: 1px solid transparent;
            transition: all 300ms ease;

            :hover {
              border-bottom-color: var(--colors-text-secondary);
            }
          }
        }
      }
    }

    &__social {
      display: flex;
      list-style: none;
      margin-bottom: 1rem;

      @media ${breakpoints.desktop} {
        margin-bottom: 3rem;
      }

      &__item {
        position: relative;
        margin-right: 1rem;
        @media ${breakpoints.desktopLarge} {
          margin-right: 1.5rem;
        }

        &::before {
          content: '';
          width: 48px;
          height: 48px;
          position: absolute;
          left: 0;
          top: 4px;
          opacity: 0.685;
          background-image: linear-gradient(180deg, #f4111b 0%, #0402fd 100%);
          border-radius: 24px;
          filter: blur(5px);
        }

        &__icon {
          position: relative;
          width: 50px;
          height: 50px;
          transition: transform 0.2s;
          &:hover {
            transform: translateY(-3px);
          }
        }
      }
    }

    &__copyright {
      color: #55516093;
      cursor: pointer;
      text-decoration: underline;
    }

    &__navigation {
      display: flex;
      flex-direction: column;
      grid-row: 2;
      grid-column: 1;
      align-items: flex-start;
      @media ${breakpoints.desktop} {
        grid-row: 1;
        grid-column: 2;
        align-self: end;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        justify-content: flex-end;
        margin-right: ${rem(16)};
      }

      &__button {
        @media ${breakpoints.tabletMax} {
          margin: 0 0 ${rem(19)} 8px;
        }
      }
    }

    &__aside {
      .sign-up {
        position: relative;
        width: 120px;
        height: 39px;
        font: normal normal bold 18px/0px Geomanist;
        color: #ffffff;
      }

      @media ${breakpoints.desktop} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .get-HB,
        .header__basket-button,
        > a {
          display: block;
        }
      }
    }

    &__container {
      padding: ${rem(20)} 0;
      display: grid;
      grid-template-columns: 1fr auto;
      color: var(--colors-text-primary);

      @media ${breakpoints.desktop} {
        grid-template-columns: auto 1fr auto;
        padding-top: ${rem(33)};
      }
    }

    &__sharing-container {
      background-color: var(--color-mid-blue);
      margin-top: ${rem(60)};
      margin-left: 74px;
      max-width: 600px;
      padding: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: calc(100% - 74px);
      border-radius: 5px;
      box-shadow: 0px 13px 36px #676d9a29;
      position: relative;

      @media ${breakpoints.desktop} {
        margin-left: 74px;
      }

      &:before {
        position: absolute;
        width: 195px;
        height: 236px;
        content: '';
        background-image: url('${BennyFooterImage.src}');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 100%;
        left: -74px;
        top: -66px;
      }

      > p {
        position: relative;
      }
      .find-button {
        margin-top: 20px;
      }
    }

    &__sharing-title {
      color: var(--colors-primary-bright);
      font-size: var(--fonts-sizing-h5);
      position: relative;
      margin-bottom: 1rem;
    }
    &__dropdown {
      display: none;

      @media ${breakpoints.tabletMax} {
        position: static;
        transform: none;
        width: 100%;

        &--open {
          display: flex;
        }
        &--closed {
          display: none;
        }
      }
    }
    &__more {
      &::before {
        content: ' ';
        width: 200px;
        height: 150%;
        position: absolute;
        top: 50%;
        left: 132%;
        transform: translateX(-50%);
      }
      &:hover {
        .footer__dropdown {
          @media ${breakpoints.desktop} {
            display: flex;
            z-index: 9999;
          }
        }

        .footer__navigation__button {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }
`;

export const FooterWrapper = styled.div`
  padding-top: 1.25rem;
  padding-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  @media ${breakpoints.tablet} {
    padding-top: 1.25rem;
    padding-bottom: 4rem;
  }
`;

export const FooterCopyright = styled.p`
  margin-bottom: 2rem;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  @media ${breakpoints.tablet} {
    padding-left: 0;
    padding-right: 0;
    padding-top: 1.5rem;
    padding-bottom: 3rem;
    margin-bottom: 0;
  }
`;
