import React, { FC } from 'react';
import Link from 'next/link';
import { StyledLinkList, StyledLinkItems } from './styled';

import { Props } from './types';

export const LinkList: FC<Props> = ({ data }) => {
  const { heading, listItem } = data || {};

  return (
    <StyledLinkList>
      {heading && <h5 className="x-small">{heading}</h5>}
      {listItem && (
        <StyledLinkItems>
          {listItem.map((item) => {
            return (
              <Link key={item.label} href={item.link} passHref>
                <a className="" href={item.link}>
                  {item.label}
                </a>
              </Link>
            );
          })}
        </StyledLinkItems>
      )}
    </StyledLinkList>
  );
};
