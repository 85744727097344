import React, { FC, Key } from 'react';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import { ImageDataT } from '@lib/types';
import { Icon } from '@components/Atoms';
import { rem } from 'polished';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CarouselNextArrow } from '@components/Atoms/CarouselArrow';

interface TwoColumnGridI {
  display: boolean;
  title: string;
  subtitle?: string;
  cards: {
    key: Key;
    display: boolean;
    heading: string;
    body: string;
    icon: ImageDataT;
  }[];
}

const STYLED = {
  wrapper: styled.section`
    background-color: var(--color-blue);
    & > div {
      align-items: start;

      @media ${breakpoints.desktop} {
        align-items: center;
      }
    }
  `,
  title: styled.h2`
    font-size: ${rem(30)};
    color: var(--color-primary);

    @media ${breakpoints.tablet} {
      font-size: ${rem(40)};
    }

    @media ${breakpoints.desktop} {
      font-size: ${rem(53)};
    }
  `,
  subtitle: styled.p`
    font-size: ${rem(16)};

    @media ${breakpoints.desktop} {
      font-size: ${rem(21)};
    }
  `,
  grid: styled.div`
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
    margin-top: 1rem;

    .slick-slider {
      .slick-prev {
        display: none !important;
      }
      .slick-next {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        right: -3px;
        &:before {
          display: inline-block;
        }
        @media ${breakpoints.desktopLarge} {
          border-radius: 50%;
          left: 95%;
        }
      }
      .slick-dots {
        text-align: center;
        bottom: -3rem;
        @media ${breakpoints.desktopLarge} {
          padding-left: 2.5rem;
        }
        li {
          button {
            &:before {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background: var(--color-grey);
              opacity: 0.3;
              color: transparent;
            }
          }
          &.slick-active {
            button {
              &:before {
                background: var(--color-grey);
                opacity: 1;
              }
            }
          }
        }
      }
      .slick-list {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .slick-track {
        display: flex;
        gap: 1rem;
      }
    }

    @media ${breakpoints.tablet} {
      .slider.regular {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.5rem;
      }
    }

    @media ${breakpoints.desktop} {
      display: flex;
      justify-content: center;

      .slider.regular {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;
        max-width: ${rem(1300)};
      }
    }
  `,
};

const STYLEDCARD = {
  wrapper: styled.div`
    background-color: var(--color-white);
    border-radius: 10px;
    padding: ${rem(30)};

    img {
      margin-bottom: ${rem(20)};

      @media ${breakpoints.tablet} {
        margin-bottom: 0;
        width: ${rem(122)};
        height: ${rem(122)};
      }
    }

    @media ${breakpoints.tablet} {
      display: flex;
      gap: 3rem;
      align-items: center;
      padding: ${rem(52)} ${rem(30)};
    }
  `,
  content: styled.div`
    h3 {
      font-size: ${rem(20)};
      margin-bottom: ${rem(10)};

      @media ${breakpoints.desktop} {
        font-size: ${rem(30)};
      }
    }

    p {
      font-size: ${rem(16)};
      margin-bottom: ${rem(10)};

      @media ${breakpoints.desktop} {
        font-size: ${rem(21)};
      }
    }
  `,
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  nextArrow: <CarouselNextArrow />,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    { breakpoint: 100000, settings: 'unslick' },
  ],
};

const GridCard = ({ card }) => {
  return (
    <STYLEDCARD.wrapper>
      <Icon lg icon={card.icon.url} altText={card.icon.alternativeText} />
      <STYLEDCARD.content>
        <h3>{card.heading}</h3>
        <p>{card.body}</p>
      </STYLEDCARD.content>
    </STYLEDCARD.wrapper>
  );
};

export const TwoColumnGrid: FC<TwoColumnGridI> = ({ display, title, subtitle, cards }) => {
  if (!display) return null;

  const GridCards = cards?.map((card, key) => {
    const Component = <GridCard key={key} card={card} />;

    return Component;
  });

  return (
    <STYLED.wrapper className="slice">
      <div className="flex-col slice__inner">
        <STYLED.title
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        {subtitle && (
          <STYLED.subtitle
            dangerouslySetInnerHTML={{
              __html: subtitle,
            }}
          />
        )}
        <STYLED.grid>
          <Slider {...settings}>{GridCards}</Slider>
        </STYLED.grid>
      </div>
    </STYLED.wrapper>
  );
};
