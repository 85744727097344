import { renderImage } from '@lib/utils/index';
import { Format } from '@lib/types';
import { FC } from 'react';
import styled from 'styled-components';

interface BasicImageProps {
  image: {
    id: number;
    name: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
    formats: {
      large: {
        ext: string;
        url: string;
        hash: string;
        mime: string;
        name: string;
        path: string;
        size: number;
        width: number;
        height: number;
      };
      small: {
        ext: string;
        url: string;
        hash: string;
        mime: string;
        name: string;
        path: string;
        size: number;
        width: number;
        height: number;
      };
      medium: {
        ext: string;
        url: string;
        hash: string;
        mime: string;
        name: string;
        path: string;
        size: number;
        width: number;
        height: number;
      };
      thumbnail: {
        ext: string;
        url: string;
        hash: string;
        mime: string;
        name: string;
        path: string;
        size: number;
        width: number;
        height: number;
      };
    };
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: string;
    provider: string;
    provider_metadata: string;
    created_at: string;
    updated_at: string;
  };
}

export const BasicImage: FC<BasicImageProps> = ({ image }) => {
  return (
    <StyledBasicImage>
      {renderImage({
        className: `basic-image`,
        alternativeText: image.alternativeText,
        formats: image.formats,
        format: Format.LARGE,
      })}
    </StyledBasicImage>
  );
};

const StyledBasicImage = styled.div`
  .basic-image {
    display: flex;
    justify-content: center;
  }
`;
