let themeCss = '';

const generateGlobalStyle = (themeObject: object, prefix?: string) => {
  // Create list of keys for our themeObject
  const propertyList = Object.keys(themeObject);
  // Start a loop of themeProperties from the themeObject
  propertyList.forEach((key) => {
    // ThemeObject can have nested properties. Check if we're on an object, and if so, recurse
    // We also pass a prefix here to differentiate theme properties by their category
    if (typeof themeObject[key] === 'object') {
      generateGlobalStyle(themeObject[key], prefix ? prefix + `-${key}` : key);
    } else {
      // Otherwise we return the new theme Property as a CSS variable
      themeCss += `--${prefix ? `${prefix}-` : ''}${key}: ${themeObject[key]};`;
    }
  });
  // Return the themeCss as a string to be used in template literals
  return themeCss;
};

export default generateGlobalStyle;
