import styled, { css } from 'styled-components';

function columnStyle({ $column = false }) {
  if ($column) {
    return css`
      flex-direction: column;
      align-items: center;
    `;
  }
}

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > * {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 250px;
  }
  ${columnStyle}
`;
