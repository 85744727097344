import { FullLengthCard } from '@components/Molecules/FullLengthCard';

import { StyledContainer } from './styled/StyledContainer';
import { StyledFullLengthCardBlock } from './styled/StyledFullLengthCardBlock';

const FullLengthCardBlock = ({ Card }) => {
  return (
    <StyledFullLengthCardBlock>
      <StyledContainer>
        <FullLengthCard {...Card} />
      </StyledContainer>
    </StyledFullLengthCardBlock>
  );
};

export { FullLengthCardBlock };
