import React, { FC } from 'react';

import { TextElementListProps } from './types';
import { StyledTextElementList } from '@components/Molecules/TextElementList/styled';
import { fromMarkdown, renderImage } from '@lib/utils';
import { TextElementT } from '@factories/RowFactory/components/TextElement/types';
import { TextElement } from '@components/Atoms';
import { Format, Layout } from '@lib/types';

export const TextElementList: FC<TextElementListProps> = ({
  heading,
  textElements,
  listStyle,
  image,
}) => {
  const listStyleClass = 'listStyle' + listStyle;

  return (
    <StyledTextElementList className="container lg:w-4/5 mx-auto">
      {heading && (
        <h2 className="textElementList__heading mb-10 text-center">
          {fromMarkdown({ text: heading })}
        </h2>
      )}
      {textElements && (
        <div className="flex flex-col-reverse lg:flex-row">
          <div className="text-center lg:w-1/2">
            <ul className={`textElementList__items mb-10 ${listStyleClass}`}>
              {textElements.map((item: TextElementT, index) => {
                return (
                  <li className="my-4" key={index}>
                    <TextElement text={item.text} />
                  </li>
                );
              })}
            </ul>
          </div>
          {image &&
            renderImage({
              className: 'text-center lg:w-1/2',
              alternativeText: image.alternativeText,
              formats: image.formats,
              format: Format.SMALL,
              layout: Layout.RESPONSIVE,
            })}
        </div>
      )}
    </StyledTextElementList>
  );
};
