import { useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import browserStorage from 'store';

const composeStorageContent = (content: any, expireTime?: number) => {
  if (!expireTime) return content;

  return {
    content,
    expireAt: Date.now() + expireTime,
  };
};

export default function usePersistState<S>(
  initialState: S | (() => S),
  storageKey: string,
  expireTime?: number,
): [S, Dispatch<SetStateAction<S>>] {
  const [state, setInternalState] = useState(initialState);

  useEffect(() => {
    const storageInBrowser = browserStorage.get(storageKey);

    if (storageInBrowser) {
      if (storageInBrowser.expireAt) {
        if (Date.now() < storageInBrowser.expireAt) {
          setInternalState(storageInBrowser.content);
        }
      } else if (!expireTime) {
        setInternalState(storageInBrowser);
      }
    }
  }, [expireTime, storageKey]);

  const setState = useCallback(
    (newState) => {
      if (typeof newState === 'function') {
        browserStorage.set(storageKey, composeStorageContent(newState(state), expireTime));
        setInternalState(newState(state));
      } else {
        browserStorage.set(storageKey, composeStorageContent(newState, expireTime));
        setInternalState(newState);
      }
    },
    [storageKey, state, expireTime],
  );

  return [state, setState];
}
