import usePersistState from '@hooks/usePersistState';
import { createContext, FC, useCallback, useEffect, useState } from 'react';
import {
  CheckoutDataI,
  CheckoutProviderT,
  CustomerAdditionalI,
  CustomerI,
  DirectDebitDetailsI,
  InstallationI,
  OrderI,
} from './types';
import { addHours } from 'date-fns';
import browserStorage from 'store';
import _ from 'cypress/types/lodash';

export const CheckoutContext = createContext<CheckoutDataI>({
  customer: {
    contact_title: {},
    contact_name: '',
    contact_surname: '',
    email: '',
    confirm: '',
    phone: '',
    birthdate: '',
    origin: {},
    company_name: '',
    company_number: '',
    building_number: '',
    street_name: '',
    town: '',
    postcode: '',
    accept_marketing: false,
    accept_terms: false,
    voucher: false,
  },
  customerAdditional: {
    hasAlternativeContactMethod: undefined,
    hasHealthcareOrMedicalAlarm: undefined,
    confirmResponsibility: undefined,
    wantsToRetainExistingLandline: undefined,
    landlineNumber: undefined,
    currentProvider: undefined,
  },
  directDebitDetails: {
    account_name: '',
    account_number: '',
    sort_code: '',
    authorise_direct_debit: false,
  },
  setCheckoutField: (field, value) => {},
  setAdditionalCheckoutField: (field, value) => {},
  setDirectDebitDetails: (values) => {},
  setInstallationDate: (date) => {},
  setInstallationTime: (startTime, endTime, label) => {},
  setTermsAccepted: (termsAccepted) => {},
  setOrder: (data) => {},
  setOrderCode: (code) => {},
  clearCheckout: () => {},
  startCheckout: () => {},
  setInstallationSlots: (slots) => {},
  setCheckoutEndTime: () => {},
  setShowStickyCheckoutFooter: () => {},
  order: {
    orderNumber: '',
    email: '',
    customerCode: '',
    action: 'SIGNUP',
    productId: '',
  },
  installation: {
    date: '',
    label: '',
    slots: [],
    startTime: '',
    endTime: '',
  },
  checkoutEndTime: null,
  showStickyCheckoutFooter: false,
  checkoutSkipTo: '',
  setCheckoutSkipTo: () => {},
});

// 1 hour checkout slot
const fourHours = 60 * 60 * 1000 * 4;
const oneDay = 24 * 60 * 60 * 1000;
const STATE_EXPIRY_TIME = process.env.NODE_ENV === 'development' ? oneDay : fourHours;

export const CheckoutProvider: FC<CheckoutProviderT> = ({ children }) => {
  const initCustomer = {
    contact_title: {},
    contact_name: '',
    contact_surname: '',
    email: '',
    confirm: '',
    phone: '',
    birthdate: '',
    origin: {},
    company_name: '',
    company_number: '',
    building_number: '',
    street_name: '',
    town: '',
    postcode: '',
    accept_marketing: false,
    accept_terms: false,
    voucher: false,
  };

  const [checkoutSkipTo, setCheckoutSkipTo] = usePersistState<string>(
    '',
    'checkoutSkipTo',
    STATE_EXPIRY_TIME,
  );

  const [customer, _setCustomerDetails] = usePersistState<CustomerI>(
    initCustomer,
    'customerDetails',
    STATE_EXPIRY_TIME,
  );

  const initCustomerAdditional = {
    hasAlternativeContactMethod: undefined,
    hasHealthcareOrMedicalAlarm: undefined,
    confirmResponsibility: undefined,
    wantsToRetainExistingLandline: undefined,
    landlineNumber: '',
    currentProvider: '',
  };

  const [customerAdditional, _setAdditionalDetails] = usePersistState<CustomerAdditionalI>(
    initCustomerAdditional,
    'customerAdditionalDetails',
    STATE_EXPIRY_TIME,
  );

  const initInstallation = {
    date: '',
    startTime: '',
    endTime: '',
    label: '',
    slots: [],
  };

  const [installation, _setInstallation] = usePersistState<InstallationI>(
    initInstallation,
    'installation',
    STATE_EXPIRY_TIME,
  );

  const initDirectDebitDetails = {
    account_name: '',
    account_number: '',
    sort_code: '',
    authorise_direct_debit: false,
  };

  const [directDebitDetails, _setDirectDebitDetails] = usePersistState<DirectDebitDetailsI>(
    initDirectDebitDetails,
    'directDebitDetails',
    STATE_EXPIRY_TIME,
  );

  const initOrder = {
    orderNumber: '',
    email: '',
    customerCode: '',
    action: 'SIGNUP',
    productId: '',
  };

  const [order, _setOrder] = usePersistState<OrderI>(initOrder, 'order', STATE_EXPIRY_TIME);

  const [showStickyCheckoutFooter, _setShowStickyCheckoutFooter] = useState<any>(false);

  const setCheckoutField = useCallback(
    (field: string, value: string | boolean) => {
      _setCustomerDetails({ ...customer, [field]: value });
    },
    [_setCustomerDetails],
  );

  const setAdditionalCheckoutField = useCallback(
    (field: string, value: string) => {
      _setAdditionalDetails({ ...customerAdditional, [field]: value });
    },
    [_setAdditionalDetails],
  );

  const setInstallationDate = useCallback(
    (date: string) => {
      _setInstallation({ ...installation, date });
    },
    [_setInstallation],
  );

  const setInstallationTime = useCallback(
    (startTime: string, endTime: string, label: string) => {
      _setInstallation({ ...installation, startTime, endTime, label });
    },
    [_setInstallation],
  );

  const setInstallationSlots = useCallback(
    (slots: Array<any>) => {
      _setInstallation({ ...installation, slots: slots || [] });
    },
    [_setInstallation],
  );

  const setDirectDebitDetails = useCallback(
    (values: object) => {
      _setDirectDebitDetails({ ...directDebitDetails, ...values });
    },
    [_setDirectDebitDetails],
  );

  const setTermsAccepted = useCallback(
    (termsAccepted) => {
      _setCustomerDetails({ ...customer, accept_terms: termsAccepted });
    },
    [_setCustomerDetails],
  );

  const setOrder = useCallback(
    (order) => {
      _setOrder(order);
    },
    [_setOrder],
  );

  const setOrderCode = useCallback(
    (code: string) => {
      _setOrder({
        ...order,
        orderNumber: code,
        customerCode: code,
      });
    },
    [_setOrder],
  );

  const clearCheckout = useCallback(() => {
    _setCustomerDetails(initCustomer);
    _setAdditionalDetails(initCustomerAdditional);
    _setDirectDebitDetails(initDirectDebitDetails);

    // clear the order number so we create new orders when new checkout begins
    _setOrder((prev) => ({
      ...prev,
      orderNumber: '',
    }));
  }, [
    _setOrder,
    _setCustomerDetails,
    _setAdditionalDetails,
    _setInstallation,
    _setDirectDebitDetails,
  ]);

  const startCheckout = useCallback(() => {
    _setOrder(initOrder);
    _setInstallation(initInstallation);
    _setDirectDebitDetails(initDirectDebitDetails);
  }, [_setOrder, _setInstallation, _setDirectDebitDetails]);

  // 1 hour checkout slot
  const oneHour = 60 * 60 * 1000;
  const [checkoutEndTime, _setCheckoutEndTime] = usePersistState<Date | null>(
    null,
    'checkoutEndTime',
    oneHour,
  );

  const setCheckoutEndTime = useCallback(() => {
    if (browserStorage.get('checkoutEndTime')) {
      return false;
    }
    _setCheckoutEndTime(addHours(new Date(), 1));
  }, []);

  const data: CheckoutDataI = {
    customer,
    customerAdditional,
    installation,
    directDebitDetails,
    order,
    showStickyCheckoutFooter,
    setCheckoutField,
    setAdditionalCheckoutField,
    setDirectDebitDetails,
    setInstallationDate,
    setInstallationTime,
    setTermsAccepted,
    setOrder,
    setOrderCode,
    setInstallationSlots,
    setCheckoutEndTime,
    clearCheckout,
    checkoutEndTime,
    startCheckout,
    setShowStickyCheckoutFooter: _setShowStickyCheckoutFooter,
    checkoutSkipTo,
    setCheckoutSkipTo,
  };

  return <CheckoutContext.Provider value={data}>{children}</CheckoutContext.Provider>;
};
