import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f7f7ff;
  border-radius: 0.5rem;
  border: 1px solid #d0d6e6;
  padding: 1rem;
  cursor: pointer;
  min-height: 10rem;
  max-height: 10rem;
  max-width: 200px;
  min-width: 200px;
  position: relative;

  @media (${breakpoints.mobilelg}) {
    max-width: 160px;
    min-width: 160px;
  }
  @media (${breakpoints.desktop}) {
    max-width: 200px;
    min-width: 200px;
  }

  .card-shadow {
    content: '';
    position: absolute;
    inset: 10px;
    filter: blur(14px);
    transform: translate3d(0px, 12px, -1px);
    border-radius: inherit;
    pointer-events: none;
    background: transparent linear-gradient(99deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
      padding-box;
    width: 95%;
  }

  .card-background {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f7f7ff;
    border-radius: 0.5rem;
    border: 1px solid #d0d6e6;
    padding: 1rem;
    cursor: pointer;
    min-height: 10rem;
    max-height: 10rem;
    max-width: 200px;
    min-width: 200px;
    position: absolute;
    top: 0;

    @media (${breakpoints.mobilelg}) {
      max-width: 160px;
      min-width: 160px;
    }
    @media (${breakpoints.desktop}) {
      max-width: 200px;
      min-width: 200px;
    }
  }

  .card-content {
    z-index: 1;
  }

  h3 {
    font-size: 0.8rem;
    font-family: var(--font-geomanist);
    font-weight: 500;
    margin-top: 1rem;
  }

  p {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    color: var(--color-primary);
    text-decoration: underline;
    font-family: var(--font-geomanist);
    font-weight: 500;
  }

  transform-style: preserve-3d;

  > div {
    transition: all 0.2s ease;
    // background-color: var(--colors-primary-bright);
  }

  :hover,
  &.hovering {
    .card-background {
      transform: scaleX(1.1) scaleY(1.03);
      transition: all 0.2s ease;
      background: var(--color-white);
    }

    .card-shadow {
      background: transparent linear-gradient(99deg, #0402fd 0%, #f4111b 100%) 0% 0% no-repeat
        padding-box;
      filter: blur(20px);
    }
  }
`;
