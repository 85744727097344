import React, { FC, Key } from 'react';

import { IconCardT, IconCardListT } from './types';

import { Card } from '@components/Molecules';
import { CardType } from '@components/Molecules/Card/types';

import { StyledCTA, StyledIconCards } from './styled';
import { fromMarkdown, renderParagraphs, renderComponents } from '@lib/utils';
import { DynamicIcon } from '@components/Atoms/Icon/Icon';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CarouselNextArrow, CarouselPrevArrow } from '@components/Atoms/CarouselArrow';

//  TODO:  Extract the Icon Card into a Molecule Component
const IconCard: FC<IconCardT> = ({ heading, body, icon, display = true }) => {
  if (!display) return null;

  const block = `card-${CardType.ICON}`;

  return (
    <Card type={CardType.ICON}>
      <DynamicIcon icon={icon} className={`${block}__image`} al />
      <h2 className={`${block}__heading small`}>{fromMarkdown({ text: heading })}</h2>
      {renderParagraphs({ body, className: `${block}__body` })}
    </Card>
  );
};

export const IconCardList: FC<IconCardListT> = ({ iconCards, ctas }) => {
  if (!iconCards.length) return null;

  const IconCards = iconCards?.map(({ heading, body, icon, display }) => {
    const Component = <IconCard heading={heading} body={body} icon={icon} display={display} />;

    return Component;
  });

  const Ctas = ctas?.map(({ label, link, buttonType, display, event }, key: Key) => {
    const Component = (
      <StyledCTA
        key={key}
        label={label}
        link={link}
        display={display}
        buttonType={buttonType}
        event={event}
      />
    );

    return Component;
  });

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <StyledIconCards className="pt-8 pb-12">
      {!!iconCards?.length && (
        <div className="px-6 py-12 mx-4 md:mx-auto md:container md:px-8 lg:px-32">
          <Slider className="px-4 sm:px-6 md:px-8 lg:px-16" {...settings}>
            {renderComponents({ components: IconCards })}
          </Slider>
        </div>
      )}

      {!!renderComponents?.length && (
        <div className="card__buttons">{renderComponents({ components: Ctas })}</div>
      )}
    </StyledIconCards>
  );
};
