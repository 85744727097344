import React, { FC } from 'react';

import { StyledStickyBanner, StickyBannerFakeButton } from './styled';
import { StickyBannerProps } from './types';

export const StickyBanner: FC<StickyBannerProps> = ({
  display,
  text,
  buttonText,
  ...props
}: StickyBannerProps) => {
  return (
    <StyledStickyBanner $display={display} {...props}>
      <span>{text}</span>
      <StickyBannerFakeButton>{buttonText}</StickyBannerFakeButton>
    </StyledStickyBanner>
  );
};
