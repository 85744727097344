import styled from 'styled-components';

export const StyledBanner = styled.a`
  background-color: var(--colors-grey-100);
  color: var(--colors-white);
  text-align: center;
  font-family: var(--fonts-family-secondary);
  width: 100%;
  > span {
    display: block;
    margin: 0.5rem 0;
    font-size: 1.125rem;
  }
  :hover {
    text-decoration: underline;
  }
`;
