// @todo Make this more robust, this should not be filtering by name!!
function checkIsHomePhone(product) {
  return product.name.match(/Home Phone/gim);
}

function getHomePhoneForProduct({ products, product }) {
  const homePhoneProduct = products
    .filter((_product) => product?.addons?.includes(_product.id_product))
    .find((_product) => checkIsHomePhone(_product));
  return homePhoneProduct;
}

export { checkIsHomePhone, getHomePhoneForProduct };
