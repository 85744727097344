import styled from 'styled-components';

import { breakpoints } from '@theme/breakpoints';

import CloudImage from '@public/cloud.png';

export const StyledTrustPilotBox = styled.section`
  background-image: url('${CloudImage.src}'), url('${CloudImage.src}'),
    linear-gradient(180deg, #f9d8de 0%, #b4b4fe 100%);
  background-repeat: no-repeat;
  background-position: -70px 0, right -70px top 5rem, top center;
  background-size: 15rem auto, 15rem auto, auto;
  @media ${breakpoints.desktop} {
    padding-top: 16rem;
    padding-bottom: 16rem;
    background-position: 3rem 10rem, right 1.5rem top 25rem, top center;
    background-size: 15rem auto, 15rem auto, auto;
    @keyframes clouds2 {
      0% {
        background-position: -25% 10%, -25% 90%, top center;
      }
      100% {
        background-position: 125% 10%, 125% 90%, top center;
      }
    }
    animation: clouds2 12s infinite;
    animation-timing-function: linear;
  }
`;
