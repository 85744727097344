import styled from 'styled-components';

interface StyledModalT {
  active: boolean;
  width?: number;
  height?: number;
}

// eslint-disable-next-line prettier/prettier
export const StyledModal = styled.div<StyledModalT>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--colors-white);
  width: ${({ width }) => (width ? `${width}px` : 'unset')};
  height: ${({ height }) => (height ? `${height}px` : 'unset')};
  max-width: 95%;
  border: 1px solid var(--colors-primary-30);
  padding: 1rem;
  border-radius: 8px;
  @media screen and (min-width: 516px) {
    max-width: 400px;
  }
`;
