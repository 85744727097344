import styled, { css } from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import Backgroundcircles from '@public/backgrounds/gradient-circles.svg';

function hasGradientBackground({
  $gradientBackground = false,
  $salesCountdownIsDisplayed = false,
}) {
  if ($gradientBackground) {
    return css`
      padding-top: ${$salesCountdownIsDisplayed ? '224px' : '80px'};
      @media ${breakpoints.desktop} {
        background: url(${Backgroundcircles.src}) no-repeat;
        background-size: 2000px;
        background-position: top center;
        padding-top: ${$salesCountdownIsDisplayed ? '180px' : '103px'};
      }
    `;
  }

  return css`
    padding-top: ${$salesCountdownIsDisplayed ? '224px' : '80px'};
    @media ${breakpoints.desktop} {
      padding-top: ${$salesCountdownIsDisplayed ? '180px' : '103px'};
    }
  `;
}

export const StyledMain = styled.main<any>`
  ${hasGradientBackground}
`;

export const StyledBody = styled.div<any>`
  ${hasGradientBackground}
`;
