import React, { FC } from 'react';

import { fromMarkdown } from '@lib/utils';

import { SectionHeadingT } from './types';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

const StyledSectionHeading = styled.div`
  text-align: center;
  padding: 0 1rem;

  @media ${breakpoints.tablet} {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const SectionHeading: FC<SectionHeadingT> = ({ heading, subHeading }) => (
  <StyledSectionHeading>
    <h2 className="title">{fromMarkdown({ text: heading })}</h2>
    {subHeading && <h3 className="subtitle">{fromMarkdown({ text: subHeading })}</h3>}
  </StyledSectionHeading>
);
