export const filters = {
  addressType: [
    {
      key: 'RES',
      value: 'Residential',
    },
    {
      key: 'HWRK',
      value: 'Home Worker',
    },
    {
      key: 'BUS',
      value: 'Business',
    },
  ],
  packageType: [
    {
      key: 'BBO',
      value: 'Broadband',
    },
    {
      key: 'BBANDP',
      value: 'Broadband & Phone',
    },
  ],
  months: [
    {
      key: '24',
      value: '24 months',
    },
    {
      key: '12',
      value: '12 months',
    },
    {
      key: '0',
      value: '30 days',
    },
  ],
};
