import { StyledTitle } from './styled/StyledTitle';
import { StyledDescription } from './styled/StyledDescription';
import { StyledHeroWithCards } from './styled/StyledHeroWithCards';
import Image from 'next/image';
import { StyledFloatingImage } from './styled/StyledFloatingImage';
import { StyledContainer } from './styled/StyledContainer';
import { StyledCards } from './styled/StyledCards';
import { StyledContent } from './styled/StyledContent';
import { HeroCard } from '@components/Molecules/HeroCard/HeroCard';
import Link from 'next/link';
import { fromMarkdown } from '@lib/utils';

const HeroWithCards = ({ Title, Description, ImageLeft, ImageRight, Cards, UnderCardText }) => {
  const linkFromMarkdown = UnderCardText ? UnderCardText.match(/\(([^)]+)\)/)[1] : '';
  const textFromMarkdown = UnderCardText ? UnderCardText.match(/\[([^)]+)\]/)[1] : '';
  return (
    <StyledHeroWithCards>
      <StyledContainer>
        <StyledContent>
          <header>
            <StyledTitle>{Title}</StyledTitle>
            <StyledDescription>{Description}</StyledDescription>
          </header>
          <StyledCards>
            {Cards.map((card, index) => {
              return <HeroCard {...card} key={index} />;
            })}
          </StyledCards>
          <div className="mt-4 opacity-80">
            <p>
              <span className="under-card-text underline">
                {UnderCardText && !linkFromMarkdown
                  ? fromMarkdown({ text: UnderCardText, isHeading: false })
                  : linkFromMarkdown && <Link href={linkFromMarkdown}>{textFromMarkdown}</Link>}
              </span>
            </p>
          </div>
        </StyledContent>
      </StyledContainer>
      <StyledFloatingImage>
        <Image src={ImageLeft.url} alt="Image Left" width={400} height={400} quality={100} />
      </StyledFloatingImage>

      <StyledFloatingImage>
        <Image src={ImageRight.url} alt="Image Right" width={400} height={400} quality={100} />
      </StyledFloatingImage>
    </StyledHeroWithCards>
  );
};

export { HeroWithCards };
