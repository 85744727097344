import { PriceT } from './types';

/**
 * Parses the input price ensuring it always returns a number
 *
 * @param {PriceT} price The price string or number
 * @returns {number} The parsed price
 */
// @todo Write tests for this
export const getFormattedPrice = (price: PriceT = 0, keepZeroPadding = false) => {
  const priceAsNumber = typeof price === 'number' ? price : parseFloat(price);

  if (keepZeroPadding) {
    return priceAsNumber.toFixed(2);
  }

  return priceAsNumber.toFixed(2).replace('.00', '');
};
