import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledPhoneAlternate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  p {
    font-size: 1.2rem;
    color: var(--colors-primary);
    font-weight: 500;
    text-align: left;
    font-family: var(--font-geomanist);
    margin-top: 0.1rem;
  }

  img {
    width: 1.2rem;
    padding-bottom: 12px;
    margin-right: 1rem;

    @media ${breakpoints.desktop} {
      width: 1.5rem;
      padding-bottom: 12px;
    }
  }
`;
