import { FC, useEffect, useState } from 'react';

import { InputType } from '@components/Atoms/Input/types';

import { Input } from '@components/Atoms';

import StyledCheckoutFormRow from './styled/StyledCheckoutFormRow';

export const CheckoutFormRow: FC<any> = ({
  name,
  label,
  formData,
  validators = [],
  onChange,
  className,
  type,
  tooltip,
  required,
  options,
  placeholder,
  preText,
  autoComplete,
}) => {
  const [fieldErrors, setFieldErrors] = useState(null);
  const showFieldErrors = () => {
    const errors = validators
      .filter(({ keys }) => keys.includes(name))
      .filter(({ isValid }) => !isValid)
      .map(({ message }) => message);

    if (errors && errors.length > 0) {
      setFieldErrors(errors);
    } else {
      clearFieldErrors();
    }
  };
  const clearFieldErrors = () => {
    setFieldErrors(null);
  };

  useEffect(() => {
    if (type === 'date' && formData[name]) {
      showFieldErrors();
    }
  }, [formData[name]]);

  return (
    <StyledCheckoutFormRow className={className}>
      {preText && <div className="text-left text-black pre-text">{preText}</div>}
      <Input
        fullWidth
        type={type || InputType.TEXT}
        name={name}
        label={label}
        placeholder={placeholder || label}
        value={formData[name]}
        error={fieldErrors || undefined}
        onChange={(event) => {
          onChange(name, event?.currentTarget?.value);
        }}
        onFocus={clearFieldErrors}
        onBlur={showFieldErrors}
        tooltip={tooltip}
        className={className}
        required={required}
        options={options}
        formData={formData}
        textArea={type === InputType.TEXTAREA}
        autoComplete={autoComplete}
      />
    </StyledCheckoutFormRow>
  );
};
