import React, { FC } from 'react';
import Image from 'next/image';

import { ImageDataT } from '@lib/types';
import { makeSrc } from '@lib/utils';

const root = process.env.NEXT_PUBLIC_CMS_MEDIA_ROOT_URL;

/**
 * Renders a next/image component from the given data input.
 * If no format is provided, data from the raw image will be used
 *
 * @param {string} alternativeText The alt text
 * @param {Format} format The chosen format
 * @param {FormatT[]} formats The list of available formats
 * @param {Layout} layout The chosen layout
 * @param {ImageT} image The image data
 * @returns {JSX.Element} The rendered image
 */
export const renderImage: FC<ImageDataT> = ({
  className,
  alternativeText,
  format,
  formats,
  layout,
  image,
}): JSX.Element => {
  const {
    width,
    height,
    url = null,
  } = (format && formats?.[format]) || (formats && Object.values(formats)[0]) || image || {};

  if (!url) return <></>;

  const src = makeSrc({ root, url });

  const isFill = layout === 'fill';

  return (
    <div className={className}>
      <Image
        src={src}
        alt={alternativeText}
        width={!isFill && width}
        height={!isFill && height}
        layout={layout}
        quality={100}
        blurDataURL={src}
      />
    </div>
  );
};
