import styled from 'styled-components';

import ArrowDownIcon from '@public/icons/Arrow-Down-blue.svg';

export const StyledDropdown = styled.div<any>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  background: var(--color-lightest-blue) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 1px 6px #ada9fb83;
  border-radius: 8px;
  opacity: 1;
  overflow: hidden;

  :hover {
    cursor: pointer;
  }

  span {
    font-family: var(--font-geomanist);
    font-size: 10px;
    opacity: 40%;
    position: absolute;
    top: 0.5rem;
    left: 0;
    padding-left: 0.875rem;
    display: none;
    &.drop-down--selected {
      display: block;
    }
  }

  button {
    background-size: 1.75rem;
    padding: 0.7rem 0.875rem;
    width: 100%;
    text-align: left;
    color: black;
    font-family: var(--font-geomanist);
    font-size: 19px;
    height: 53px;

    @media (min-width: 350px) {
      background: url('${ArrowDownIcon.src}') center right 1rem no-repeat;
    }

    &.drop-down--selected {
      font-family: Roboto;
      font-size: 19px;
      padding-top: 1.25rem;
    }
  }
`;
