import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledRightSide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 2rem;

  @media (${breakpoints.tablet}) {
    align-items: flex-start;
  }

  @media (${breakpoints.desktop}) {
    width: 60%;
    align-items: flex-start;
    padding-top: 0;
    padding-bottom: 0;
  }
`;
