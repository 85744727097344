import { FC, useEffect, useState } from 'react';
import StyledCountdownTimer from './styled/StyledCountdownTimer';
import StyledCountdownTimerContainer from './styled/StyledCountdownTimerContainer';
import StyledTimeLeft from './styled/StyledTimeLeft';
import StyledUnits from './styled/StyledUnits';
import StyledUnit from './styled/StyledUnit';
import { mapFieldColorToVar } from '@lib/utils/mapFieldColorToVar';

export interface Props {
  time: string;
  text?: string;
  onTimerEnd?: () => void;
  bg?: string;
}

function zeroPad(n: number) {
  return n < 10 ? '0' + n : '' + n;
}

const defaultTimerStart: [string, string, string, string] = ['00', '00', '00', '00'];

export const CountdownTimer: FC<Props> = ({ time, onTimerEnd, text, bg }) => {
  const [timeLeft, setTimeLeft] = useState(defaultTimerStart);

  useEffect(() => {
    const targetDate = new Date(time);

    const interval = setInterval(() => {
      const now = new Date();
      const distance = targetDate.getTime() - now.getTime();

      const duration = [
        Math.floor(distance / (1000 * 60 * 60 * 24)) + '',
        Math.floor((distance / (1000 * 60 * 60)) % 24) + '',
        Math.floor((distance / (1000 * 60)) % 60) + '',
        Math.floor((distance / 1000) % 60) + '',
      ];

      if (distance < 0) {
        if (onTimerEnd) {
          onTimerEnd();
        }
        clearInterval(interval);
        setTimeLeft(defaultTimerStart);
      } else {
        setTimeLeft(duration as [string, string, string, string]);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const hasTimeLeft = timeLeft.some((time) => +time > 0);

  return (
    <>
      {text && <p className="text-white font-bold text-[2.5rem]">{text}</p>}
      <StyledCountdownTimer
        hasTimeLeft={hasTimeLeft}
        bg={bg ? mapFieldColorToVar(bg) : 'var(--color-danger)'}
      >
        <StyledCountdownTimerContainer>
          <StyledUnit bg="transparent">
            <StyledTimeLeft>{`${timeLeft[0]}`}</StyledTimeLeft>
            <StyledUnits>Days</StyledUnits>
          </StyledUnit>
          <StyledUnit bg="transparent">
            <StyledTimeLeft>{`${timeLeft[1]}`}</StyledTimeLeft>
            <StyledUnits>
              H<span>ou</span>rs
            </StyledUnits>
          </StyledUnit>
          <StyledUnit bg="transparent">
            <StyledTimeLeft>{`${timeLeft[2]}`}</StyledTimeLeft>
            <StyledUnits>
              Min<span>ute</span>s
            </StyledUnits>
          </StyledUnit>
          <StyledUnit bg="transparent">
            <StyledTimeLeft>{`${timeLeft[3]}`}</StyledTimeLeft>
            <StyledUnits>
              Sec<span>ond</span>s
            </StyledUnits>
          </StyledUnit>
        </StyledCountdownTimerContainer>
      </StyledCountdownTimer>
    </>
  );
};
