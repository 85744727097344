/**
 * Parses a string error message from an occurring Error object
 *
 * @param {Error} error The error that occurred
 * @returns The parsed error message
 */
export const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) {
    return error.message;
  }
  return String(error);
};
