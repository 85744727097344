import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';

export default styled.div<{ bg?: string }>`
  text-align: center;
  padding: 1.25rem 0 1rem;
  border-radius: 0.5rem;
  background: ${(props) => props.bg || `linear-gradient(135deg, #f4111b, #0402fd 110%)`};
  min-width: 25%;

  @media ${breakpoints.mobilelg} {
    padding-top: 1.5rem;
    min-width: 5rem;
  }

  @media ${breakpoints.tablet} {
    min-width: 6rem;
  }

  & > .truncate {
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  & > div {
    display: flex;
    gap: 0.25rem;
  }
`;
