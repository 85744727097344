import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

function minHeightStyle({ $minHeight = null }: { $minHeight: string | null }) {
  if ($minHeight) {
    return css`
      min-height: ${$minHeight};
    `;
  }
}

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  > div {
    text-align: center;
    animation: ${spin} 1s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    img {
      display: inline;
    }
  }
  ${minHeightStyle}
`;
