import React, { FC } from 'react';

import { fromMarkdown } from '@lib/utils';

import { ActionCardT } from '@components/Organisms/ActionCardList/types';
import { StyledActionCard } from '@components/Organisms/ActionCardList/styled';
import { DynamicIcon } from '@components/Atoms/Icon/Icon';
import { Cta } from '@components/Molecules';

export const ActionCard: FC<ActionCardT> = ({
  heading,
  body,
  icon,
  display,
  ctaDisplay,
  ctaEvent,
  ctaLink,
  ctaLabel,
  ctaButtonType,
}) => {
  if (!display) return null;

  return (
    <StyledActionCard>
      <div className="mb-3">
        <DynamicIcon icon={icon} />
      </div>
      <p className="mb-4 font-semibold">{heading}</p>
      <div className="mb-4 text-center">{fromMarkdown({ text: body, isHeading: false })}</div>
      <Cta
        display={ctaDisplay}
        link={ctaLink}
        label={ctaLabel}
        event={ctaEvent}
        buttonType={ctaButtonType}
      />
    </StyledActionCard>
  );
};
