import styled from 'styled-components';
import CloudImage from '@public/cloud.png';
import { breakpoints } from '@theme/breakpoints';

export const StyledActionCardList = styled.section`
  background-image: url('${CloudImage.src}'), linear-gradient(180deg, #ffffff 0%, #f9d8de 100%);
  background-size: 12rem auto, cover;
  background-repeat: no-repeat;
  background-position: 180% 12rem, top center;
  @media ${breakpoints.desktop} {
    @keyframes clouds {
      0% {
        background-position: -25% 12rem, top center;
      }
      100% {
        background-position: 125% 12rem, top center;
      }
    }
    animation: clouds 20s infinite;
    background-image: url('${CloudImage.src}'), linear-gradient(180deg, #ffffff 20%, #f9d8de 100%);
    background-position: 100% 12rem, top center;
    animation-timing-function: linear;
  }

  .card-list {
    &__title {
      margin-bottom: 2rem;
      text-align: center;
      @media ${breakpoints.desktop} {
        margin-bottom: 3rem;
      }
    }
    &__cards {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media ${breakpoints.desktop} {
        flex-direction: row;
      }
    }
  }
`;
