import React from 'react';

import { Cta } from '../Cta';
import { ButtonType } from '@components/Atoms/Button/types';

import { StyledCard } from './styled/StyledCard';
import { StyledContactDetails } from './styled/StyledContactDetails';
import { StyledPhone } from './styled/StyledPhone';
import { StyledContactDetailsAlternate } from './styled/StyledContactDetailsAlternate';
import { StyledPhoneAlternate } from './styled/StyledPhoneAlternate';
import { StyledEmail } from './styled/StyledEmail';

import { Icon } from '@components/Atoms';
import Image from 'next/image';

import Phone from '@public/icons/Phone.svg';
import Email from '@public/icons/Email.svg';

export const FullLengthCard = (props) => {
  let bg = props.BackgroundColor;
  switch (bg) {
    case 'White':
      bg = 'bg-white';
      break;
    case 'Blue':
      bg = 'bg-[#ECF0FD]';
  }

  return (
    <StyledCard className={bg}>
      {!props.HasContactDetails && props.LeftImage && (
        <div className="image-left">
          <Image
            src={props.LeftImage.url}
            alt={props.LeftImage.name}
            width={150}
            height={100}
            quality={100}
          />
        </div>
      )}
      <h2>{props.Title}</h2>
      <p>{props.Body}</p>

      <div>
        <StyledContactDetails>
          {props.HasSingleCTA && props.SingleCTALink && props.SingleCTAText && (
            <Cta
              display={true}
              link={props.SingleCTALink}
              label={props.SingleCTAText}
              buttonType={ButtonType.PRIMARY}
            />
          )}
        </StyledContactDetails>

        {props.HasContactDetails && props.HasRegisterButton && (
          <StyledContactDetails>
            <StyledPhone>
              <Icon icon={Phone} />
              <div>
                <h3>{props.Phone}</h3>
                <p>{props.PhoneOpeningHours}</p>
              </div>
            </StyledPhone>
            <p>Or</p>
            <Cta
              display={true}
              link={props.RegisterButtonLink}
              label={props.RegisterButtonText}
              buttonType={ButtonType.PRIMARY}
            />
          </StyledContactDetails>
        )}
        {props.HasContactDetails && !props.HasRegisterButton && (
          <StyledContactDetailsAlternate>
            <StyledPhoneAlternate>
              <Icon icon={Phone} />
              <p>{props.Phone}</p>
            </StyledPhoneAlternate>
            <StyledEmail>
              <Icon icon={Email} />
              <a href={`mailto:${props.Email}`}>{props.Email}</a>
            </StyledEmail>
          </StyledContactDetailsAlternate>
        )}
      </div>

      {!props.HasContactDetails && props.RightImage && (
        <div className="image-right">
          <Image
            className="image-right"
            src={props.RightImage.url}
            alt={props.RightImage.name}
            width={150}
            height={150}
            quality={100}
          />
        </div>
      )}
    </StyledCard>
  );
};
