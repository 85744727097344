import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { StyledCard } from './styled/StyledCard';

export const HeroCard = (props) => {
  return (
    <a href={`#${props.LinkURL}`}>
      <StyledCard>
        <div className="card-shadow"></div>
        <div className="card-background"></div>
        <div className="card-content">
          <Image src={props.CardIcon.url} alt="card icon" width={50} height={50} quality={100} />
          <h3>{props.Title}</h3>
          <p>{props.LinkText}</p>
        </div>
      </StyledCard>
    </a>
  );
};
