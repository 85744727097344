import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export default styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 6rem;

  @media ${breakpoints.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
