import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledTrustPilotBoxMiniLogo = styled.img`
  margin: 0 auto;
  width: 72px;
  height: 45px;
  @media ${breakpoints.desktop} {
    width: 103px;
    height: 65px;
  }
`;
