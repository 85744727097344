import { FC } from 'react';
import { CountdownDigit } from '@components/Atoms/CountdownDigit';
import { CountdownProps } from './types';

export const Countdown: FC<CountdownProps> = ({ children, numOfDigits }) => {
  let value = children + '';
  if (numOfDigits) value = value.padStart(numOfDigits, '0');

  const digits = value.split('');

  return (
    <>
      {digits.map((digit, index) => (
        <CountdownDigit key={`${value}_${index}_${digit}`}>{digit}</CountdownDigit>
      ))}
    </>
  );
};
