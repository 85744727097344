import styled from 'styled-components';

export default styled.div<{ showShadow: boolean }>`
  width: 100%;
  ${({ showShadow }) =>
    showShadow &&
    `
        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            inset: 5px;
            filter: blur(12px);
            transform: translate3d(0px, 1x, -1px);
            border-radius: inherit;
            pointer-events: none;
            background: transparent linear-gradient(99deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat;
        }
    `}
`;
