import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from '@theme/breakpoints';

export default styled.div`
  padding-right: ${rem(16)};
  @media ${breakpoints.mobilelgMax} {
    text-align: center;
    padding-right: 0;
    padding-bottom: ${rem(5)};
  }
  & > p {
    color: #fdef4c;
    font-size: ${rem(22)};
    line-height: 1.3;
  }
`;
