import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import CloudImage from '@public/cloud.png';

export const StyledLargeInfoCard = styled.section`
  position: relative;
  padding: 3rem 2rem;
  text-align: center;
  background-image: url('${CloudImage.src}'), linear-gradient(180deg, #b6b4fd 0%, #f9d4da 100%);
  background-size: 12rem auto, cover;
  background-repeat: no-repeat;
  background-position: 180% 12rem, top center;
  display: flex;
  justify-content: center;

  @media (${breakpoints.desktop}) {
    padding: 4rem 2rem;
  }
`;
