import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';

export const StyledTitle = styled.h1`
  font-size: 3.5rem;
  color: var(--colors-primary);
  font-family: var(--font-geomanist);
  font-weight: 500;
  text-shadow: 0.5px 0.5px 0px var(--colors-primary);

  @media (${breakpoints.tablet}) {
    font-size: 5rem;
  }
  @media (${breakpoints.desktop}) {
    padding: 0 8rem;
  }
  @media (${breakpoints.desktopLarge}) {
    max-width: 1200px;
    padding: 0;
  }
`;
