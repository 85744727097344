import React, { FC } from 'react';
import styled from 'styled-components';
import { Format, ImageDataT } from '@lib/types';
import { CtaT } from '../Cta/types';
import { rem } from 'polished';
import { Cta } from '../Cta';
import { breakpoints } from '@theme/breakpoints';
import { renderImage } from '@lib/utils';
interface HeroCentredI {
  display: boolean;
  title: string;
  subtitle?: string;
  body?: string;
  imageLeft?: ImageDataT;
  imageRight?: ImageDataT;
  cta?: CtaT;
}

const STYLED = {
  wrapper: styled.section`
    padding-top: 4rem;
    padding-bottom: 0;
  `,
  container: styled.div`
    @media ${breakpoints.desktop} {
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
    }
  `,
  image: styled.div`
    display: none;

    @media ${breakpoints.desktop} {
      display: block;

      span {
        img {
          object-fit: contain;
        }
      }
    }
  `,
  content: styled.div``,
  subcontent: styled.div`
    max-width: 580px;
    margin: 0 auto;

    @media ${breakpoints.desktop} {
      max-width: 100%;
    }
  `,
  title: styled.h1`
    position: relative;
    font-style: italic;
    text-transform: uppercase;
    text-align: center;
    background: -webkit-linear-gradient(
      135deg,
      hsla(357, 91%, 51%, 1) 0%,
      hsla(240, 100%, 50%, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: ${rem(80)};
    line-height: 1;
    font-weight: 700;

    display: flex;
    flex-direction: column;
    gap: -1rem;

    @media ${breakpoints.desktop} {
      font-size: ${rem(130)};
    }

    span.lower {
      text-transform: lowercase;
      font-size: ${rem(70)};

      @media ${breakpoints.desktop} {
        font-size: ${rem(100)};
      }
    }
  `,
  subtitle: styled.h2`
    text-align: center;
    color: var(--color-primary);
    font-weight: 700;
    font-size: ${rem(25)};
    margin: 0 auto;
    margin-bottom: 1rem;

    @media ${breakpoints.desktop} {
      font-size: ${rem(35)};
    }
  `,
  body: styled.p`
    text-align: center;
    margin-bottom: 1rem;
  `,
  cta: styled.div`
    display: flex;
    justify-content: center;
  `,
};

export const HeroCentred: FC<HeroCentredI> = ({
  display,
  title,
  subtitle,
  body,
  imageLeft,
  imageRight,
  cta,
}) => {
  if (!display) return null;

  return (
    <>
      <STYLED.wrapper className="slice">
        <div className="slice__inner">
          <STYLED.container>
            {imageLeft && imageLeft.url && (
              <STYLED.image>
                {renderImage({
                  className: '',
                  alternativeText: imageLeft.alternativeText,
                  formats: imageLeft.formats,
                  format: Format.LARGE,
                })}
              </STYLED.image>
            )}
            <STYLED.content>
              <STYLED.title
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
              <STYLED.subcontent>
                {subtitle && (
                  <STYLED.subtitle
                    dangerouslySetInnerHTML={{
                      __html: subtitle,
                    }}
                  />
                )}
                {body && (
                  <STYLED.body
                    dangerouslySetInnerHTML={{
                      __html: body,
                    }}
                  />
                )}
                {cta && (
                  <STYLED.cta>
                    <Cta
                      display={cta.display}
                      event={cta.event}
                      link={cta.link || '#'}
                      label={cta.label}
                      buttonType={cta.buttonType}
                    />
                  </STYLED.cta>
                )}
              </STYLED.subcontent>
            </STYLED.content>
            {imageRight && imageRight.url && (
              <STYLED.image>
                {renderImage({
                  className: '',
                  alternativeText: imageRight.alternativeText,
                  formats: imageRight.formats,
                  format: Format.LARGE,
                })}
              </STYLED.image>
            )}
          </STYLED.container>
        </div>
      </STYLED.wrapper>
    </>
  );
};
