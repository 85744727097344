import { createContext, FC } from 'react';
import { FooterDataI, FooterProviderT } from './types';

export const FooterContext = createContext<FooterDataI>({
  address: undefined,
  linksColumns: [],
  socials: [],
  compareLink: undefined,
  copyright: undefined,
  displayCard: false,
});

export const FooterProvider: FC<FooterProviderT> = ({ children, footerData }) => {
  const data = {
    address: footerData?.address,
    linksColumns: footerData?.linksColumns,
    socials: footerData?.socials,
    compareLink: footerData?.compareLink,
    copyright: footerData?.copyright,
    displayCard: footerData?.displayCard,
    cardTitle: footerData?.cardTitle,
    cardBody: footerData?.cardBody,
    cardButton: footerData?.cardButton,
  };

  return <FooterContext.Provider value={data}>{children}</FooterContext.Provider>;
};
