import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from '@theme/breakpoints';

export default styled.div<{ hasTimeLeft: boolean; bg?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  margin-top: 0.5rem;
  background: ${(props) => props.bg || `transparent`};
  padding: 0 1.5rem;
  max-width: 24rem;

  @media ${breakpoints.mobilelg} {
    width: fit-content;
  }

  @media ${breakpoints.tablet} {
    max-width: 100%;
  }

  @media ${breakpoints.desktopLarge} {
    margin-top: 1rem;
    justify-self: center;
    margin-bottom: 1.25rem;
  }
`;
