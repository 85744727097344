import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledCardContainer = styled.div`
  padding: 0;
  @media (${breakpoints.tablet}) {
    padding: 0 2rem;
    margin: 0 auto;
    max-width: 1200px;
  }
  @media (${breakpoints.desktop}) {
    padding: 0 4rem;
  }

  & > div:first-of-type {
    margin-bottom: 4rem;
  }
`;
