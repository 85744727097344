import styled, { css } from 'styled-components';

type PropsT = {
  $loading?: boolean;
  $noResults?: boolean;
  $hoveredRow?: number;
};

export default styled.div`
  width: 100%;
  padding: 0.5rem 0.5rem 1rem 2rem;

  @media ({breakpoints.tablet}) {
    min-width: 25rem;
  }

  li {
    > button {
      width: 100%;
      height: 100%;
      text-align: left;
      padding: 0.5rem 1rem 0.5rem 0;
      font-size: 0.875rem;
      color: var(--colors-text-primary);
      font-family: var(--font-geomanist);
      font-weight: 300;
      /* &:hover {
        background: var(--colors-primary-15);
      } */
    }
  }
`;
