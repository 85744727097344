import { createContext, FC, useEffect, useState } from 'react';

import { PromotionsDataI, PromotionsProviderT } from './types';
import { PromoT } from '@lib/types';

import usePostcodeContext from '@hooks/usePostcodeContext';

import { productController } from '@controllers/index';

export const PromotionsContext = createContext<PromotionsDataI>({
  promotions: [],
});

export const PromotionsProvider: FC<PromotionsProviderT> = ({ children, promotionsData }) => {
  const { postcodeItem } = usePostcodeContext();

  const [promotions, setPromotions] = useState<PromoT[]>(promotionsData.promotions);

  //@promo-debug
  // all active promotions are queried here
  useEffect(() => {
    productController
      .getActivePromosServer({ postcode: postcodeItem?.postcode })
      .then((promos) => setPromotions(promos as PromoT[]));
  }, [postcodeItem?.postcode]);

  return <PromotionsContext.Provider value={{ promotions }}>{children}</PromotionsContext.Provider>;
};
