import Image from 'next/image';
import { Icon } from '@components/Atoms';
import { fromMarkdown } from '@lib/utils';
import { FullLengthCard } from '@components/Molecules/FullLengthCard';
import { convertToSlug } from '@lib/utils';

import { StyledContainer } from './styled/StyledContainer';
import { StyledVideoRelay } from './styled/StyledVideoRelay';
import { StyledHeader } from './styled/StyledHeader';
import { StyledLeft } from './styled/StyledLeft';
import { StyledRight } from './styled/StyledRight';
import { StyledAppLinks } from './styled/StyledAppLinks';
import { StyledAppLink } from './styled/StyledAppLink';
import { StyledAppSiteInfo } from './styled/StyledAppSiteInfo';
import { StyledGooglePlay } from './styled/StyledGooglePlay';
import { StyledCardContainer } from './styled/StyledCardContainer';

import GooglePlay from '@public/icons/GooglePlay.svg';
import Apple from '@public/icons/Apple.svg';

const VideoRelay = ({
  Title,
  LeftText,
  RightTextFirstParagraph,
  RightTextSecondParagraph,
  Has999AppLinks,
  Cards,
  LeftIcon,
}) => {
  return (
    <StyledVideoRelay id={convertToSlug(Title)}>
      <StyledContainer>
        <div>
          <StyledLeft>
            <StyledHeader>
              <div>
                <Image src={LeftIcon.url} alt="Icon" width={100} height={100} quality={100} />
              </div>
              <h2>{Title}</h2>
            </StyledHeader>
            <div>{fromMarkdown({ text: LeftText, isHeading: false })}</div>
          </StyledLeft>

          <StyledRight>
            <div>{fromMarkdown({ text: RightTextFirstParagraph, isHeading: false })}</div>
            {Has999AppLinks && (
              <div>
                <StyledAppLinks>
                  <StyledAppLink
                    href="https://play.google.com/store/apps/details?id=com.sorenson.sli.bsl999&pli=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledGooglePlay>
                      <p>999 BSL app for Android</p>
                      <Icon icon={GooglePlay} />
                    </StyledGooglePlay>
                  </StyledAppLink>

                  <StyledAppLink
                    href="https://apps.apple.com/gb/app/999-bsl/id1609981468"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <p>999 BSL app for iOS</p>
                      <Icon icon={Apple} className={'google'} />
                    </div>
                  </StyledAppLink>
                </StyledAppLinks>
                <StyledAppSiteInfo>
                  <div>
                    {fromMarkdown({
                      text: 'Alternatively you can call through the 999 BSL website:  [www.999bsl.co.uk](www.999bsl.co.uk)',
                      isHeading: false,
                    })}
                  </div>
                </StyledAppSiteInfo>
              </div>
            )}
            <div>{fromMarkdown({ text: RightTextSecondParagraph, isHeading: false })}</div>
          </StyledRight>
        </div>
        <StyledCardContainer>
          {Cards.map((card, index) => {
            return <FullLengthCard key={index} {...card} />;
          })}
        </StyledCardContainer>
      </StyledContainer>
    </StyledVideoRelay>
  );
};

export { VideoRelay };
