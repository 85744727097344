import React, { FC } from 'react';

import { ImageT } from '@lib/types';

import { ButtonType } from '@components/Atoms/Button/types';
import { DynamicIcon } from '@components/Atoms/Icon/Icon';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  StyledButtonContainer,
  StyledCard,
  StyledCardIcon,
  StyledCardsMasonryBlock,
  StyledPackagesPromoBlock,
  StyledCta,
} from './styled';
import { CarouselNextArrow, CarouselPrevArrow } from '@components/Atoms/CarouselArrow';
import styled from 'styled-components';

type PromoBlockT = {
  id: string | number;
  icon: ImageT;
  title: string;
  display: boolean;
};

const Card: FC<any> = ({ icon, title, display = true }: PromoBlockT) => {
  if (!display) return null;

  return (
    <StyledCard>
      <StyledCardIcon className="h-1/2">
        <DynamicIcon icon={icon} />
      </StyledCardIcon>
      <h3 className="text-center slice__subtitle mb-0">{title}</h3>
    </StyledCard>
  );
};

const CardsMasonryBlock: FC<any> = ({ blocks = null }: { blocks: PromoBlockT[] | null }) => {
  if (!blocks) return null;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <StyledCardsMasonryBlock>
      <Slider {...settings}>
        {blocks?.map(({ id, ...block }) => (
          <Card key={`masonry-${id}`} {...block} />
        ))}
      </Slider>
    </StyledCardsMasonryBlock>
  );
};

const Wrapper = styled.section<{ colorTheme: string }>`
  ${({ colorTheme }) => {
    if (colorTheme === 'whiteBackground') {
      return `
      background-color: #fff;
    `;
    }

    if (colorTheme === 'purpleBackground') {
      return `
      background-color: var(--colors-primary-8);

      [class*="StyledCard-"] {
        background-color: var(--color-lightest-blue);
        padding: 0 1rem;
      }
    `;
    }
  }}
`;

export const PackagesPromoBlock: FC<any> = ({
  title = 'Unlock the power to full fibre in your home',
  ctaText = 'See all advantages',
  ctaDisplay,
  blocks,
  href = '#',
  colorTheme = 'whiteBackground',
}: {
  title: string;
  ctaText: string;
  ctaDisplay: boolean;
  blocks: PromoBlockT[] | null;
  href: string;
  colorTheme?: string;
}) => {
  return (
    <Wrapper className="slice packages-promo-block" colorTheme={colorTheme}>
      <StyledPackagesPromoBlock className="slice__inner">
        <h2 className="slice__title packages-promo-block__title">{title}</h2>
        <CardsMasonryBlock blocks={blocks} />
        <StyledButtonContainer>
          <StyledCta
            label={ctaText}
            link={href}
            display={ctaDisplay}
            buttonType={ButtonType.PRIMARY}
          />
        </StyledButtonContainer>
      </StyledPackagesPromoBlock>
    </Wrapper>
  );
};
