import styled, { css } from 'styled-components';

interface ProgressLabelProps {
  $filled?: boolean;
  $active?: boolean;
}

function filledOrActiveStyle({ $filled, $active }: ProgressLabelProps) {
  if ($active) {
    return css`
      opacity: 100%;
    `;
  }
}

export default styled.span`
  display: flex;
  align-items: center;
  margin-top: 1.25rem;

  p {
    font-size: 0.875rem;
    font-family: Geomanist, Segoe, 'Segoe UI', 'DejaVu Sans', 'Trebuchet MS', Verdana, 'sans-serif';
    opacity: 30%;
    ${filledOrActiveStyle};
    overflow-wrap: break-word;
    word-break: break-word;
    text-align: center;
  }

  span {
    margin-left: 0.2rem !important;
  }

  .progress__tick {
    opacity: 100%;
  }
`;
