import styled from 'styled-components';
import { rem } from 'polished';
import ArrowRightBlue from '@public/icons/Arrow-Right-Blue.svg';

export const StyledLinkList = styled.div`
  width: 100%;
  padding-bottom: ${rem(30)};
`;

export const StyledLinkItems = styled.div`
  > a {
    display: block;
    width: 100%;
    border-bottom: 2px solid var(--colors-primary-15);
    padding: ${rem(16)} ${rem(25)} ${rem(16)} 0;
    position: relative;
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
      top: 18px;
      transform: translateX(-10%);
      background: url('${ArrowRightBlue.src}') no-repeat;
      background-size: cover;
      transform-origin: center;
    }
    &:last-of-type {
      border-bottom: none;
    }
    &:hover {
      text-decoration: underline;
    }
  }
`;
