import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from '@theme/breakpoints';

export const StyledContactCards = styled.div``;

export const StyledContactCard = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${rem(32)};

  @media ${breakpoints.tablet} {
    margin-bottom: 0;
  }
  .contact-card {
    &__image {
      width: 56px;
      height: 56px;
    }
    &__content {
      margin-left: ${rem(15)};
      max-width: 200px;
    }
    &__cta {
      color: var(--colors-primary);
      text-decoration: underline;
      font-weight: 700;
      font-size: ${rem(18)};
    }
    &__body {
      font-size: ${rem(14)};
    }
  }
`;
