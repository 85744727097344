import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';

export default styled.div`
  display: flex;
  align-items: flex-start;
  @media ${breakpoints.mobilelgMax} {
    padding-bottom: ${rem(5)};
  }
`;
