import styled from 'styled-components';

export default styled.div`
  position: absolute;
  top: -50px;
  right: 20px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background: var(--colors-gradient-tertiary-2);
  padding: 0.4rem 0.8rem;
  color: white;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: var(--fonts-family-secondary);
  transform: rotate(10deg);
  background: transparent linear-gradient(300deg, #f4111b 0%, #0402fd 100%) 0 0 no-repeat
    padding-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;
`;
