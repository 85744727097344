/* eslint-disable react/display-name */
import React, { FC, useCallback, useState } from 'react';
import { FakeInput as InputWrapper } from '../Hero/variants/Main/styled';
import { useRouter } from 'next/router';
import usePostcodeContext from '@hooks/usePostcodeContext';
import { Button } from '@components/Atoms';
import { ButtonType } from '@components/Atoms/Button/types';

export const PostcodeSearch = (props) => {
  const [currentPostcode, setCurrentPostcode] = useState<string>('');
  const router = useRouter();

  const { setPostcodeString } = usePostcodeContext();

  const { onSubmit } = props;

  const handleSubmit = onSubmit
    ? () => onSubmit(currentPostcode)
    : (e) => {
        e.preventDefault();

        setPostcodeString(currentPostcode);
        return router.push('/address-finder');
      };

  const { rootElement, className, ...attributes } = props;
  const RootElement = rootElement || 'form';

  return (
    <RootElement onSubmit={handleSubmit} className={className || ''}>
      <InputWrapper isStandalone={props.isStandalone}>
        <input
          type="text"
          placeholder={props.placeholder || 'Enter your postcode'}
          onChange={(e) => setCurrentPostcode(e.target.value)}
          value={currentPostcode}
          {...attributes}
        />
        <Button buttonType={ButtonType.ALTERNATE} type="submit">
          Search
        </Button>
      </InputWrapper>
    </RootElement>
  );
};
