import styled, { css } from 'styled-components';

function isAddonStyle({ $isAddon = false }) {
  if ($isAddon) {
    return css`
      margin-bottom: 0;
      border-bottom: 0;
    `;
  }
}

export default styled.div<any>`
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid var(--colors-primary-15);
  flex: 1;
  p {
    margin: 0;
    line-height: 1.6;
    color: var(--colors-grey-50);
    font-weight: 300;
    font-size: 1rem;
    letter-spacing: 0.02em;
    margin-right: 1rem;
  }
  ${isAddonStyle}
`;
