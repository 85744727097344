import React, { FC } from 'react';
import { Icon } from '@components/Atoms/Icon';
import { ProgressBarT, ProgressBarStepT } from './types';
import useBasketContext from '@hooks/useBasketContext';

import StyledProgressBar from './styled/StyledProgressBar';
import StyledProgressBarStep from './styled/StyledProgressBarStep';
import StyledProgressDot from './styled/StyledProgressDot';
import StyledProgressLabel from './styled/StyledProgressLabel';
import Image from 'next/image';

// @todo This needs to have an "inverted" mode, or dark/light, for different headings.

const ProgressBarStep: FC<any> = ({
  step,
  filled = false,
  active = false,
}: {
  step: ProgressBarStepT;
  filled: boolean;
  active: boolean;
}) => {
  const { getBasketItemProduct } = useBasketContext();
  const bikeImage = getBasketItemProduct()?.category ?? 'SUPER';
  return (
    <StyledProgressBarStep className={active ? 'active' : ''}>
      {active ? (
        <div className="progress__image">
          <Image src={`/Benny_${bikeImage}BIKE.png`} width="45" height="47" alt="Benny on a bike" />
        </div>
      ) : (
        ''
      )}
      <StyledProgressLabel $filled={filled} $active={active} className={filled ? 'completed' : ''}>
        <p>{step.name}</p>
        {filled ? (
          <Image
            className="progress__tick"
            src="/HB_tick_blue.svg"
            width="15"
            height="15"
            alt="progress bar tick"
          />
        ) : (
          ''
        )}
      </StyledProgressLabel>
    </StyledProgressBarStep>
  );
};

export const ProgressBar: FC<any> = ({ steps }: ProgressBarT) => {
  const stepValue = 100 / steps.length;
  const activeStepIndex = steps.findIndex((step) => step.active);
  const currentStepValue = stepValue * (activeStepIndex + 1);
  return (
    <StyledProgressBar $steps={steps.length} $progressvalue={currentStepValue}>
      {steps?.map((step, index) => (
        <ProgressBarStep
          key={step.name}
          step={step}
          filled={index < activeStepIndex}
          active={step.active}
        />
      ))}
    </StyledProgressBar>
  );
};
