import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledColumns = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;

  @media (${breakpoints.desktop}) {
    flex-direction: row;
    gap: 2rem;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;

    * {
      font-family: var(Roboto);
    }

    p {
      color: var(--colors-grey-50);
      margin-bottom: 1rem;
      font-family: var(Roboto);
      @media (${breakpoints.tablet}) {
        text-align: left;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 1rem;
      color: var(--colors-primary);
      font-family: var(--font-geomanist);
      font-weight: 500;
      @media (${breakpoints.tablet}) {
        text-align: left;
      }
    }

    h2 {
      @media (${breakpoints.desktop}) {
        font-size: 2rem;
      }
    }

    h3 {
      @media (${breakpoints.desktop}) {
        font-size: 1.5rem;
      }
    }

    h4,
    h5,
    h6 {
      @media (${breakpoints.desktop}) {
        font-size: 1rem;
      }
    }

    strong {
      color: black;
      font-family: var(Roboto);
    }

    ul {
      display: flex;
      text-align: left;
      flex-direction: column;
      color: var(--colors-grey-50);
    }

    li:before {
      content: '•';
      margin-right: 0.5rem;
      font-family: var(Roboto);
    }
  }
`;
