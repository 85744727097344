import React from 'react';
import { Icon } from '../';

import SpinnerIcon from '@public/icons/Spinner.svg';

import StyledSpinner from './styled/StyledSpinner';

export const Spinner = ({ minHeight = null }: { minHeight?: string | null }) => {
  return (
    <StyledSpinner $minHeight={minHeight}>
      <div>
        <Icon lg icon={SpinnerIcon} />
      </div>
    </StyledSpinner>
  );
};
