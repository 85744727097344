/* eslint-disable react/display-name */
import React, { FC, forwardRef } from 'react';

import { StyledDropdownItem } from './styled/StyledDropdownItem';

export const DropdownItem: FC<any> = forwardRef(({ item, className, ...rest }, ref) => {
  return (
    <li {...rest} ref={ref} className={className}>
      <StyledDropdownItem>{item.label}</StyledDropdownItem>
    </li>
  );
});
