import styled from 'styled-components';
import { rem } from 'polished';

export default styled.div`
  color: #fdef4c;
  text-align: center;
  font-size: ${rem(15)};
  width: 100%;
  padding-top: ${rem(1)};
`;
