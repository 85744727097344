import { AddressType, SlaT } from './types';

/**
 * Takes in a selected sla and returns the mapped address type
 *
 * @param {SlaT} sla The selected sla
 * @returns {AddressType} The address type
 */
export const getAddressTypeFromSla = (sla: SlaT): AddressType => {
  switch (sla) {
    case '1':
      return AddressType.BUS;
    case '2':
      return AddressType.RES;
    case '3':
      return AddressType.HWRK;
    default:
      return AddressType.RES;
  }
};
