import styled, { css } from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';
import { StyledStickyBannerProps } from '../types';

export const StyledStickyBanner = styled.div<StyledStickyBannerProps>`
  position: fixed;
  bottom: -20%;
  left: 0;
  z-index: 15;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colors-primary-8);
  font-family: var(--fonts-family-primary);
  color: var(--colors-grey-100);
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.3;
  width: 100%;
  transition: all 0.3s ease-out;
  cursor: pointer;
  span {
    width: 60%;
    text-align: center;
    padding: ${rem(18)} ${rem(18)} ${rem(14)};
  }

  @media ${breakpoints.desktop} {
    border-radius: 0.7rem;
    border: 1px solid var(--colors-primary);
    width: 375px;
    left: 50%;
    transform: translateX(-50%);
  }

  ${({ $display }: StyledStickyBannerProps) => {
    if ($display) {
      return css`
        bottom: 0;
        @media ${breakpoints.desktop} {
          bottom: ${rem(16)};
        }
      `;
    }
  }}
`;

export const StickyBannerFakeButton = styled.div`
  background-color: var(--colors-primary);
  color: var(--colors-white);
  padding: ${rem(18)} ${rem(18)} ${rem(14)};
  font-family: var(--fonts-family-secondary);
  width: 40%;
  text-align: center;
`;
