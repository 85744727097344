import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;

  h2 {
    color: var(--colors-primary);
    font-family: var(--font-geomanist);
    font-weight: 500;
    margin-top: 1rem;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }

  @media (${breakpoints.tablet}) {
    font-size: 3rem;
    text-align: left;
  }
`;
