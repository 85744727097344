import styled from 'styled-components';

export const HeaderDropDown = styled.div`
  position: absolute;
  top: 155%;
  left: 132%;
  transform: translate(-50%, 0);
  width: 200px;
  color: var(--colors-text-primary);
  border-radius: 0.625rem;
  margin-top: 0.625rem;
  font-family: var(--fonts-family-secondary);
  transform-style: preserve-3d;
  &:before {
    content: '';
    position: absolute;
    inset: 20px;
    filter: blur(20px);
    transform: translate3d(0px, 15px, -1px);
    border-radius: inherit;
    pointer-events: none;
    background: transparent linear-gradient(20deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
      padding-box;
  }

  > div {
    background-color: #f7f7ff;
    position: relative;
    border-radius: 0.625rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  a {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    transition: all 300ms ease;
  }
`;
