import styled, { css } from 'styled-components';

import { StyledInputProps } from '../types';

function fullWidthStyle({ fullWidth = false }: StyledInputProps) {
  if (fullWidth) {
    return css`
      input:not(.booleanInput) {
        width: 100%;
        &[type='date'] {
          -webkit-min-logical-width: calc(100% - 16px);
        }
      }
    `;
  }
}

function labelOpacityStyle({ isActive = false, isFilled = false }: StyledInputProps) {
  if (!isActive && !isFilled) {
    return css`
      label:not(.checkbox-label):not(.radio-row) {
        opacity: 0;
      }
    `;
  }

  if (isActive) {
    return css`
      label:not(.checkbox-label):not(.radio-row) {
        opacity: 100%;
        text-align: left;
      }
    `;
  }

  if (isFilled) {
    return css`
      label:not(.checkbox-label):not(.radio-row) {
        opacity: 40%;
      }
    `;
  }
}

function borderColorStyle({ error = false }: StyledInputProps) {
  if (error) {
    return css`
      input {
        border-color: var(--colors-error);
      }
    `;
  }
}

const GlobalInputStyle = css<{ isIOSSafari: boolean }>`
  & {
    border-radius: 0.5rem;
    padding: 0.7rem 0.875rem;
    background: var(--color-lightest-blue) 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 1px 6px #ada9fb83;
    border-radius: 8px;
    opacity: 1;
    font-size: 19px;
    height: 53px;
    text-align: left;
    width: 100%;
  }
  ${({ isIOSSafari }) =>
    isIOSSafari &&
    `
  &[type="date"]::-webkit-date-and-time-value {
    border-radius: 0.5rem;
    padding: 0.7rem 0.875rem;
    background: var(--color-lightest-blue) 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 1px 6px #ADA9FB83;
    border-radius: 8px;
    opacity: 1;
    font-size: 19px;
    height: 53px;
    text-align: left;
    font-weight: bold;
    min-width: calc(100% - 16px);
  }
    
  &[type="date"] {
    box-shadow: none;
    background: transparent;
    width: 100%;
    padding-bottom: 0;
    padding-left: 0;
  }
  `}

  ::placeholder {
    color: black;
    font-family: var(--font-geomanist);
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: black;
    font-family: var(--font-geomanist);
  }

  &:active,
  &:focus,
  &:focus-visible,
  &:not(:placeholder-shown) {
    padding-top: 1.85rem;
    outline: none;
  }
`;

export const StyledInput = styled.fieldset<any>`
  &:not(.booleanInput) {
    display: block;
    position: relative;
    /* margin-top: 1rem; */
    label:not(.checkbox-label):not(.radio-row) {
      position: absolute;
      top: 0.5rem;
      left: 0;
      background-color: transparent;
      padding-left: 0.875rem;
      font-size: 0.75rem;
      transition: all 0.1s;
      font-family: var(--font-geomanist);
      font-size: 10px;

      &:first-of-type {
        width: 50%;
      }
    }
    ${({ isIOSSafari }) =>
      isIOSSafari &&
      `

      input[type="date"] + label {
        opacity: 1;
        font-size: 19px;
        text-align: left;
        font-weight: 500;
        top: 1rem;
        color: black;
      }
      input[value*="2"] + label,
      input[autofilled] + label {
        opacity: 0;
      }
    `}
    input {
      ${GlobalInputStyle}

      &.Title {
        width: 50%;
      }
    }

    textarea {
      ${GlobalInputStyle}
      width: 100%;
      height: 330px;
      resize: none;
    }
  }

  ${fullWidthStyle}
  ${labelOpacityStyle}
  ${borderColorStyle}

  input[type="date"] + label:not(.checkbox-label):not(.radio-row) {
    opacity: 1;
  }

  &.booleanInput {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.9rem;

    legend {
      flex-basis: 100%;
      margin-bottom: 1.5rem;
      font-size: 1.063rem;
      font-family: var(--font-geomanist);

      @brealpoints.tablet {
        font-size: 1.25rem;
      }
    }

    .radio-row {
      cursor: pointer;
      position: relative;
      box-shadow: none;
    }

    .radio-row:hover,
    .radio-row--selected {
      background-color: var(--color-selected-off-white);
    }

    & .radio-row:before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      inset: 2px;
      -webkit-filter: blur(10px);
      filter: blur(10px);
      -webkit-transform: translate3d(0px, 12px, -1px);
      -ms-transform: translate3d(0px, 12px, -1px);
      transform: translate3d(0px, 12px, -1px);
      border-radius: inherit;
      pointer-events: none;
      background: transparent linear-gradient(99deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
        padding-box;
      width: 95%;
      height: 66%;
      background: transparent linear-gradient(99deg, #0402fd 0%, #f4111b 100%) 0% 0% no-repeat;
      transition: all 0.2s;
      opacity: 0.4;
    }

    & .radio-row:hover:before,
    & .radio-row--selected:before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      -webkit-filter: blur(14px);
      filter: blur(14px);
      -webkit-transform: translate3d(0px, 12px, -1px);
      -ms-transform: translate3d(0px, 12px, -1px);
      transform: translate3d(0px, 12px, -1px);
      border-radius: inherit;
      pointer-events: none;
      background: transparent linear-gradient(99deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
        padding-box;
      width: 95%;
      height: 66%;
      background: transparent linear-gradient(99deg, #0402fd 0%, #f4111b 100%) 0% 0% no-repeat;
      transition: all 0.2s;
      opacity: 0.6;
    }
  }

  .checkbox-group {
    margin: 1.5rem 0;
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    &__legend {
      font-weight: bold;
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .radio-row {
      width: unset;
      input {
        padding: 0;
      }
    }

    input {
      display: none;

      & + span::before {
        display: inline-block;
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 0.25rem;
        margin-right: 0.5rem;
        vertical-align: middle;
        cursor: pointer;
        background-color: var(--color-lightest-blue);
      }
      &:checked + span::before {
        background-image: url('/icons/Checkbox-Gradient.png');
        background-size: cover;
      }
    }
  }

  .react-datepicker__header {
    .react-datepicker__current-month {
      display: none;
    }
  }
`;
