import styled from 'styled-components';
import { Cta } from '@components/Molecules';
import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';

export const StyledIconCards = styled.section`
  background-color: #e5e5ff;
  .card {
    &__list {
      display: flex;
      flex-direction: column;
      > li {
        &:not(:last-of-type) {
          margin-bottom: ${rem(40)};
        }
      }

      @media ${breakpoints.tablet} {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-evenly;

        > li {
          width: 50%;
          &:nth-of-type(odd) {
            padding-right: ${rem(20)};
          }
        }
      }
      @media ${breakpoints.desktopLarge} {
        flex-wrap: nowrap;

        > li {
          /* width: 25%; */
          &:nth-of-type(odd) {
            padding-right: 0;
          }
          &:not(:last-of-type) {
            margin-bottom: 0;
            margin-right: ${rem(80)};
          }
        }
      }

      &--multi-row {
        @media ${breakpoints.desktopLarge} {
          flex-wrap: wrap;

          > li {
            width: 25%;
            margin-right: ${rem(80)};
            &:not(:last-of-type) {
              margin-bottom: ${rem(80)};
            }
          }
        }
      }
    }
    &__buttons {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 0;
      @media ${breakpoints.tablet} {
        justify-content: center;
        > :first-child {
          margin-right: ${rem(20)};
        }
      }
      @media ${breakpoints.mobileMax} {
        display: block;
        button,
        a {
          width: 90%;
          margin: auto;
        }
        > :first-child {
          margin-bottom: ${rem(20)};
        }
      }
    }
  }
`;

export const StyledCTA = styled(Cta)`
  @media ${breakpoints.tablet} {
    min-width: 200px;
  }
`;
