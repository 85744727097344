import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';
import CloudImage from '@public/cloud.png';

export const StyledHeroWithCards = styled.section`
  position: relative;
  padding: 3rem 2rem;
  text-align: center;
  overflow: hidden;
  & > div {
    max-width: 1200px;
    margin: 0 auto;
  }

  @media (${breakpoints.desktop}) {
    padding: 6rem 2rem;
  }
`;
