import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledCardsMasonryBlock = styled.div`
  @media ${breakpoints.mobileMax} {
    max-width: 80%;
    margin: auto;
  }
  .slick-list {
    @media ${breakpoints.mobilelg} {
      margin: 0 2rem;
    }
    @media ${breakpoints.desktop} {
      margin: 0 5rem;
    }
  }
`;
