import styled from 'styled-components';
import { rem } from 'polished';

export default styled.span`
  border-radius: 2px;
  padding: ${rem(4)} ${rem(8)};
  background-color: #fdef4c;
  color: var(--colors-grey-100);
  margin: 0 ${rem(2)};
  font-size: ${rem(25)};
  font-weight: bold;
`;
