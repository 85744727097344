import styled from 'styled-components';

export default styled.div<{ run: boolean; speed: number }>`
  position: absolute;
  left: 2.5rem;
  top: -115px;
  transform: ${({ run }) => (run ? 'translateX(150%)' : 'translateX(0)')};
  opacity: ${({ run }) => (run ? '0' : '1')};
  transition: all ${({ run, speed }) => (run ? speed : '0')}s ease-out;
  width: 150px;
  height: 150px;
  z-index: 2;
`;
