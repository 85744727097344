import styled from 'styled-components';
import { rem } from 'polished';

export default styled.div`
  padding-left: ${rem(16)};
  & .cta-button {
    background-color: #fdef4c;
    color: var(--colors-grey-100);
  }
`;
