import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledActionCard = styled.div`
  padding: 2.5rem 1.5rem;
  background: var(--colors-white);
  box-shadow: 0 3px 16px #16285029;
  border-radius: 7.5px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media ${breakpoints.desktop} {
    max-width: 24rem;
    margin: 0 1rem;
  }

  button {
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 40px;
  }
`;
