import styled from 'styled-components';

import { breakpoints } from '@theme/breakpoints';

import TopImageBigSmall from '@public/box-top-benny-sm.png';
import TopImageBigLarge from '@public/box-top-benny-lg.png';
import BottomImageSmall from '@public/box-bottom-benny.png';
import BottomImageLarge from '@public/box-bottom-benny.png';

export const StyledTrustPilotBoxContainer = styled.div`
  ::before {
    content: '';
    position: absolute;
    left: 0.75rem;
    right: 0.75rem;
    top: -76px;
    height: 100px;
    background-image: url('${TopImageBigSmall.src}');
    background-repeat: no-repeat;
    background-position: top center;
    @media ${breakpoints.desktop} {
      top: -151px;
      height: 193px;
      background-image: url('${TopImageBigLarge.src}');
    }
  }
  ::after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    height: 50px;
    background-image: url('${BottomImageSmall.src}');
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    @media ${breakpoints.desktop} {
      background-image: url('${BottomImageLarge.src}');
      bottom: -175px;
      height: 175px;
    }
  }
`;
