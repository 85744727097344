import { FC, useCallback, useEffect, useState } from 'react';
import Router from 'next/router';

import { logger } from '@lib/logger';
import { fromMarkdown, createUuid } from '@lib/utils';

import { productController } from '@controllers/index';

import usePostcodeContext from '@hooks/usePostcodeContext';
import usePersistState from '@hooks/usePersistState';

import { ContactDetailsForm } from '@components/Organisms/OrderForms/ContactDetailsForm';

import { ActionArgsT, OrderT } from '@lib/types';
import { RegisterInterestFormT } from './types';
import useCheckoutContext from '@hooks/useCheckoutContext';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import recaptchaCallback from '@lib/utils/recaptchaCallback';

const FormWrapper = ({
  hasExtraFields,
  heading,
  smallCopy,
  formData,
  setFormData,
  setFormStep,
}) => {
  const [hasRecaptchaErrored, setHasRecaptchaErrored] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <ContactDetailsForm
      hasExtraFields={hasExtraFields}
      heading={fromMarkdown({ text: heading })}
      smallCopy={smallCopy}
      action="ROI"
      defaultFormData={formData}
      recaptchaErrored={hasRecaptchaErrored}
      onSubmit={(contactDetails) => {
        try {
          if (!executeRecaptcha) {
            setHasRecaptchaErrored(true);
            console.error('Execute recaptcha not yet available');
            return;
          }

          executeRecaptcha('checkoutFormSubmit').then((gReCaptchaToken) =>
            recaptchaCallback({
              token: gReCaptchaToken,
              onError: () => setHasRecaptchaErrored(true),
              onSuccess: () => {
                setFormStep(2);
                setFormData((prev: any) => {
                  return {
                    ...prev,
                    ...contactDetails,
                  };
                });
              },
            }),
          );
        } catch (error) {
          console.error('Recaptcha not verified');
          setHasRecaptchaErrored(true);
        }
      }}
    />
  );
};

export const RegisterInterestForm: FC<RegisterInterestFormT> = ({
  hasExtraFields = false,
  heading,
  smallCopy,
}) => {
  const { customer, setOrder } = useCheckoutContext();
  const [formStep, setFormStep] = useState<number>(0);
  const { postcodeItem } = usePostcodeContext();
  const [formData, setFormData] = useState<ActionArgsT | any>({});
  const { customerRegisterInterestServer } = productController;

  const submitForm = useCallback(async () => {
    if (!formData) {
      setFormStep(0);
      return null;
    }
    let orderNumber = createUuid();
    try {
      // Start loading spinner...
      setFormStep(3);

      const args = {
        ...customer,
        ...postcodeItem,
        contact_title: formData.contact_title?.value,
        voucher: hasExtraFields, // States that this is an "extra fields" page.
        pending_direct_debit: false,
        marketing: formData?.accept_marketing ? 'YES' : 'NO',
        gdpr: true,
        origin: customer.origin?.value,
      } as any;

      // Fake delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Register interest...
      const { code } = await customerRegisterInterestServer({ args });
      orderNumber = code;

      // Save the latest order to the persisted state, to pass to another page.
      setOrder({
        orderNumber,
        email: formData.email,
        customerCode: orderNumber,
        action: 'ROI',
      });

      // Setting done...
      setFormStep(4);

      // Redirecting...
      Router.push(`/confirmation-of-interest/${orderNumber}`);
    } catch (error) {
      setFormStep(5);
      logger.error(error, 'pages.service_unavailable.error');
      Router.push(`/checkout/order/error/${orderNumber}`);
    }
  }, [formData, postcodeItem, customerRegisterInterestServer, hasExtraFields, customer]);

  useEffect(() => {
    if (formStep === 2) {
      submitForm();
    }
  }, [formStep, submitForm]);

  useEffect(() => {
    if (postcodeItem) {
      setFormData({
        ...postcodeItem,
      });
    }
  }, [postcodeItem]);

  return (
    <div id="register-interest" className="register-interest container mx-auto my-14">
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.RECAPTCHA_SITE_KEY || '6LeEfhwoAAAAAGFBvsuHhiQtZLX-Br6hBC4Vq8FZ'}
        scriptProps={{
          async: false,
          defer: false,
          appendTo: 'head',
          nonce: undefined,
        }}
        container={{
          element: 'recaptcha-container',
          parameters: {
            callback: () => {
              const container = document.getElementById('recaptcha-container');

              container?.classList.add('mt-8');
            },
          },
        }}
      >
        <FormWrapper
          smallCopy={smallCopy}
          hasExtraFields={hasExtraFields}
          setFormStep={setFormStep}
          setFormData={setFormData}
          heading={heading}
          formData={formData}
        />
      </GoogleReCaptchaProvider>
    </div>
  );
};
