import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

const { desktop } = breakpoints;

export const StyledPackagesPromoBlock = styled.div`
  display: block;
  position: static;

  .packages-promo-block {
    &__title {
      text-align: center;
      margin-bottom: 2rem;
    }
  }
`;
