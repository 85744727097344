import React, { FC, useRef } from 'react';

import { fromMarkdown, renderParagraphs } from '@lib/utils';

import {
  StyledAccordionButton,
  StyledAccordionContent,
  StyledAccordionItem,
  StyledAccordionWrapper,
} from './styled';

import { Props } from './types';
import { Cta } from '@components/Molecules';

export const AccordionItem: FC<Props> = ({
  heading,
  body,
  toggle,
  open,
  ctaDisplay,
  ctaLabel,
  ctaButtonType,
  ctaLink,
  ctaEvent,
}) => {
  const content = useRef<HTMLDivElement>(null);

  return (
    <StyledAccordionWrapper className="accordion__item">
      <StyledAccordionItem key={heading}>
        <StyledAccordionButton
          className={`${open ? 'open' : ''}`}
          onClick={() => {
            toggle(heading);
          }}
        >
          {fromMarkdown({ text: heading })}
        </StyledAccordionButton>
        <StyledAccordionContent
          ref={content}
          style={{ height: `${!open ? '0px' : `${content?.current?.scrollHeight}px`}` }}
        >
          <div className="accordion-item__content">
            {renderParagraphs({ body, hasMarkdown: true })}
            {ctaDisplay && ctaLabel && (
              <Cta
                display={ctaDisplay}
                event={ctaEvent}
                link={ctaLink || '#'}
                label={ctaLabel}
                buttonType={ctaButtonType}
              />
            )}
          </div>
        </StyledAccordionContent>
      </StyledAccordionItem>
    </StyledAccordionWrapper>
  );
};
