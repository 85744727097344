// Taken from the hero. Should probably be modified.
import { List } from '@components/Atoms/List';
import { rem } from 'polished';
import styled from 'styled-components';

export default styled(List)`
  display: block;
  column-count: 2;
  margin-top: 2rem;
  max-width: 32rem;
  li {
    display: flex;
    align-items: center;
    font-size: var(--fonts-sizing-small);
    color: var(--colors-grey-50);
    &:first-of-type {
      margin-top: 0;
    }
    &:not(:last-of-type) {
      margin-bottom: ${rem(9)};
    }
    img {
      margin-right: ${rem(6)};
    }
  }
`;
