import React, { FC } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import HBLogoBlue from '@public/hb-logo-blue.svg';
import HBMobile from '@public/HB_mobile_blue.png';
import HBMobileInverted from '@public/HB_mobile_inverted.png';
import { StyledLogo, StyledMobileLogo } from './styled';

// @todo Color could probably be an enum
export const Logo: FC<any> = ({
  readOnly = false,
  inverted = false,
}: {
  readOnly?: boolean;
  inverted?: boolean;
}) => {
  return (
    <>
      {readOnly ? (
        <>
          <StyledLogo>
            <Image src={HBLogoBlue} alt="Hey! Broadband logo" layout="fill" objectFit="contain" />
          </StyledLogo>
          <StyledMobileLogo>
            <Image
              src={inverted ? HBMobileInverted : HBMobile}
              alt="Hey! Broadband logo"
              layout="fill"
              objectFit="contain"
            />
          </StyledMobileLogo>
        </>
      ) : (
        <>
          <Link passHref href="/">
            <StyledMobileLogo as={'a'} href="/">
              <Image
                src={inverted ? HBMobileInverted : HBMobile}
                alt="Hey! Broadband logo"
                layout="fill"
                objectFit="contain"
              />
            </StyledMobileLogo>
          </Link>
          <Link passHref href="/">
            <StyledLogo as={'a'} href="/">
              <Image src={HBLogoBlue} alt="Hey! Broadband logo" layout="fill" objectFit="contain" />
            </StyledLogo>
          </Link>
        </>
      )}
    </>
  );
};
