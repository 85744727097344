import styled from 'styled-components';
import { StyledSwitchLabelProps } from '@components/Atoms/Switch/types';

export const StyledSwitchLabel = styled.span<StyledSwitchLabelProps>`
  color: ${({ checked }) => (checked ? 'var(--colors-black)' : 'var(--colors-grey-50)')};
  font-weight: ${({ checked }) => (checked ? 'bold' : 'normal')};
  font-size: 0.813rem;
  margin-left: 0.875rem;

  &:hover {
    cursor: pointer;
    color: var(--colors-black);
  }
`;
