import { createContext, FC } from 'react';
import { PromoDataI, PromoProviderT } from './types';

import { Theme } from '@lib/types';

export const PromoContext = createContext<any>({
  CTA: {},
  theme: Theme.DARK,
  displayOnPage: true,
});

export const PromoProvider: FC<PromoProviderT> = ({ children, promoData }) => {
  const data: PromoDataI = {
    CTA: promoData?.CTA,
    theme: promoData?.theme,
    displayOnPage: promoData?.displayOnPage,
  };

  return <PromoContext.Provider value={data}>{children}</PromoContext.Provider>;
};
