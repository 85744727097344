import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledCard = styled.div`
  background: var(--color-lightest-blue) 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0.5rem;
  min-height: 16rem;
  padding: 2rem;
  @media ${breakpoints.mobilelg} {
    min-height: 18rem;
    margin: 0 1rem;
  }

  h2 {
    @media ${breakpoints.tabletMax} {
      font-size: var(--fonts-sizing-h5);
    }
    @media ${breakpoints.mobileMax} {
      font-size: 13px;
      line-height: 16px;
    }
  }
`;
