import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

const { desktop } = breakpoints;

export default styled.div<any>`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 0.6rem;
  padding: 0 2rem;
  align-items: center;

  > *:first-child {
    margin-top: 1.5rem;
  }

  @media ${desktop} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0;

    > *:first-child {
      margin-top: 0;
    }
  }
`;
