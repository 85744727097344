import React from 'react';
import { Theme } from '@lib/types';

export enum CardType {
  RECOMMENDATION = 'recommendation',
  ICON = 'icon',
  IMAGE = 'image',
  COVERAGE = 'coverage',
  COVERAGE_MOBILE_MINIMAL = 'coverage_mobile_minimal',
}

export type StyledCardT = {
  type: CardType;
  cmsTheme?: Theme;
};

export type CardT = StyledCardT & {
  children: React.ReactNode;
  className?: string;
};
