import styled, { css } from 'styled-components';
import { ChipProps, ChipType } from '../types';
import { breakpoints } from '@theme/breakpoints';

export const StyledChip = styled.span<ChipProps>`
  border-radius: 1rem;
  font-size: 0.875rem;
  padding: 0 0.5rem;
  img {
    display: inline;
  }

  ${({ chipType }: ChipProps) => {
    switch (chipType) {
      case ChipType.PRIMARY:
        return css`
          background-color: var(--colors-primary);
          color: var(--colors-white);
        `;
      case ChipType.SECONDARY:
        return css`
          background-color: var(--colors-primary-30);
          color: var(--colors-primary);
        `;
      case ChipType.OUTLINE:
        return css`
          background-color: transparent;
          color: var(--colors-text-primary);
          box-shadow: 0 0 0 1px var(--colors-primary-30);
        `;
      case ChipType.SUCCESS:
        return css`
          background-color: var(--colors-success-50);
          color: var(--colors-success);
        `;
      case ChipType.ERROR:
        return css`
          background-color: var(--colors-secondary-15);
          color: var(--colors-secondary);
        `;
      case ChipType.COVERAGE:
        return css`
          background-color: white;
          padding: 0.33em 1.75rem 0.25rem;
          font-size: 0.75rem;
          font-family: var(--fonts-family-secondary);
          border-radius: 0.8rem;
          min-width: max-content;
          &::after {
            display: none;
          }

          @media ${breakpoints.tablet} {
            font-size: 1.25rem;
          }
        `;
    }
  }}
`;
