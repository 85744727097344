import { getFormattedPrice, getMonthlyPriceCalculated } from '@lib/utils';

export const getOriginalPrice = ({
  display_original_price,
  exclude_vat_price,
  hasHomePhone,
  homePhoneProduct,
  addressType,
  monthly_price,
  promo,
  formatPrice = true,
}: any) => {
  if (!promo && !display_original_price) return null;

  let originalPriceCalculated;

  if (promo) {
    const monthlyPriceWithPromoCalculated = getMonthlyPriceCalculated({
      monthly_price,
      exclude_vat_price,
      hasHomePhone,
      homePhoneProduct,
      addressType,
      promo,
      formatPrice: false,
    });

    originalPriceCalculated = getMonthlyPriceCalculated({
      monthly_price,
      exclude_vat_price,
      hasHomePhone,
      homePhoneProduct,
      addressType,
      promo: null,
      formatPrice: false,
    });

    if (parseFloat(display_original_price) === 43) {
      originalPriceCalculated = display_original_price ? parseFloat(display_original_price) : null;
    } else if (monthlyPriceWithPromoCalculated !== originalPriceCalculated) {
      return formatPrice ? getFormattedPrice(originalPriceCalculated) : originalPriceCalculated;
    } else {
      originalPriceCalculated = display_original_price ? parseFloat(display_original_price) : null;
    }
  } else {
    originalPriceCalculated = display_original_price ? parseFloat(display_original_price) : null;
  }

  if (!originalPriceCalculated) return null;

  if (addressType === 'BUS') {
    if (exclude_vat_price) {
      originalPriceCalculated = parseFloat(exclude_vat_price);
    } else {
      originalPriceCalculated = originalPriceCalculated * 0.83334;
    }
  }
  if (hasHomePhone) {
    originalPriceCalculated += parseFloat(homePhoneProduct?.monthly_price || 0);
  }
  return formatPrice ? getFormattedPrice(originalPriceCalculated) : originalPriceCalculated;
};
