import styled, { css } from 'styled-components';

function isAddonStyle({ $isAddon }) {
  if ($isAddon) {
    return css`
      margin-bottom: 1rem;
    `;
  }
}

export default styled.div`
  display: flex;
  > div {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      display: flex;
      justify-content: center;
      width: 7rem;
      margin-right: 1rem;
      border-radius: 1rem;
      background: white;
      overflow: hidden;
    }
  }
  ${isAddonStyle}
`;
