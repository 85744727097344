import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export default styled.div`
  position: fixed;
  top: 1rem;
  right: 1rem;
  padding: 2rem 1rem 1rem;
  max-width: 100%;
  border-radius: 0.5rem;
  background: var(--color-blue) 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 26px #676d9a29;
  z-index: 60;
  @media ${breakpoints.mobileMax} {
    left: 1rem;
  }
  @media ${breakpoints.mobilelg} {
    width: 25rem;
  }
  @media ${breakpoints.tablet} {
    top: 7rem;
    right: 4rem;
  }
  @media ${breakpoints.desktop} {
    position: absolute;
    max-width: unset;
    right: 2rem;
  }
`;
