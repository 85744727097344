/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useCallback, useState } from 'react';
import usePersistState from '@hooks/usePersistState';

// import { UprnStatus } from '@lib/types';
import { PostcodeDataI, PostcodeT } from './types';
import { MDUStatus } from '@lib/types';
import { getIsCurrentPostcodePreorder } from '@lib/utils/uprn';

export const PostcodeContext = createContext<PostcodeDataI>({
  postcodeItem: null,
  setPostcodeItem: () => {},
  clearPostcode: () => {},
  isCurrentPostcodeEligible: false,
  isCurrentPostcodeAvailable: false,
  isCurrentPostcodePreorder: false,
  featureList: {},
  registerInterestData: [],
  addressType: 'RES',
  postcodeString: '',
  setAddressType: () => {},
  setPostcodeString: () => {},
  hasConfirmedAddress: false,
  setHasConfirmedAddress: () => {},
});

export const PostcodeProvider = ({ children, featureList, registerInterestData }) => {
  // 24horas
  const [postcodeItem, setPostcodeItem] = usePersistState<PostcodeT | null>(
    null,
    'postcodeItem',
    86400000,
  );

  const clearPostcode = useCallback(() => setPostcodeItem(null), [setPostcodeItem]);

  const coverage = postcodeItem?.coverage || '';

  const isCurrentPostcodeEligible = Boolean(
    postcodeItem?.uprn &&
      [
        'PASSSERV',
        'CUSTOMER',
        'PREINSTALLED',
        'PASSED',
        'DESIGN',
        'INPROGRESS',
        'CERTIFIED',
      ].includes(coverage),
  );
  const isCurrentPostcodeAvailable = Boolean(
    postcodeItem?.uprn &&
      ['PASSSERV', 'CUSTOMER', 'PREINSTALLED'].includes(coverage) &&
      postcodeItem?.oss_type !== 'CERTIFIED_CUSTOMER',
  );

  const isCurrentPostcodePreorder = getIsCurrentPostcodePreorder(postcodeItem);

  const [addressType, setAddressType] = useState<string>('RES');

  // legal confirmation of being a resident or future resident
  const [hasConfirmedAddress, setHasConfirmedAddress] = usePersistState<boolean>(
    false,
    'hasConfirmedAddress',
    86400000,
  );

  const [postcodeString, setPostcodeString] = usePersistState<string>(
    '',
    'postcodeString',
    86400000,
  );

  const data: PostcodeDataI = {
    postcodeItem,
    setPostcodeItem,
    clearPostcode,
    isCurrentPostcodeEligible,
    isCurrentPostcodeAvailable,
    isCurrentPostcodePreorder,
    featureList,
    registerInterestData,
    addressType,
    setAddressType,
    hasConfirmedAddress,
    setHasConfirmedAddress,
    setPostcodeString,
    postcodeString,
  };
  return <PostcodeContext.Provider value={data}>{children}</PostcodeContext.Provider>;
};
