import React, { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';

import StyledStickyCheckoutFooter from './styled/StyledStickyCheckoutFooter';
import { ButtonType } from '@components/Atoms/Button/types';
import Link from 'next/link';
import { Button } from '@components/Atoms';
import StyledSecondaryButton from './styled/StyledSecondaryButton';
import StyledButtonsWrapper from './styled/StyledButtonsWrapper';
import SummaryIconOpen from '@public/icons/summary-icon.svg';
import SummaryIconClosed from '@public/icons/summary-icon-close.svg';
import { MiniBasketStatic } from '@components/Organisms/MiniBasket/MiniBasket';
import { Logo } from '@components/Atoms/Logo/Logo';
import Image from 'next/image';

import useBasketContext from '@hooks/useBasketContext';
import useGAEvent from '@hooks/useGAEvent';

export const StickyCheckoutFooter = ({
  className = '',
  primaryHref,
  primaryText,
  primaryCtaCallback,
  secondaryHref,
  secondaryText,
  tertiaryHref,
  tertiaryText,
  showSummaryButton = true,
  form = '',
  show = false,
  isAlwaysOnScreen = false,
  isSticky = true,
  isDisabled = false,
  primaryHrefCallback,
  id = '',
}: StickyCheckoutFooterProps) => {
  const [showSummaryBtn, setShowSummaryBtn] = React.useState(showSummaryButton);
  const [isSummaryOpen, setIsSummaryOpen] = React.useState(false);
  const SummaryIcon = !isSummaryOpen ? SummaryIconOpen : SummaryIconClosed;

  const gaEvent = useGAEvent();

  const footerRef = React.useRef(null);

  const { getBasketItemProduct, getAddonsProducts } = useBasketContext();

  const basketItem = getBasketItemProduct();
  const addons = getAddonsProducts();

  useEffect(() => {
    const handleResize = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window.innerWidth < 768) {
        setShowSummaryBtn(showSummaryButton);
      } else {
        setShowSummaryBtn(false);
      }
    };

    const debouncedResize = debounce(() => handleResize(), 500);

    window.addEventListener('resize', debouncedResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, []);

  // handleclick
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();

      if (!isSummaryOpen) {
        // this will be flipped to true so summary will open
        // get product and addons
        gaEvent.send({
          action: 'view_cart',
        });
      }
      setIsSummaryOpen(!isSummaryOpen);
    },
    [addons, basketItem?.display_name, basketItem?.id_product, isSummaryOpen, gaEvent],
  );

  // Close summary on resize if screen is greater than 768px
  useEffect(() => {
    const closeSummary = () => {
      window.innerWidth > 768 ? setIsSummaryOpen(false) : '';
    };

    const debounceCloseSummary = debounce(() => closeSummary(), 500);

    window.addEventListener('resize', debounceCloseSummary);

    if (isSummaryOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
    }

    return () => {
      window.removeEventListener('resize', debounceCloseSummary);
    };
  }, [isSummaryOpen]);

  useEffect(() => {
    document.body.classList.add('has-sticky-checkout-footer');

    return () => {
      document.body.classList.remove('has-sticky-checkout-footer');
    };
  }, []);

  const PrimaryWrapper = primaryHref ? Link : 'div';

  return show ? (
    <StyledStickyCheckoutFooter
      id={id}
      isSummaryOpen={isSummaryOpen}
      isSticky={isSticky}
      isAlwaysOnScreen={isAlwaysOnScreen}
      className={`sticky-checkout-footer ${className}`}
      ref={footerRef}
    >
      {isSummaryOpen && (
        <div className="content">
          <Logo />
          <MiniBasketStatic readOnly={false} show={isSummaryOpen} />
        </div>
      )}
      <div className="checkout-footer__nav">
        {showSummaryBtn && (
          <Button
            className="show-summary"
            as="button"
            type="button"
            buttonType={ButtonType.SUMMARY}
            onClick={handleClick}
          >
            <Image
              src={SummaryIcon.src}
              alt={isSummaryOpen ? 'Close icon' : 'Open icon'}
              width={20}
              height={20}
            />
            {isSummaryOpen ? (
              <span className="pl-4">
                <span>
                  <span className="sr-only md:not-sr-only">Close</span>
                  Close
                </span>
              </span>
            ) : (
              <span className="pl-4">
                <span>
                  <span className="sr-only md:not-sr-only">Open</span>
                  Summary
                </span>
              </span>
            )}
          </Button>
        )}

        <StyledButtonsWrapper>
          {secondaryHref && secondaryText && (
            <Link passHref href={secondaryHref}>
              <StyledSecondaryButton>{secondaryText}</StyledSecondaryButton>
            </Link>
          )}
          {secondaryText && !secondaryHref && (
            <p className="text-black text-sm md:text-lg sm:text-right max-w-[24rem]">
              {secondaryText}
            </p>
          )}
          {primaryCtaCallback && (
            <Button
              as="button"
              buttonType={ButtonType.ALTERNATE}
              onClick={primaryCtaCallback}
              {...(isDisabled ? { disabled: true, className: 'disabled' } : {})}
            >
              {primaryText}
            </Button>
          )}
          {!primaryCtaCallback ? (
            <PrimaryWrapper passHref href={primaryHref || ''}>
              <Button
                as={primaryHref ? 'a' : 'button'}
                buttonType={ButtonType.ALTERNATE}
                form={form}
                type={primaryHref ? '' : 'submit'}
                {...(isDisabled ? { disabled: true, className: 'disabled' } : {})}
                onClick={primaryHrefCallback}
              >
                {primaryText}
              </Button>
            </PrimaryWrapper>
          ) : null}
          {tertiaryHref && tertiaryText && (
            <Link passHref href={tertiaryHref}>
              <Button
                as={tertiaryHref ? 'a' : 'button'}
                buttonType={ButtonType.TERTIARY}
                form={form}
                type={tertiaryHref ? '' : 'submit'}
                {...(isDisabled ? { disabled: true, className: 'tertiary-disabled' } : {})}
                onClick={primaryHrefCallback}
              >
                {tertiaryText}
              </Button>
            </Link>
          )}
        </StyledButtonsWrapper>
      </div>
    </StyledStickyCheckoutFooter>
  ) : null;
};

interface StickyCheckoutFooterProps {
  id?: string;
  className?: string;
  primaryHref?: string;
  primaryText: string | React.ReactElement;
  secondaryHref?: string | null;
  secondaryText?: string | null;
  tertiaryHref?: string | null;
  tertiaryText?: string | null;
  primaryCtaCallback?: () => void;
  showSummaryButton?: boolean;
  form?: string;
  show?: boolean;
  isAlwaysOnScreen?: boolean;
  isSticky?: boolean;
  isDisabled?: boolean;
  primaryHrefCallback?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
