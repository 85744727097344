import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';
export const StyledFloatingImage = styled.div`
  display: none;

  &:last-of-type {
    @media (${breakpoints.tabletMax}) {
      width: 300px;
      display: block;
      top: calc(100% - 300px);
    }
    @media (${breakpoints.mobilelgMax}) {
      width: 400px;
      display: block;
      top: calc(100% - 200px);
    }
  }

  @media (${breakpoints.desktop}) {
    display: block;
  }

  position: absolute;
  left: -8rem;
  z-index: -1;
  top: calc(50% - 200px);

  & + & {
    left: auto;
    right: -8rem;
  }
`;
