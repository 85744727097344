import React, { FC } from 'react';
import { StyledListBlock } from './styled';
import { LinkList } from '@components/Atoms/LinkList';

import { Props } from './types';

export const LinkListBlock: FC<Props> = ({ data }) => {
  const { heading, linkLists } = data || {};

  return (
    <StyledListBlock className="container lg:w-4/5 mx-auto pb-12 pt-36">
      {heading && <h1 className="large mb-8">{heading}</h1>}
      {linkLists &&
        linkLists.map((list) => {
          return <LinkList key={list.heading} data={list} />;
        })}
    </StyledListBlock>
  );
};
