import React, { FC } from 'react';
import { StyledDropdown, StyledHeroServices } from './styled';
import Link from 'next/link';
import CheckIcon from '@public/icons/Check-3.svg';
import { Props } from './types';
import { fromMarkdown } from '@lib/utils';
import { List } from '@components/Atoms/List';

export const HeroServices: FC<Props> = ({ data }) => {
  const {
    headline,
    subHeadline,
    address,
    onChangeAddress,
    onChangeAddressType,
    addressType,
    checklist,
    aside,
    packageType,
  } = data;
  const DropDown: FC<any> = () => {
    return (
      <StyledDropdown
        onChange={(e: any) => {
          if (onChangeAddressType) {
            onChangeAddressType(e?.target?.value || 'RES');
          }
        }}
        value={addressType}
      >
        {packageType?.listItems?.map((item) => {
          return (
            <option key={item.key} value={item.key}>
              {item.label}
            </option>
          );
        })}
      </StyledDropdown>
    );
  };
  return (
    <StyledHeroServices className="flex flex-col relative mb-20 md:mb-60">
      <div className="hero-content container mx-auto lg:flex">
        <div className={`hero-content__column ${checklist ? 'pb-8 lg:w-1/2 lg:pb-0' : ''}`}>
          {headline && (
            <h1 className="hero-content__title x-large">{fromMarkdown({ text: headline })}</h1>
          )}
          {subHeadline && (
            <h2 className="hero-content__subtitle">{fromMarkdown({ text: subHeadline })}</h2>
          )}
          <div className="hero-content__description">
            {address && (
              <p className="hero-content__address">
                {address}
                <button className="hero-content__address-change" onClick={onChangeAddress}>
                  Change
                </button>
              </p>
            )}
          </div>
          {packageType && <DropDown />}
        </div>

        {checklist && (
          <div className="hero__content__column pb-10 lg:w-1/2 lg:pb-0 flex flex-col lg:items-end">
            <div className="info-box">
              <List list={checklist.listItems.list} icon={CheckIcon} className="info-box__list" />
              <p className="info-box__smallprint">{checklist.smallPrint}</p>
            </div>

            {aside && (
              <div className="info-box mt-2">
                <h3 className="info-box__heading">{aside.heading}</h3>
                <p className="info-box__body">
                  {aside.body}
                  {
                    <Link href={aside.CallToAction.link} passHref>
                      <a href={aside.CallToAction.link}>{aside.CallToAction.label}</a>
                    </Link>
                  }
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </StyledHeroServices>
  );
};

export default HeroServices;
