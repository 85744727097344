/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { StyledButton } from './styled';
import { ButtonProps } from './types';

export const Button: React.FC<ButtonProps> = forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledButton
      ref={ref}
      className={`button button--${props.buttonType} ${props?.className || ''}`}
      {...props}
    >
      <div>{children}</div>
    </StyledButton>
  );
});
