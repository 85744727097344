import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (${breakpoints.mobilelg}) {
    text-align: left;

    h2 {
      max-width: 60%;
    }

    & > div {
      align-items: flex-start;
    }
  }

  @media (${breakpoints.desktop}) {
    flex-direction: row;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
`;
