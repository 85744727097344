import { createContext, FC } from 'react';
import {
  SalesCountdownDataI,
  SalesCountdownProviderT,
} from '@providers/SalesCountdownProvider/types';

export const SalesCountdownContext = createContext<any>({
  CTA: {},
  description: '',
  display: true,
  offerEndsAt: '',
  displayOnPage: true,
});

export const SalesCountdownProvider: FC<SalesCountdownProviderT> = ({
  children,
  salesCountdownData,
}) => {
  const data: SalesCountdownDataI = {
    CTA: salesCountdownData?.CTA,
    description: salesCountdownData?.description,
    display: salesCountdownData?.display,
    offerEndsAt: salesCountdownData?.offerEndsAt,
    displayOnPage: salesCountdownData?.displayOnPage,
  };

  return <SalesCountdownContext.Provider value={data}>{children}</SalesCountdownContext.Provider>;
};
