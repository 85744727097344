import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';

export default styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: var(--colors-primary);
  text-align: center;

  @media (${breakpoints.tablet}) {
    font-size: 3.5rem;
  }
`;
