import { useCallback } from 'react';
import * as gtag from '@lib/utils/gtag';
import useDataLayerContext from './useDataLayerContext';
import { ProductT, PromoT } from '@lib/types';
import { getFormattedPrice, getProductPromotion } from '@lib/utils';
import { PriceT } from '@lib/utils/getFormattedPrice/types';
import { DataLayerParametersI } from '@providers/DataLayerProvider';
import { logger } from '@lib/logger';
import { getMonthlyPriceCalculated, getOriginalPrice } from '@lib/utils';
import usePostcodeContext from './usePostcodeContext';
import { PostcodeT } from '@providers/PostcodeProvider/types';

interface GAEventI {
  action: string;
  parameters?: any;
  category?: string;
  label?: string;
  value?: string;
}

interface GAEventReturn {
  send: (event: GAEventI, useAPI?: boolean) => Promise<any>;
  parameters: any;
}

type ProductTWithCalculatedPricing<t> = Partial<t> & {
  originalPrice: any;
  monthlyPriceCalculated: any;
};

const PackageTypeMap: {
  [key: string]: string;
} = {
  HWRK: 'Home Worker',
  RES: 'Home',
  BUS: 'Business',
  MSM: 'Multi-dwelling',
};

function get_ga_clientid() {
  const cookie = {
    _ga: '',
  };
  document.cookie.split(';').forEach(function (el) {
    const splitCookie = el.split('=');
    const key = splitCookie[0].trim();
    const value = splitCookie[1];
    cookie[key] = value;
  });
  cookie._ga = typeof cookie._ga === 'string' ? cookie._ga : '';
  return cookie._ga.substring(6);
}

interface AddToCartParamsI {
  currency: 'GBP';
  value: number;
  items: gtag.GA4ItemI[];
}

export interface PurchaseParamsI {
  transaction_id: string;
  currency: 'GBP';
  value: number;
  items: gtag.GA4ItemI[];
  OrderID: string;
  OrderValue: string;
  ProductType: string;
  Extras: string;
  OrderStatus: string;
  OrderLocation: string;
  CommissionGroup: string;
  PromoCodes: string;
  AWC?: string;
}

export interface ViewItemParamsI {
  currency: 'GBP';
  value: PriceT;
  items: gtag.GA4ItemI[];
}
export interface ViewItemListParamsT {
  currency?: 'GBP';
  items: {
    item_id: string;
    item_name: string;
  }[];
}

export function formatParameters({
  action,
  parameters,
  postcodeItem,
}: {
  action: string;
  parameters: DataLayerParametersI;
  postcodeItem: PostcodeT | null;
}) {
  const ActionParametersMap: {
    [key: string]: any;
  } = {
    add_to_cart: (): AddToCartParamsI => {
      const selected_product = parameters.selected_product;
      const selected_promotions = parameters.selected_promotions;
      const extras = parameters.extras || [];

      if (!selected_product) {
        logger?.error('No product selected for add_to_cart event.');

        return {
          currency: 'GBP',
          value: 0,
          items: [],
        };
      }

      const value = +getFormattedPrice(selected_product.monthlyPriceCalculated, true);

      return {
        currency: 'GBP',
        value,
        items: [
          {
            item_id: selected_product.id_product || 'unknown',
            item_name: `${selected_product.name}`,
            item_list_name: `${PackageTypeMap[selected_product.address_type || '']} Broadband`,
            item_category: PackageTypeMap[selected_product.address_type || ''],
            item_category2: `${selected_product.months} months`,
            price: value,
            discount: +getFormattedPrice(
              selected_product.originalPrice - selected_product.monthlyPriceCalculated,
              true,
            ),
            coupon: Array.isArray(selected_promotions)
              ? selected_promotions.map((promo: PromoT) => promo?.code).join(',')
              : '',
          },
          ...extras,
        ],
      };
    },
    add_extra_to_cart: (): AddToCartParamsI => {
      const extra = parameters.add_extra as ProductTWithCalculatedPricing<ProductT>;
      const originalPrice = parameters.originalPrice || 0;
      const monthlyPriceCalculated = parameters.monthlyPriceCalculated || 0;
      const discount = +getFormattedPrice(originalPrice - monthlyPriceCalculated, true);

      const items = [
        {
          item_id: extra.id_product || 'unknown',
          item_name: extra.name || 'unknown',
          item_list_name: 'Extras',
          item_category: 'Extra',
          price: +getFormattedPrice(parameters.monthlyPriceCalculated, true),
          discount: isNaN(discount) || !discount || discount < 0 ? 0 : discount,
        },
      ];

      return {
        items,
        currency: 'GBP',
        value: items.reduce((acc: number, item: any) => acc + item.price, 0),
      };
    },
    remove_extra_from_cart: () => {
      const extraId = parameters.remove_extra;
      const items = parameters.items?.filter((item: any) => item.item_id !== extraId);

      return {
        items,
        currency: 'GBP',
        value: items?.reduce((acc: number, item: any) => acc + item.price, 0),
      };
    },
    view_item_list: (): ViewItemListParamsT => {
      const products = parameters.viewed_items;
      const promotions = parameters.promotions;

      const items = products.map((product) => {
        const promos = getProductPromotion({
          id_product: product.id_product,
          coverage: postcodeItem?.coverage,
          promotions,
        });

        const deductablePromo = Array.isArray(promos)
          ? promos.find(({ promotion_type }) => promotion_type === 'RECURAMTOFF')
          : promos;

        const originalPrice = getOriginalPrice({
          display_original_price: product.display_original_price,
          monthly_price: product.monthly_price,
          promo: deductablePromo,
          formatPrice: true,
        });

        const monthlyPriceCalculated = getMonthlyPriceCalculated({
          monthly_price: product.monthly_price,
          promo: deductablePromo,
          formatPrice: true,
        });

        const value = +getFormattedPrice(monthlyPriceCalculated, true);

        return {
          item_id: product.id_product,
          item_name: product.name,
          item_list_name: `${PackageTypeMap[product.address_type || '']} Broadband`,
          item_category: PackageTypeMap[product.address_type || ''],
          item_category2: `${product.months} months`,
          price: value,
          discount: +getFormattedPrice(
            Number(originalPrice) - Number(monthlyPriceCalculated),
            true,
          ),
          coupon: Array.isArray(promos) ? promos.map((promo: PromoT) => promo?.code).join(',') : '',
        };
      });

      return {
        currency: 'GBP',
        items,
      };
    },
    view_item: (): ViewItemParamsI => {
      return {
        currency: 'GBP',
        value: parameters.value,
        items: parameters.items,
      };
    },
    select_promotion: (): {
      promotion_name: string;
      items: gtag.GA4ItemI[];
    } => {
      return {
        promotion_name: parameters.promotion_name,
        items: parameters.items,
      };
    },
    purchase: (): PurchaseParamsI => {
      return {
        transaction_id: parameters.OrderID,
        currency: 'GBP',
        value: +getFormattedPrice(parameters.value, true),
        items: parameters.items?.map((item: any) => {
          return {
            ...item,
            price: +getFormattedPrice(item.price, true),
            discount: +getFormattedPrice(item.discount, true),
          };
        }),
        OrderID: parameters.OrderID,
        OrderValue: parameters.OrderValue,
        ProductType: parameters.ProductType,
        Extras: parameters.Extras,
        OrderStatus: parameters.OrderStatus,
        OrderLocation: parameters.OrderLocation,
        CommissionGroup: parameters.CommissionGroup,
        PromoCodes: parameters.PromoCodes,
        AWC: parameters.AWC,
      };
    },
  };

  if (ActionParametersMap[action]) {
    return ActionParametersMap[action](parameters);
  }

  return parameters;
}

function useGAEvent(): GAEventReturn {
  const { parameters, setParameters } = useDataLayerContext();
  const { postcodeItem } = usePostcodeContext();
  const send = useCallback(
    (event: GAEventI) => {
      const newParams = event.parameters || {};
      const params = formatParameters({
        action: event.action,
        parameters: {
          ...parameters,
          ...newParams,
        },
        postcodeItem,
      });

      if (['view_item_list', 'view_item', 'select_promotion'].indexOf(event.action) === -1) {
        // Only store some params in state
        setParameters(params);
      }

      switch (event.action) {
        case 'add_extra_to_cart':
          event.action = 'add_to_cart';
          break;
        case 'remove_extra_from_cart':
          event.action = 'remove_from_cart';
          break;
        default:
          event.action = event.action;
          break;
      }

      const body = {
        action: event.action,
        params,
        ga_client_id: get_ga_clientid(),
      };

      gtag.event({
        ...event,
        action: event.action,
        parameters: {
          ...params,
          debug_mode: localStorage.getItem('debug_mode'),
        },
      });

      // return fetch('/api/ga4-ecom-event', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(body),
      // });

      return new Promise((resolve) => {
        resolve(true);
      });
    },
    [parameters, setParameters],
  );

  return {
    send,
    parameters,
  };
}

export default useGAEvent;
