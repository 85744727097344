import { breakpoints } from '@theme/breakpoints';
import styled, { css } from 'styled-components';

function firstAndLastChildStyle({ $firstChild = false, $lastChild = false }) {
  if ($firstChild) {
    return css`
      border-top: 0;
      padding-top: 0;
      padding-bottom: 1rem;
    `;
  }
  if ($lastChild) {
    return css`
      border-bottom: 0;
      padding-bottom: 0;
      padding-top: 1rem;
      border-top: 1px solid #66707070;
    `;
  }
}

function promoCodeStyle({ $promoCode = false }) {
  if ($promoCode) {
    return css`
      padding-top: 0;
      &,
      > div:first-child {
        font-size: 1rem;
      }
    `;
  }
}

export default styled.div<any>`
  display: flex;
  justify-content: space-between;
  font-family: Geomanist, Segoe, 'Segoe UI', 'DejaVu Sans', 'Trebuchet MS', Verdana, 'sans-serif';
  padding: 0.75rem 0;
  color: var(--colors-grey-100);
  > div:first-child {
    font-size: 0.75rem;
    color: var(--colors-grey-50);
  }

  strong:not(.benefit-tag) {
    font-size: 1.063rem;
    color: var(--colors-text-primary);

    @media ${breakpoints.desktop} {
      font-size: 1.313rem;
    }
  }

  .row__info {
    font-size: 0.938rem;

    @media ${breakpoints.desktop} {
      font-size: 1.063rem;
    }
  }

  .row__cost-pm,
  .row__extra {
    font-weight: bold;
  }

  & .remove-container {
    padding-right: 0 !important;
    display: flex;
    align-items: center;
    .remove-button {
      background: none;
    }
  }

  & .original-price {
    font-size: 0.9rem;
    text-decoration: line-through;
  }

  & .benefit {
    font-weight: normal;
  }

  & .remove-button {
    display: inline-flex;
    text-decoration: underline;
    margin-left: 1.5rem;
    line-height: normal;
    font-size: 1.063rem;
  }

  .row__total-costs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

    &__column {
      strong {
        font-size: 1.313rem;
        color: var(--colors-primary);
      }

      p {
        margin-top: 0.5rem;
        font-size: 1.25rem;
        font-weight: bold;
        color: var(--colors-text-primary);
        font-family: Geomanist, Segoe, 'Segoe UI', 'DejaVu Sans', 'Trebuchet MS', Verdana,
          'sans-serif';

        @media ${breakpoints.desktop} {
          font-size: 2.5rem;
        }
      }
    }
  }

  ${firstAndLastChildStyle}
  ${promoCodeStyle}
`;
