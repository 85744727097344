/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FC } from 'react';
import Link from 'next/link';

import usePromoContext from '@hooks/usePromoContext';
import usePostcodeContext from '@hooks/usePostcodeContext';

import { CmsEventSubscriber } from '@hoc/index';
import { match } from '@lib/utils';
import { Event } from '@lib/types';

import { StyledBanner } from './styled';
import { useRouter } from 'next/router';

const BannerWrapper: FC<any> = ({ children, onClick, link }) => {
  if (onClick)
    return (
      <div role="button" onClick={onClick} className="w-full bg-[#2E2E44] m-0">
        {children}
      </div>
    );

  if (link)
    return (
      <Link href={link} passHref>
        {children}
      </Link>
    );

  return children;
};

export const Banner: FC<any> = ({ className }) => {
  const { theme, CTA, displayOnPage } = usePromoContext() || {};
  const router = useRouter();

  const EnhancedBannerWrapper = match(CTA)
    .on(
      (CTA: any) => CTA?.event === Event.GO_TO_PACKAGES,
      () =>
        CmsEventSubscriber(BannerWrapper, {
          link: '#product-selector',
        }),
    )
    .on(
      (CTA: any) => CTA?.event === Event.POSTCODE_CHECKER,
      () =>
        CmsEventSubscriber(BannerWrapper, {
          onClick: () => router.push('/address-finder'),
        }),
    )
    .otherwise(() =>
      CmsEventSubscriber(BannerWrapper, {
        link: CTA?.link,
      }),
    );

  return (
    (displayOnPage && CTA?.display && (
      <EnhancedBannerWrapper>
        <StyledBanner className={className}>
          <span>{CTA?.label}</span>
        </StyledBanner>
      </EnhancedBannerWrapper>
    )) || <></>
  );
};
