import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';

export const StyledCoverageGrid = styled.ul`
  list-style: none;
  display: grid;
  gap: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  @media ${breakpoints.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${breakpoints.desktop} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${breakpoints.desktopLarge} {
    max-width: 70vw;
    min-width: 80rem;
    margin: 0 auto;
  }
`;
