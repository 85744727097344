import styled from 'styled-components';

export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-top: 0.7rem;
  img {
    margin-right: 0.875rem;
  }
`;
