import styled from 'styled-components';
import { Button } from '@components/Atoms';

export default styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent linear-gradient(100deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
    padding-box;

  & > div {
    justify-content: center;
  }
`;
