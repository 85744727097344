import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export default styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background: rgba(15, 15, 17, 0.8);
  backdrop-filter: blur(20px);
  z-index: 40;
  @media ${breakpoints.tablet} {
    backdrop-filter: blur(0);
    background: none;
  }
`;
