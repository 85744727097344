import { rem } from 'polished';

const typography = {
  family: {
    primary: 'Roboto',
    secondary: 'Geomanist',
  },
  sizing: {
    h1Small: rem(40),
    h1Large: rem(87),
    h2Large: rem(56),
    h2Small: rem(32),
    h3Large: rem(40),
    h4: rem(32),
    h5: rem(24),
    h6: rem(20),
    primary: rem(16),
    small: rem(14),
    xSmall: rem(12),
    button: rem(20),
    caption: rem(14),
  },
};

export default typography;
