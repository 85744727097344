import React, { FC } from 'react';
import { Icon } from '@components/Atoms/Icon';
import { StyledToggle, StyledToggleItem } from './styled';
import { ToggleProps } from './types';

export const Toggle: FC<ToggleProps> = ({
  toggles,
  className,
  selected,
  setSelected,
}: ToggleProps) => {
  return (
    <StyledToggle className={className}>
      {toggles.map((toggle) => (
        <StyledToggleItem
          key={toggle.name}
          selected={selected === toggle.value}
          description={!!toggle.description}
          onClick={() => setSelected(toggle.value)}
        >
          <div className="toggle-content">
            <div className="toggle-name">
              {toggle.icon ? <Icon className="toggle-icon" xs icon={toggle.icon} /> : null}
              {toggle.name}
            </div>
            {toggle.description ? (
              <span className="toggle-description">{toggle.description}</span>
            ) : null}
          </div>
        </StyledToggleItem>
      ))}
    </StyledToggle>
  );
};
