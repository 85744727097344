// tailwind default breakpoints https://tailwindcss.com/docs/screens
export const deviceSizes = {
  sm: 640,
  md: 768,
  mlg: 992,
  lg: 1024,
  xlg: 1280,
  xxlg: 1536,
};

export const breakpoints = {
  mobileMax: `(max-width: ${deviceSizes.sm - 1}px)`,
  mobilelg: `(min-width: ${deviceSizes.sm}px)`,
  mobilelgMax: `(max-width: ${deviceSizes.md - 1}px)`,
  tablet: `(min-width: ${deviceSizes.md}px)`,
  tabletMax: `(max-width: ${deviceSizes.lg - 1}px)`,
  tabletOnly: `(min-width: ${deviceSizes.md}px) and (max-width: ${deviceSizes.lg - 1}px)`,
  smallDesktop: `(min-width: ${deviceSizes.mlg}px)`,
  smallDesktopMax: `(max-width: ${deviceSizes.lg - 1}px)`,
  desktop: `(min-width: ${deviceSizes.lg}px)`,
  desktopMax: `(max-width: ${deviceSizes.xlg - 1}px)`,
  desktopLarge: `(min-width: ${deviceSizes.xlg}px)`,
  desktopLargeMax: `(max-width: ${deviceSizes.xxlg - 1}px)`,
  desktopXL: `(min-width: ${deviceSizes.xxlg}px)`,
};
