import styled from 'styled-components';

export const StyledCopyWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    padding: 0.5rem 0 0.5rem 0;
  }

  ul {
    list-style-type: unset;
    padding-left: 4rem;
  }

  ol {
    list-style-type: decimal;
    padding-left: 4rem;
  }

  a {
    color: var(--colors-primary);
  }
`;
