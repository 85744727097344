import { FC, useEffect, useState } from 'react';
import useSalesCountdownContext from '@hooks/useSalesCountdownContext';

import StyledSalesCountdown from './styled/StyledSalesCountdown';
import StyledSalesCountdownContent from './styled/StyledSalesCountdownContent';
import StyledSalesCountdownDescription from './styled/StyledSalesCountdownDescription';
import StyledSalesCountdownTimerWrapper from './styled/StyledSalesCountdownTimerWrapper';
import StyledSalesCountdownIntervalWrapper from './styled/StyledSalesCountdownIntervalWrapper';
import StyledSalesCountdownIntervalLabel from './styled/StyledSalesCountdownIntervalLabel';
import StyledSalesCountdownIntervalCtaWrapper from './styled/StyledSalesCountdownIntervalCtaWrapper';

import { fromMarkdown } from '@lib/utils';
import { Countdown } from '@components/Molecules/Countdown';
import { Cta } from '@components/Molecules';

export const SalesCountdown: FC = () => {
  const { CTA, description, display, offerEndsAt, displayOnPage } =
    useSalesCountdownContext() || {};

  const [diffInSeconds, setDiffInSeconds] = useState(() => {
    const now = Date.now();

    const offerEndsAtDate = new Date(offerEndsAt);

    return Math.floor((offerEndsAtDate.getTime() - now) / 1000);
  });

  useEffect(() => {
    const ti = setInterval(() => {
      setDiffInSeconds((v) => v - 1);
    }, 1000);

    return () => clearInterval(ti);
  }, []);

  let restDiffInSeconds = diffInSeconds;

  const countdownDays = diffInSeconds > 0 ? Math.floor(restDiffInSeconds / 86400) : 0;
  restDiffInSeconds -= countdownDays * 86400;

  const countdownHours = diffInSeconds > 0 ? Math.floor(restDiffInSeconds / 3600) : 0;
  restDiffInSeconds -= countdownHours * 3600;

  const countdownMinutes = diffInSeconds > 0 ? Math.floor(restDiffInSeconds / 60) : 0;
  restDiffInSeconds -= countdownMinutes * 60;

  const countdownSeconds = restDiffInSeconds > 0 ? restDiffInSeconds : 0;

  return display && displayOnPage ? (
    <StyledSalesCountdown>
      <StyledSalesCountdownContent>
        <StyledSalesCountdownDescription>
          {fromMarkdown({ text: description, isHeading: false })}
        </StyledSalesCountdownDescription>

        <StyledSalesCountdownTimerWrapper>
          <StyledSalesCountdownIntervalWrapper>
            <Countdown numOfDigits={3}>{countdownDays}</Countdown>
            <StyledSalesCountdownIntervalLabel>Days</StyledSalesCountdownIntervalLabel>
          </StyledSalesCountdownIntervalWrapper>

          <StyledSalesCountdownIntervalWrapper>
            <Countdown numOfDigits={2}>{countdownHours}</Countdown>
            <StyledSalesCountdownIntervalLabel>Hours</StyledSalesCountdownIntervalLabel>
          </StyledSalesCountdownIntervalWrapper>

          <StyledSalesCountdownIntervalWrapper>
            <Countdown numOfDigits={2}>{countdownMinutes}</Countdown>
            <StyledSalesCountdownIntervalLabel>Minutes</StyledSalesCountdownIntervalLabel>
          </StyledSalesCountdownIntervalWrapper>

          <StyledSalesCountdownIntervalWrapper>
            <Countdown numOfDigits={2}>{countdownSeconds}</Countdown>
            <StyledSalesCountdownIntervalLabel>Seconds</StyledSalesCountdownIntervalLabel>
          </StyledSalesCountdownIntervalWrapper>
        </StyledSalesCountdownTimerWrapper>

        <StyledSalesCountdownIntervalCtaWrapper>
          <Cta {...CTA} className="cta-button" />
        </StyledSalesCountdownIntervalCtaWrapper>
      </StyledSalesCountdownContent>
    </StyledSalesCountdown>
  ) : null;
};
