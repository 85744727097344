import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { DefaultHeroContent } from '@components/Molecules/Hero/variants/Default/styled';
import { breakpoints } from '@theme/breakpoints';
import { supportsSafariOnly } from '@lib/utils/styling/supportsSafariOnly';

export const StyledHeroMain = styled.div<{
  desktopImageSrc: string | null;
  hasCountdownTimer: boolean;
  paddingTop?: string;
  paddingBottom?: string;
}>`
  min-height: 320px;
  background-color: var(--colors-white);
  overflow: hidden;

  @media ${breakpoints.desktopLarge} {
    padding-top: ${({ paddingTop }) => (paddingTop ? `calc(${paddingTop}rem + 6vw)` : '6vw')};
    padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? `calc(${paddingBottom}rem + 3vw)` : '3vw'};
  }

  &,
  p {
    color: var(--colors-primary-bright);
  }
  ${DefaultHeroContent};

  .hero-content {
    ${({ desktopImageSrc }) =>
    desktopImageSrc
      ? css`
            @media ${breakpoints.desktopLarge} {
              background-image: url('${desktopImageSrc}');
              background-repeat: no-repeat;
              background-position: top right;
              background-size: auto 100%;
            }
          `
      : ''};

    // TODO: remove h1 when the new design is implemented
    h1 {
      span.large {
        font-size: inherit;
      }

      @media ${breakpoints.desktop} {
        font-size: ${rem(70)};

        span.large {
          font-size: ${rem(140)};
        }
      }
    }

    // TODO: remove h2 when the new design is implemented
    h2 {
      font-size: ${rem(40)};
    }

    @media ${breakpoints.desktopLarge} {
      margin-top: -4rem;
    }

    @media (min-width: 1450px) {
      padding-top: 2rem;
    }

    @media ${breakpoints.desktopXL} {
      padding-top: 0;
      padding-bottom: ${rem(24)};
    }

    @media (min-width: 1600px) {
      padding-bottom: 4vw;
    }

    &__text {
      position: static;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 3;
      padding: 2.5rem 2rem 0;
      padding-top: 2rem;
      box-shadow: 0 -4px 6px -2px rgba(0, 0, 0, 0.2);

      @media ${breakpoints.tablet} {
        max-width: 100%;
        margin-top: 0;
      }

      @media ${breakpoints.desktop} {
        box-shadow: none;
      }

      @media ${breakpoints.desktopLarge} {
        max-width: 50%;
        padding-bottom: 1rem;
        padding-top: 1.5rem;

        ${supportsSafariOnly(`
          padding-right: 0;
          padding-left: 0;
        `)}
      }
    }

    &__trust-box {
      margin: 2rem 1rem;
      overflow: hidden;
      @media ${breakpoints.desktop} {
        margin-left: 0;
        background: rgb(255 255 255 / 78%);
        padding: 0.5rem 1rem;
        height: 2.25rem;
        width: 324px;
        border-radius: 8px;
      }

      @media ${breakpoints.desktop} {
        margin-left: -1.75rem;
        margin-bottom: 0;
        .trustpilot-widget {
          height: 2.25rem;
        }
      }

      .trustpilot-widget {
        @media ${breakpoints.desktop} {
          transform: translateX(1rem);
        }
      }
    }

    &__postcode_label {
      margin-top: ${rem(8)};
      font-family: var(--fonts-family-secondary);
      font-size: ${rem(16)};

      @media ${breakpoints.desktopLarge} {
        font-size: ${rem(20)};
      }
    }
  }
`;

export const FakeInput = styled.div<{ isStandalone: boolean }>`
  position: relative;
  margin-top: ${rem(12)};
  margin-bottom: ${rem(32)};
  width: 100%;
  max-width: ${rem(450)};
  -webkit-transform-style: preserve-3d;
  ${({ isStandalone }) =>
    !isStandalone
      ? `
  width: calc(100% - 2rem);
  margin-left: 1rem;

  @media ${breakpoints.desktop} {
    margin-left: 0;
    width: 100%;
  }

  `
      : ''}
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    inset: 5px;
    filter: blur(12px);
    transform: translate3d(0px, 1x, -1px);
    border-radius: 15px;
    pointer-events: none;
    background: transparent linear-gradient(99deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat;
  }
  > input:first-child {
    border-radius: 15px;
    background: var(--colors-white);
    color: black;
    text-align: left;
    position: relative;
    display: block;
    font-family: var(--fonts-family-secondary);
    margin: 1px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    padding: ${rem(16)};
    width: 100%;
    max-width: 100%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--colors-grey-30);
    }

    @media ${breakpoints.desktopLarge} {
      min-width: 330px;
      font-size: ${rem(16)};
      font-weight: 400;
      padding: ${rem(22)};
      letter-spacing: 0.5px;
      min-width: 400px;
    }
  }

  > button {
    position: absolute;
    right: 1rem;
    top: calc(50% - 1rem);
    height: 2rem;
    ${supportsSafariOnly(`
      line-height: 1;
    `)}

    > div {
      margin-top: 0.1rem;
    }
  }
`;
