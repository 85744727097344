import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  p {
    color: var(--colors-grey-50);
    margin-bottom: 1rem;
    @media (${breakpoints.tablet}) {
      text-align: left;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
    color: black;
    font-family: var(--font-geomanist);
    font-weight: 500;
    @media (${breakpoints.tablet}) {
      text-align: left;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.5rem;
  }

  strong {
    color: black;
  }

  ul {
    display: flex;
    text-align: left;
    flex-direction: column;
    color: var(--colors-grey-50);
  }

  li:before {
    content: '•';
    margin-right: 0.5rem;
  }

  a {
    text-decoration: underline;
    color: var(--colors-primary);
  }

  @media (${breakpoints.tablet}) {
    flex-basis: 50%;
  }
`;
