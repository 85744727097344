import styled from 'styled-components';

const StyledDescription = styled.div<{ color?: string }>`
  margin: 0.5rem 0 .5rem;
  p {
    font-family: var(--font-geomanist);
    color: ${(props) => props.color || `var(--color-black)`};
    font-size: 1.33rem;
    font-weight: 400;

    &:last-child {
      margin-top: -.5rem;
      font-weight: 300;
  }
`;

export { StyledDescription };
