import { renderImage } from '@lib/utils';
import { Format, Layout } from '@lib/types';
import React from 'react';
import { StyledImageBanner } from './styled';

export const ImageBanner = ({ image, display }) => {
  const { alternativeText, formats } = image || {};

  return (
    <StyledImageBanner>
      {display &&
        renderImage({
          className: 'image-content',
          alternativeText,
          formats,
          format: Format.LARGE,
          layout: Layout.RESPONSIVE,
        })}
    </StyledImageBanner>
  );
};
