import styled from 'styled-components';
import { StyledSwitchProps } from '@components/Atoms/Switch/types';

export const StyledSwitch = styled.div<StyledSwitchProps>`
  background-color: #f2f2f2;
  box-shadow: inset 0px 3px 6px #00000029;
  height: 38px;
  width: 82px;
  border-radius: 19px;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    left: ${({ checked }) => (checked ? '49px' : '7px')};
    top: 6px;
    width: 28px;
    height: 28px;
    filter: blur(15px);
    border-radius: inherit;
    pointer-events: none;
    background: transparent linear-gradient(99deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
      padding-box;
    transition: all 0.5s;
  }

  .switch-thumb {
    position: absolute;
    left: ${({ checked }) => (checked ? '49px' : '7px')};
    top: 6px;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    background: ${({ checked }) =>
      checked
        ? 'transparent linear-gradient(136deg, #F4111B 0%, #0402FD 100%) 0% 0% no-repeat padding-box'
        : 'var(--colors-white)'};
    transition: all 0.5s;
  }
`;
