import React, { FC } from 'react';
import { ActionCardListT } from './types';
import { StyledActionCardList } from '@components/Organisms/ActionCardList/styled';
import { SectionHeading } from '@components/Molecules';
import { ActionCard } from '@components/Organisms/ActionCardList/ActionCard';

export const ActionCardList: FC<ActionCardListT> = ({ heading, actionCards, display }) => {
  if (!display) return null;

  return (
    <StyledActionCardList className="slice card-list">
      <div className="slice__inner flex-col">
        <h2 className="slice__title card-list__title">{heading}</h2>
        <div className="card-list__cards">
          {actionCards.map((actionCard) => (
            <ActionCard key={actionCard.heading} {...actionCard} />
          ))}
        </div>
      </div>
    </StyledActionCardList>
  );
};
