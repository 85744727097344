import { breakpoints } from '@theme/breakpoints';
import { WidgetType, StyledWidgetProps } from '../types';
import styled, { css } from 'styled-components';
export const WidgetWrapper = styled.div<StyledWidgetProps>`
  ${({ backgroundColor }) => {
    if (backgroundColor) {
      switch (backgroundColor) {
        case 'lightBlue':
          return css`
            background-color: var(--color-lightest-blue);
          `;
        default:
          return css`
            background-color: ${backgroundColor};
          `;
          break;
      }
    }
  }}

  ${({ type }) => {
    switch (type) {
      case WidgetType.CAROUSEL:
        return css`
          padding-bottom: 2rem;
          padding-top: 2rem;
          color: var(--colors-text-primary);
          iframe {
            min-height: 300px;
            @media ${breakpoints.tablet} {
              min-height: 140px;
            }
          }
        `;
      case WidgetType.MICROSTAR:
        return css`
          iframe {
            min-height: 24px;
          }
        `;
    }
  }};
`;
