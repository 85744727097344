import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from '@theme/breakpoints';
export const StyledTextElementList = styled.div`
  .textElementList {
    &__heading {
      color: var(--colors-grey-100);
      font-size: ${rem(24)};
      font-weight: 700;
      @media ${breakpoints.tablet} {
        font-size: ${rem(40)};
      }
    }
    &__items {
      display: inline-block;
      text-align: left;
      > :last-child {
        border-bottom: 1px;
      }
    }
  }
  .listStyleCircle {
    list-style: circle;
  }
  .listStyleSquare {
    list-style: square;
  }
  .listStyleDisc {
    list-style: disc;
  }
  .listStyleNumber {
    list-style: decimal;
  }
  .listStyleCheck {
    list-style-type: '✓';
  }
`;
