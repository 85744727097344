import styled, { css } from 'styled-components';
import { StyledCheckAvailabilityBottomImageWrapperT } from '@components/Organisms/CheckAvailability/types';
import { breakpoints } from '@theme/breakpoints';

export const StyledCheckAvailabilityBottomImageWrapper = styled.div<StyledCheckAvailabilityBottomImageWrapperT>`
  margin-top: 0.4rem;
  ${({ animationDirection }) =>
    animationDirection === 'forward'
      ? css`
          transition: all 2s;
        `
      : ''}
  transform: ${({ animationDirection }) =>
    animationDirection === 'forward' ? 'translateX(100%)' : 'translateX(-300px)'};
  img {
    height: 120px !important;
    width: auto;
    @media ${breakpoints.desktop} {
      height: auto !important;
    }
  }
`;
