import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export default styled.button`
  font-family: Geomanist, Segoe, 'Segoe UI', 'DejaVu Sans', 'Trebuchet MS', Verdana, 'sans-serif';
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  color: var(--colors-primary);
  text-align: left;
  font-weight: bold;
  margin-bottom: 1rem;
  @media ${breakpoints.mobilelgMax} {
    border-radius: 0.5rem;
  }
  > *:nth-child(2) {
    padding-left: 1rem;
  }

  .basket__close__title {
    font-weight: 500;
    font-size: 1.563rem;

    @media ${breakpoints.desktop} {
      font-size: 2.063rem;
    }
  }

  .basket__close__button {
    text-decoration: underline;
    font: normal normal bold 12px/30px Geomanist;
  }
`;
