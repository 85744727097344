export const GA_TRACKING_ID = process.env.GA_TRACKING_ID || 'G-DPK2Y626FN';

export interface GA4ItemI {
  item_id: string;
  item_name: string;
  item_list_name: string;
  item_category: string;
  item_category2?: string;
  price: number;
  discount: number;
  coupon?: string;
}

export type ParametersGA4 = {
  currency: string;
  value: number;
  transaction_id?: string;
  OrderID?: string;
  OrderValue?: string;
  ProductType?: string;
  Extras?: string;
  OrderStatus?: string;
  OrderLocation?: string;
  CommissionGroup?: string;
  PromoCodes?: string;
  AWC?: string;
  items?: GA4ItemI[];
};

export type ParametersGAU = {
  event_category: string;
  event_label?: string;
  value?: number;
};

interface ParametersDataLayer {
  [key: string]: string | number | boolean | Array<any> | undefined;
}

export type GTagEvent = {
  action: string;
  parameters?: ParametersGA4 | ParametersGAU | ParametersAWIN | ParametersDataLayer;
};

type TrackedProductT = {
  id: string | undefined;
  name: string | undefined;
  price: string;
  quantity: string;
  cgCode: string | undefined;
  sku?: string;
  category?: string;
};

export type ParametersPLT = Array<TrackedProductT>;

export type ParametersAWIN = {
  OrderID: string;
  OrderValue: string;
  ProductType: string | null | undefined;
  OrderStatus: string;
  OrderLocation: string | undefined;
  SignUpDate?: string | null;
  Extras?: string | null;
  CommissionGroup: string | undefined;
  PromoCodes: string;
  transactionProducts?: ParametersPLT;
  AWC?: string | null;
};

// https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
// https://developers.google.com/analytics/devguides/collection/ga4/event-parameters?client_type=gtag
export const event = ({ action, parameters }: GTagEvent) => {
  if (!window.gtag) {
    return false;
  }

  window.gtag('event', action, parameters);
};
