import styled from 'styled-components';

export const StyledDropdownItem = styled.div<any>`
  width: 100%;
  min-height: 3rem;
  background-color: var(--colors-primary-3);
  display: flex;
  align-items: center;
  justify-content: left;
  color: var(--colors-black);
  padding: 0.7rem 0.875rem;
  :hover {
    background-color: var(--colors-primary-15);
  }
`;
