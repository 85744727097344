export interface InputProps {
  label?: string;
  error?: string;
  fullWidth?: boolean;
  textArea?: boolean;
  type?: InputType;
  fieldName?: string;
  onKeyEnter?: (event?: any) => void;
  onKeyEscape?: (event?: any) => void;
  onKeyArrowUp?: (event?: any) => void;
  onKeyArrowDown?: (event?: any) => void;
  display?: boolean;
  tooltip?: string;
  options?: any[];
  formData?: any;
  autoComplete?: string;
}

export interface StyledInputProps {
  isActive: boolean;
  isFilled: boolean;
  fullWidth?: boolean;
  error?: boolean;
  textArea?: boolean;
}

export enum InputType {
  TEXT = 'text',
  EMAIL = 'email',
  PASSWORD = 'password',
  DATE = 'date',
  HIDDEN = 'hidden',
  TEXTAREA = 'textarea',
  PHONE = 'tel',
  BOOLEAN = 'boolean',
  RADIO = 'radio',
  CHECKBOX_GROUP = 'checkbox-group',
}
