import React from 'react';
import { StyledCarouselArrow } from '@components/Atoms/CarouselArrow/styled';

export const CarouselNextArrow = (props) => {
  const { className, onClick } = props;
  return <StyledCarouselArrow className={className} onClick={onClick} />;
};

export const CarouselPrevArrow = (props) => {
  const { className, onClick } = props;
  return <StyledCarouselArrow className={className} onClick={onClick} />;
};

export default {
  CarouselNextArrow,
  CarouselPrevArrow,
};
