import styled from 'styled-components';
import { rem } from 'polished';

export default styled.div`
  background-color: var(--colors-grey-100);
  padding: ${rem(12)};
  display: flex;
  justify-content: center;
  width: 100%;
`;
