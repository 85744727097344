import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledImageBanner = styled.div`
  background-color: var(--colors-primary);
  background-image: linear-gradient(#2c2cde, #1643e3);
  padding-top: 6rem;
  max-height: 20rem;
  & + div {
    padding-top: 3rem !important;
  }
  @media ${breakpoints.desktop} {
    padding-top: 9rem;
    & + div {
      padding-top: 6rem !important;
    }
    .image-content {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
`;
