import React, { FC } from 'react';
import { StyledVideoPlayer } from './styled';
import ReactPlayer from 'react-player/lazy';
import { Props } from './types';

export const VideoPlayer: FC<Props> = ({ data, playing, onPause, onPlay }) => {
  const { url } = data;

  return (
    <StyledVideoPlayer>
      <ReactPlayer
        controls={true}
        playing={playing}
        onPause={onPause}
        onPlay={onPlay}
        loop={false}
        url={url}
        width="100%"
        height="100%"
        className="video-player"
      />
    </StyledVideoPlayer>
  );
};
