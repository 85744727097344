import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledCard = styled.div`
  width: 100%;
  border-radius: 0.5rem;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  h2 {
    color: var(--colors-primary);
    font-family: var(--font-geomanist);
    font-weight: 500;
    margin-bottom: 0.5rem;
    font-size: 2rem;
    max-width: 80%;
  }

  p {
    font-family: var(Roboto);
  }

  & > p {
    max-width: 75%;
  }

  & > span:first-of-type {
    position: absolute !important;
    top: -40px;
    left: -20px;

    @media ${breakpoints.mobilelg} {
      top: -20px;
    }
  }
  & > span:last-of-type {
    position: absolute !important;
    bottom: -20px;
    right: -20px;
  }

  .image-right,
  .image-left {
    position: absolute;
  }

  .image-left {
    top: -50px;
    left: 0;

    @media ${breakpoints.tablet} {
      left: -30px;
    }

    img {
      width: 120px !important;
      height: auto !important;

      @media ${breakpoints.desktop} {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .image-right {
    display: none;

    @media ${breakpoints.mobilelg} {
      display: block;
      bottom: -80px;
      right: -40px;
    }

    @media ${breakpoints.tablet} {
      display: block;
      bottom: -80px;
      right: -60px;
    }

    img {
      width: 150px !important;
      height: auto !important;

      @media ${breakpoints.desktop} {
        width: 100% !important;
        height: 100% !important;
        bottom: -50px;
        right: -50px;
      }
    }
  }
`;
