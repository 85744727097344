import styled, { css } from 'styled-components';

import { breakpoints } from '@theme/breakpoints';

function isAbovePackagesStyle({ $isAbovePackages = false }) {
  if ($isAbovePackages) {
    return css`
      text-align: center;
      padding: 0 0 3rem;
      margin-top: -0.5rem;
      @media ${breakpoints.mobileMax} {
        padding: 0 4rem 1rem;
      }
    `;
  }
  return css`
    color: var(--colors-grey-50);
    margin-top: 2rem;
    margin-bottom: 1rem;
  `;
}

export default styled.div`
  display: block;
  font-size: 0.75rem;
  text-align: center;
  padding: 0 1rem;
  @media ${breakpoints.tablet} {
    padding: 0 8rem;
  }
  ${isAbovePackagesStyle}
`;
