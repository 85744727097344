import styled from 'styled-components';

export const StyledVideoPlayer = styled.div`
  position: relative;
  z-index: 10;
  padding-top: 56.25%;

  .video-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
