import { SERVICE_PREORDER, SERVICE_UNAVAILABLE } from '@components/Organisms/Postcode/Postcode';
import { MDUStatus } from '@lib/types';

export const getIsCurrentPostcodePreorder = (postcodeItem) => {
  const { coverage } = postcodeItem || {};
  return Boolean(
    (postcodeItem?.uprn &&
      ['PASSED', 'DESIGN', 'INPROGRESS', 'STOPED', 'CERTIFIED'].includes(coverage)) ||
      (['CUSTOMER'].includes(coverage) && postcodeItem?.ismdu) ||
      (['PASSSERV'].includes(coverage) &&
        postcodeItem?.ismdu &&
        postcodeItem?.mdu_status !== MDUStatus.RFS) ||
      ['CERTIFIED_CUSTOMER'].includes(postcodeItem?.oss_type || ''),
  );
};

export const postcodeSubmitCallback = ({ data, successCallback, ineligibleCallback }) => {
  const { coverage } = data;

  const ismdu = data.ismdu;
  const mdu_status = data.mdu_status;
  const isPreorder = getIsCurrentPostcodePreorder(data) || (ismdu && mdu_status !== MDUStatus.RFS);

  if (SERVICE_UNAVAILABLE.includes(coverage) || SERVICE_PREORDER.includes(coverage) || isPreorder) {
    // show not available
    return ineligibleCallback();
  }
  return successCallback();
};
