import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from '@theme/breakpoints';

export default styled.span`
  font-size: ${rem(42)};
  font-weight: 600;
  color: #fff !important;
  font-family: var(--fonts-family-primary);
  line-height: 1;
  min-width: 2.5rem;
  @media ${breakpoints.mobilelg} {
    font-size: ${rem(48)};
  }
  @media ${breakpoints.tablet} {
    font-size: ${rem(64)};
  }
`;
