import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import { FormCheckBoxProps } from './types';
import { CheckBox } from '@components/Atoms';

export const FormCheckBox: FC<FormCheckBoxProps> = ({
  id,
  label,
  name,
  control,
  error,
  onClick,
  value: inputValue,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <CheckBox
          label={label}
          name={name}
          id={id}
          value={inputValue}
          checked={!!value}
          error={error}
          onClick={(checked) => {
            if (onClick) onClick(checked);
            onChange(checked ? inputValue : undefined);
            onBlur();
          }}
        />
      )}
    />
  );
};

FormCheckBox.displayName = 'FormCheckBox';
