import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  p {
    color: var(--colors-grey-50);
    margin: 1rem 0;

    @media (${breakpoints.tablet}) {
      text-align: left;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
    color: var(--colors-primary);
    font-family: var(--font-geomanist);
    font-weight: 500;
    max-width: 80%;
    align-self: center;

    @media (${breakpoints.tablet}) {
      text-align: left;
      align-self: flex-start;
    }
  }

  h2 {
    @media (${breakpoints.desktop}) {
      font-size: 2rem;
    }
  }
  h3 {
    @media (${breakpoints.desktop}) {
      font-size: 1.5rem;
    }
  }
  h4,
  h5,
  h6 {
    @media (${breakpoints.desktop}) {
      font-size: 1rem;
    }
  }

  strong {
    color: black;
  }

  ul {
    display: flex;
    text-align: left;
    flex-direction: column;
    color: var(--colors-grey-50);
  }

  li:before {
    content: '•';
    margin-right: 0.5rem;
  }
`;
