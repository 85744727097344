import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledVideoRelay = styled.section`
  position: relative;
  padding: 3rem 2rem;
  text-align: center;
  background: white;
  display: flex;
  justify-content: center;
  @media (${breakpoints.desktop}) {
    padding: 4rem 2rem;
  }
`;
