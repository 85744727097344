import { Cta } from '@components/Molecules';
import { convertToSlug } from '@lib/utils';
import { Download } from '@components/Atoms/Download';
import Image from 'next/image';
import { StyledCard } from './styled/StyledCard';
import { StyledLargeInfoCard } from './styled/StyledLargeInfoCard';
import { StyledTitle } from './styled/StyledTitle';
import { StyledLeftSide } from './styled/StyledLeftSide';
import { StyledRightSide } from './styled/StyledRightSide';
import { StyledBody } from './styled/StyledBody';
import { StyledButtons } from './styled/StyledButtons';
import { StyledContainer } from './styled/StyledContainer';

import { ButtonType } from '@components/Atoms/Button/types';

import { fromMarkdown } from '@lib/utils';

const LargeInfoCard = ({
  Title,
  Body,
  Link,
  DownloadFile,
  HasSecondDownloadButton,
  SecondDownloadFile,
  SecondDownloadLabel,
  Icon,
}) => {
  return (
    <StyledLargeInfoCard id={convertToSlug(Title)}>
      <StyledContainer>
        <StyledCard>
          <StyledLeftSide>
            <Image src={Icon.url} alt="Icon" width={80} height={80} quality={100} />
            <StyledTitle>{Title}</StyledTitle>
          </StyledLeftSide>
          <StyledRightSide>
            <StyledBody>{fromMarkdown({ text: Body, isHeading: false })}</StyledBody>
            <StyledButtons>
              <Download href={DownloadFile.url} ext={DownloadFile.ext} />
              {HasSecondDownloadButton ? (
                <Download
                  href={SecondDownloadFile.url}
                  ext={SecondDownloadFile.ext}
                  label={SecondDownloadLabel}
                />
              ) : (
                <Cta
                  display={true}
                  link={Link.link}
                  label={Link.label}
                  buttonType={ButtonType.PRIMARY}
                />
              )}
            </StyledButtons>
          </StyledRightSide>
        </StyledCard>
      </StyledContainer>
    </StyledLargeInfoCard>
  );
};

export { LargeInfoCard };
