import React, { FC } from 'react';
import { StyledModal } from './styled';

import { ModalProps } from './types';

export const Modal: FC<ModalProps> = ({ children, active = false, ...props }: ModalProps) => {
  return (
    <StyledModal active={active} {...props}>
      {children}
    </StyledModal>
  );
};
