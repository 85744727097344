import React, { useEffect, FC, useState } from 'react';

import { NeedAHandI } from './types';
import Image from 'next/image';

import Benny from '@public/Benny_phone.png';
import BennyMobile from '@public/Benny_phone_tall.png';
import styled from 'styled-components';
import { Button } from '@components/Atoms';
import { ButtonType } from '@components/Atoms/Button/types';
import { breakpoints } from '@theme/breakpoints';

const Wrapper = styled.article`
  background-color: var(--color-mid-blue);
`;

const Heading = styled.h3`
  color: var(--color-primary);

  @media (${breakpoints.tablet}) {
    font-size: 3.3rem;
  }
`;

export const NeedAHand: FC<NeedAHandI> = ({ heading, text, display }) => {
  const [tidioChatEnabled, setTidioChatEnabled] = useState(false);
  useEffect(() => {
    setTidioChatEnabled(!!window.tidioChatApi);
  });

  if (!display) {
    return null;
  }

  return tidioChatEnabled ? (
    <Wrapper className="sm:flex gap-4 justify-center px-4 sm:px-8 pt-12 relative md:pt-8">
      <div className="flex gap-4 justify-end items-center sm:gap-24 md:max-w-[60rem]">
        <div className="absolute -bottom-2 left-4 w-3/4 sm:relative">
          <div className="hidden sm:block">
            <Image src={Benny} alt="Benny holding phone waiting to chat" />
          </div>
          <div className="sm:hidden ">
            <Image src={BennyMobile} alt="Benny holding phone waiting to chat" />
          </div>
        </div>
        <div className="max-w-[50vw] sm:max-w-[16rem] md:max-w-[24rem]">
          <header>
            <Heading className="font-geomanist font-bold mb-4">{heading}</Heading>
          </header>
          <p className="text-[1.15rem] md:text-lg">{text}</p>
          <footer>
            <Button
              buttonType={ButtonType.PRIMARY}
              className="my-6"
              onClick={() => {
                window.tidioChatApi.show();
                window.tidioChatApi.open();
              }}
            >
              Chat to us
            </Button>
          </footer>
        </div>
      </div>
    </Wrapper>
  ) : null;
};
