import styled from 'styled-components';

export default styled.div<{ inline: boolean | undefined }>`
  > div {
    position: relative;
  }
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ inline }) => inline && `display: inline;`}
`;
