import React, { FC, useMemo } from 'react';
import useBasketContext from '@hooks/useBasketContext';
import usePromotionsContext from '@hooks/usePromotionsContext';
import usePostcodeContext from '@hooks/usePostcodeContext';
import {
  chooseBenefitTagByPoscode,
  chooseMonthlyPriceByPostcode,
  getMonthlyPriceCalculated,
  getOriginalPrice,
  getProductBenefitTag,
  getProductPromotion,
} from '@lib/utils';
import { BasketContentsI } from './BasketContentsInterface';

/**
 * HOC component for handling events from the CMS
 *
 * @param Component The component to enhance
 * @param handler The event handler action
 * @returns The enhanced component that handles the event
 */
export const WithBasketContents = ({ Component, props }) => {
  const { basketItem } = props;
  const { getAddonsProducts, promoCodes } = useBasketContext();
  const addonsProducts = getAddonsProducts();

  const {
    id_product,
    monthly_price,
    preorder_montly_price,
    display_benefit_tag,
    display_preorder_benefit_tag,
    display_original_price,
  } = basketItem || {};

  const { isCurrentPostcodePreorder, postcodeItem, addressType } = usePostcodeContext();
  const { promotions } = usePromotionsContext();
  const basketPromotions = promotions.filter((promo) =>
    promoCodes.find(({ code }) => code === promo?.code),
  );

  const installationFee = basketItem?.first_charge;

  const oneOffCharges: number = [basketItem, ...addonsProducts].reduce((prev = 0, next) => {
    return parseFloat(prev || 0) + parseFloat(next?.first_charge || 0);
  }, 0);

  const allProducts = useMemo(() => [basketItem, ...addonsProducts], [addonsProducts, basketItem]);

  const allProductsPromotions = useMemo(
    () =>
      allProducts.map((product) => {
        const promos = getProductPromotion({
          id_product: product?.id_product,
          coverage: postcodeItem?.coverage,
          promotions: basketPromotions,
          filterAuto: false,
        });
        const deductablePromo = Array.isArray(promos)
          ? promos.find(({ promotion_type }) => promotion_type === 'RECURAMTOFF')
          : promos;

        return deductablePromo;
      }),
    [allProducts, postcodeItem?.coverage, basketPromotions],
  );

  const monthlyPrice = useMemo(() => {
    return chooseMonthlyPriceByPostcode({
      postcodeItem,
      isCurrentPostcodePreorder,
      preorder_montly_price,
      monthly_price,
    });
  }, [postcodeItem, isCurrentPostcodePreorder, preorder_montly_price, monthly_price]);

  const benefitTag = useMemo(() => {
    return chooseBenefitTagByPoscode({
      postcodeItem,
      isCurrentPostcodePreorder,
      display_preorder_benefit_tag,
      display_benefit_tag,
    });
  }, [postcodeItem, isCurrentPostcodePreorder, display_preorder_benefit_tag, display_benefit_tag]);

  const monthlyCharges: number = useMemo(
    () =>
      allProducts
        .filter((product) => product)
        .reduce((prev = 0, next, index) => {
          const monthly_price = chooseMonthlyPriceByPostcode({
            postcodeItem,
            isCurrentPostcodePreorder,
            preorder_montly_price: next?.preorder_montly_price,
            monthly_price: next?.monthly_price,
          });

          const price = getMonthlyPriceCalculated({
            monthly_price,
            promo: allProductsPromotions[index],
            formatPrice: false,
            exclude_vat_price: next?.exclude_vat_price,
            addressType,
          });

          return prev + price;
        }, 0),
    [allProducts, allProductsPromotions, isCurrentPostcodePreorder, postcodeItem],
  );

  const formattedPrice = useMemo(
    () =>
      getMonthlyPriceCalculated({
        monthly_price: monthlyPrice,
        formatPrice: true,
        exclude_vat_price: basketItem?.exclude_vat_price,
        addressType,
      }),
    [monthlyPrice],
  );

  const benefit_tag = useMemo(() => {
    const promo = getProductPromotion({
      id_product,
      coverage: postcodeItem?.coverage,
      promotions: basketPromotions,
    });

    const deductablePromo = Array.isArray(promo)
      ? promo.find(({ promotion_type }) => promotion_type === 'RECURAMTOFF')
      : promo;

    return getProductBenefitTag({
      display_benefit_tag: benefitTag,
      promo: deductablePromo,
    });
  }, [id_product, benefitTag, postcodeItem?.coverage, basketPromotions]);

  const addonsOriginalFormattedPrices = useMemo(
    () =>
      addonsProducts.map((addon, index) =>
        getOriginalPrice({
          display_original_price: addon.display_original_price,
          monthly_price: addon.monthly_price,
          promo: allProductsPromotions[index + 1],
          formatPrice: true,
        }),
      ),
    [addonsProducts, allProductsPromotions],
  );

  const addonsMonthlyFormattedPrices = useMemo(
    () =>
      addonsProducts.map((addon, index) =>
        getMonthlyPriceCalculated({
          monthly_price: addon.monthly_price,
          promo: allProductsPromotions[index + 1],
          formatPrice: true,
        }),
      ),
    [addonsProducts, allProductsPromotions],
  );

  const originalBasketItemPrice = getOriginalPrice({
    display_original_price: display_original_price,
    monthly_price: monthly_price,
    formatPrice: true,
  });

  const productMonthlyCost =
    originalBasketItemPrice && originalBasketItemPrice !== monthlyPrice
      ? originalBasketItemPrice
      : formattedPrice;

  // const totalMonthlyCost = addonsMonthlyFormattedPrices.reduce((total, addon) => {
  //   const cost = typeof addon === 'string' ? +addon.replace('£', '') : addon;

  //   return +total + +cost;
  // }, +formattedPrice);

  const basketProps: BasketContentsI = {
    basketItem,
    oneOffCharges,
    installationFee,
    allProducts,
    allProductsPromotions,
    monthlyCharges,
    formattedPrice,
    benefit_tag,
    originalBasketItemPrice,
    addonsMonthlyFormattedPrices,
    productMonthlyCost,
    totalMonthlyCost: monthlyCharges,
    monthlyPrice,
    addonsProducts,
    addonsOriginalFormattedPrices,
    oneOffChargesDiscounted: oneOffCharges,
    promoCodes: promoCodes.filter((promo) => promo?.products?.includes(basketItem?.id_product)),
    benefitTag,
  };

  const WrappedComponent: FC = () => <Component {...props} {...basketProps} />;
  WrappedComponent.displayName = `WithBasketContents(${
    Component.displayName || Component.name || 'Component'
  })`;
  return WrappedComponent;
};
