import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledCarouselArrow = styled.button`
  display: block;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  font-weight: 800;

  @media ${breakpoints.tabletMax} {
    width: 30px;
    height: 30px;
  }
  &:before {
    font-size: 0;
    opacity: 1;
  }
  &.slick-prev {
    background: transparent linear-gradient(135deg, #0402fd 0%, #f4111b 100%) 0% 0% no-repeat
      padding-box;
    &:before {
      content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg width='20px' height='20px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.4142 20.7071C17.8047 20.3166 17.8047 19.6834 17.4142 19.2929L10.1213 12L17.4142 4.70712C17.8047 4.3166 17.8047 3.68343 17.4142 3.29291L16.7071 2.5858C16.3166 2.19528 15.6834 2.19528 15.2929 2.5858L6.93934 10.9394C6.35355 11.5251 6.35355 12.4749 6.93934 13.0607L15.2929 21.4142C15.6834 21.8048 16.3166 21.8048 16.7071 21.4142L17.4142 20.7071Z' fill='%23FFF'/%3E%3C/svg%3E");
    }
    @media ${breakpoints.tablet} {
      left: 0;
    }
  }
  &.slick-next {
    background: transparent linear-gradient(300deg, #0402fd 0%, #f4111b 100%) 0% 0% no-repeat
      padding-box;
    &:before {
      content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg width='20px' height='20px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.20696 20.7071C6.81643 20.3166 6.81643 19.6834 7.20696 19.2929L14.4998 12L7.20696 4.70712C6.81643 4.3166 6.81643 3.68343 7.20696 3.29291L7.91406 2.5858C8.30459 2.19528 8.93775 2.19528 9.32827 2.5858L17.6818 10.9394C18.2676 11.5251 18.2676 12.4749 17.6818 13.0607L9.32828 21.4142C8.93775 21.8048 8.30459 21.8048 7.91406 21.4142L7.20696 20.7071Z' fill='%23FFFFFF'/%3E%3C/svg%3E");
    }
    @media ${breakpoints.tablet} {
      right: 0;
    }
  }
`;
