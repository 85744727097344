import { Icon } from '@components/Atoms';
import { useState } from 'react';
import SearchIcon from '@public/icons/search_icon.svg';

export const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(query);
  };

  return (
    <form className="search-bar" onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <Icon xs icon={SearchIcon} />
    </form>
  );
};
