import styled from 'styled-components';

export default styled.ul`
  max-height: 14rem;
  overflow-y: scroll;

  scrollbar-color: var(--color-primary) var(--color-blue);

  &::-webkit-scrollbar {
    width: 0.5rem;
    background-color: var(--color-blue);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--color-blue);
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 1rem;
  }
`;
