import styled from 'styled-components';
import { FilterChipStyle } from '@components/Molecules/Accordion/styled/StyledAccordion';

export const StyledCoverageListing = styled.section`
  padding: 4rem 0;
  .coverage-listing {
    &__title {
      text-align: center;
      color: var(--color-primary);
    }

    &__text {
      &,
      p {
        font-family: var(--fonts-family-secondary);
        font-weight: 300;
        color: black;
        text-align: center;
        margin-bottom: 2rem;
      }
    }
    &__filter {
      ${FilterChipStyle};
    }
  }
`;
