import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';

const StyledChoosePackageBeforeImage = styled.div`
  display: none;
  @media ${breakpoints.desktopLarge} {
    display: block;
    position: absolute;
    z-index: 1;
    top: -18rem;
    left: -2rem;
    width: 25%;
    height: 18rem;
  }

  @media ${breakpoints.desktopXL} {
    left: -4rem;
  }
`;

export default StyledChoosePackageBeforeImage;
