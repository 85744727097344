import { breakpoints } from '@theme/breakpoints';
import styled, { css } from 'styled-components';

import { ToggleItemProps } from '../types';

export const StyledToggleItem = styled.button<ToggleItemProps>`
  grid-row: 1;
  min-width: 0;
  position: relative;

  &::before {
    ${({ selected }: ToggleItemProps) => {
      if (selected) {
        return css`
          content: '';
          position: absolute;
          inset: 5px;
          filter: blur(15px);
          border-radius: inherit;
          pointer-events: none;
          background: transparent linear-gradient(99deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
            padding-box;
        `;
      }
    }}
  }

  .toggle-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 27px;
    padding: 0.4rem 1.5rem;
    border-radius: 8px;
    color: var(--colors-black);
    ${({ selected }: ToggleItemProps) => {
      if (selected) {
        return css`
          background-color: var(--colors-white);
          color: var(--colors-black);
          font-weight: bold;
          img {
            fill: var(--colors-white);
          }
          transition: all 0.5s ease-in;
        `;
      }
    }}
  }
  .toggle-name {
    font-size: 0.813rem;
    .toggle-icon {
      margin-right: 0.3rem;
    }
    &:hover {
      font-weight: bold;
    }
  }
  .toggle-description {
    font-size: 0.6875rem;
    line-height: 0.75rem;
  }
`;
