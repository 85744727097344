import { TrustBoxData, WidgetType } from '../types';

export const mockTrustBoxData: TrustBoxData = {
  heading: 'What our customers say about us',
  type: WidgetType.MICROSTAR,
  display: true,
};

export const mockTrustBoxDataMini: TrustBoxData = {
  heading: '',
  type: WidgetType.MINI,
  display: true,
};
