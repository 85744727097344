/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FC, useEffect, useState } from 'react';

import usePostcodeContext from '@hooks/usePostcodeContext';

import { CmsEventSubscriber } from '@hoc/index';
import { match } from '@lib/utils';
import { Event } from '@lib/types';

import { Button } from '@components/Atoms';

import { CtaT } from './types';
import Router, { useRouter } from 'next/router';

const CtaWrapper: FC<any> = ({ children, onClick, link, buttonType, className }) => {
  if (onClick) {
    return (
      <Button buttonType={buttonType} className={className} onClick={onClick}>
        {children}
      </Button>
    );
  }

  // @todo This shouldn't be an anchor if the parent is an anchor
  // React shouts about it, but there are use-cases where the parent doesn't have
  // its own anchor so will revisit later.
  if (link) {
    return (
      <Button buttonType={buttonType} className={className} as="a" href={link || '#'}>
        {children}
      </Button>
    );
  }

  return children;
};

export const Cta: FC<CtaT> = ({
  event,
  display = true,
  label,
  link = '#',
  buttonType,
  className,
}) => {
  const [isEnhanced, setIsEnhanced] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    setIsEnhanced(true);
  }, []);

  if (!display) {
    return null;
  }

  const EnhancedCtaWrapper = match(event)
    .on(
      (event: any) => event === Event.GO_TO_PACKAGES,
      () =>
        CmsEventSubscriber(CtaWrapper, {
          onClick: () => {
            const productSelector = document.getElementById('product-selector');

            if (!productSelector) return;

            productSelector.scrollIntoView();
          },
        }),
    )
    .on(
      (event: any) => event === Event.POSTCODE_CHECKER,
      () =>
        CmsEventSubscriber(CtaWrapper, {
          onClick: () => router.push('/address-finder'),
        }),
    )
    .on(
      (event: any) => event === Event.EXTERNAL_LINK,
      () =>
        CmsEventSubscriber(CtaWrapper, {
          onClick: () => {
            Router.push(link);
          },
        }),
    )
    .otherwise(() =>
      CmsEventSubscriber(CtaWrapper, {
        link,
      }),
    );

  if (!isEnhanced) {
    return (
      <CtaWrapper buttonType={buttonType} className={className}>
        {label}
      </CtaWrapper>
    );
  }

  return (
    <EnhancedCtaWrapper buttonType={buttonType} className={className}>
      {label}
    </EnhancedCtaWrapper>
  );
};
