import { useState, FC } from 'react';
import styled, { css } from 'styled-components';

import { productController } from '@controllers/index';
import usePostcodeContext from '@hooks/usePostcodeContext';
import useBasketContext from '@hooks/useBasketContext';

import { InputType } from '@components/Atoms/Input/types';
import { ButtonType } from '@components/Atoms/Button/types';
import { ChipType } from '@components/Atoms/Chip/types';

import { Button, Chip, Input } from '@components/Atoms';
import * as gtag from '@lib/utils/gtag';
import useGAEvent from '@hooks/useGAEvent';

const StyledPromoText = styled.p`
  background-color: transparent;
  color: var(--colors-primary);
  font-weight: normal;
  text-decoration: none;
  padding: 0;
  font-size: 0.875rem;
  margin-top: 1rem;
  text-align: left;
  width: 100%;
  font-family: Geomanist, Segoe, 'Segoe UI', 'DejaVu Sans', 'Trebuchet MS', Verdana, 'sans-serif';
`;

const StyledPromoInput = styled.form`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0.5rem;
  justify-content: start;
  gap: 1rem;

  fieldset {
    min-width: 200px;
  }

  > *:first-child {
    flex: 1;
  }
`;

const StyledPromoCodeChipRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const PromoCode: FC<any> = () => {
  const [error, setHasError] = useState<string>('');
  const [promoCodeLocal, setPromoCodeLocal] = useState('');
  const { postcodeItem } = usePostcodeContext();
  const { basketItem, promoCodes, setPromoCode, removePromoCode } = useBasketContext();
  const gaEvent = useGAEvent();

  if (!postcodeItem) {
    return null;
  }

  //@promo-debug
  // Does the below variable contain the right promos based on the user journey?
  const eligiblePromoCodes = promoCodes.filter((promo) => {
    if (!promo?.products?.includes(basketItem)) {
      return false;
    }

    if (
      promo.uprn_status !== null &&
      !!postcodeItem?.coverage &&
      !promo.uprn_status.includes(postcodeItem?.coverage)
    ) {
      return false;
    }

    return true;
  });

  return (
    <div>
      <>
        <StyledPromoText>
          <span>Got {`${eligiblePromoCodes?.length ? 'another' : 'a'}`} promotion code?</span>
        </StyledPromoText>
        <StyledPromoInput
          onSubmit={async (event) => {
            //@promo-debug
            //Function which adds promo to the basket

            event.preventDefault();
            setHasError('');
            const promo = await productController.getPromoByCodeServer({
              code: promoCodeLocal,
              product: basketItem,
              postcode: postcodeItem?.postcode,
            });
            if (!promo) {
              setHasError('Promo code not valid');
              return;
            }

            const promoExists = !!promoCodes.find((p) => promo?.code === p?.code);
            if (promoExists) {
              setHasError('You have already entered this promo code');
              return;
            }

            gaEvent.send({
              action: 'select_promotion',
              parameters: {
                promotion_name: promo?.code,
              },
            });

            setPromoCode([...promoCodes, promo]);
          }}
        >
          <Input
            fullWidth
            type={InputType.TEXT}
            label="Type code"
            name="promo-code"
            placeholder="Type code"
            onChange={(event) => setPromoCodeLocal(event?.target?.value)}
          />
          <Button buttonType={ButtonType.PRIMARY} disabled={!promoCodeLocal}>
            Enter Code
          </Button>
        </StyledPromoInput>
      </>

      {error && (
        <StyledPromoCodeChipRow>
          <Chip chipType={ChipType.ERROR}>{error}</Chip>
        </StyledPromoCodeChipRow>
      )}
      {eligiblePromoCodes.map((promo) => (
        <StyledPromoCodeChipRow key={promo.id_promotion}>
          <Chip
            chipType={ChipType.PRIMARY}
            as={promo.auto ? 'div' : 'button'}
            onClick={() => {
              if (!promo.auto) {
                removePromoCode(promo);
              }
            }}
          >
            {promo.code} {!promo.auto ? 'x' : ''}
          </Chip>
          <Chip chipType={ChipType.SUCCESS}>Promo code applied</Chip>
        </StyledPromoCodeChipRow>
      ))}
    </div>
  );
};
