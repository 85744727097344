import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledContainer = styled.div`
  margin: 0 auto;
  padding: 1rem;
  background: transparent;
  @media (${breakpoints.desktop}) {
    padding: 0rem 4rem;
    margin: 0 2rem;
    max-width: 1200px;
  }
`;
