import React, { FC } from 'react';

/**
 * HOC component for handling events from the CMS
 *
 * @param Component The component to enhance
 * @param handler The event handler action
 * @returns The enhanced component that handles the event
 */
export const CmsEventSubscriber = (
  Component: FC<any>,
  handler: { onClick?: (arg0: any) => void; link?: string },
) => {
  return function cmsEventHandler(props: any) {
    const { onClick = null, link = null } = handler || {};

    if (onClick) {
      return <Component {...props} onClick={(event: any) => onClick(event)} />;
    }

    if (link && link !== '') {
      return <Component {...props} link={link} />;
    }

    return <Component {...props} />;
  };
};
