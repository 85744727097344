import styled from 'styled-components';

export const StyledBackToTop = styled.button`
  background-color: var(--colors-primary-8);
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: var(--colors-primary-15);
  }
`;
