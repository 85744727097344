import { FC, ReactElement } from 'react';
import { CtaT } from '@components/Molecules/Cta/types';

export interface FooterDataI {
  address?: string;
  linksColumns: LinkColumnI[] | never[];
  socials: SocialLinksT[] | never[];
  compareLink?: LinkItemT;
  copyright?: string;
  displayCard: boolean;
  cardTitle?: string;
  cardBody?: string;
  cardButton?: CtaT;
}

export interface LinkColumnI {
  heading: string;
  link: LinkItemT[];
}

export type LinkItemT = {
  label: string;
  link: string;
};

export type SocialLinksT = {
  label: string;
  link: string;
  icon: IconType;
};

export enum IconType {
  TWITTER = 'Twitter',
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  LINKEDIN = 'Linkedin',
}

export type FooterProviderT = {
  children: FC<any> | ReactElement<any>;
  footerData: FooterDataI;
};
