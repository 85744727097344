import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledLeft = styled.div`
  margin-bottom: 2rem;

  @media (${breakpoints.tablet}) {
    margin-bottom: 0;
    flex-basis: 50%;
    text-align: left;
    padding-right: 4rem;
  }
`;
