import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledTitle = styled.h2`
  width: 100%;
  color: var(--colors-primary);
  font-family: var(--font-geomanist);
  font-weight: 500;
  margin-top: 2rem;
  font-size: 2.5rem;

  @media (${breakpoints.tablet}) {
    max-width: 50%;
    text-align: left;
  }

  @media (${breakpoints.desktop}) {
    flex-direction: row;
    max-width: 80%;
  }
`;
