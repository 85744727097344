import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import ErrorIcon from '@public/icons/Featured-1.svg';

export const StyledErrorBar = styled.div`
  background: var(--colors-secondary-15) url('${ErrorIcon.src}') center right 0 no-repeat;
  border: 1px solid var(--colors-error);
  margin-bottom: 0;
  margin-top: 3rem;
  padding: 0.75rem 1rem;
  padding-right: 4rem;
  max-width: 30rem;
  border-radius: 0.5rem;
  color: var(--colors-secondary);
  font-weight: normal;
`;
