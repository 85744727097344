import React, { FC } from 'react';
import { Format, ImageDataT, ProductT } from '@lib/types';
import styled from 'styled-components';
import { fromMarkdown, renderImage } from '@lib/utils';
import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';
import { Icon } from '@components/Atoms';
import ImgBaseShadow from '@public/shadow.png';

interface CardColumnT {
  cards: Array<ColumnCardI>;
  display: boolean;
  title: string;
  bundleVariant2024Sept: string;
  bundleProducts: Array<ProductT>;
}

interface ColumnCardI {
  id: number;
  cardTitle: string;
  cardBody: string;
  cardSubBody: string;
  image: ImageDataT;
}

const StyledCardColumn = styled.section`
  background: var(--color-background-gradient);
  .column-title {
    text-align: center;
    margin-bottom: 3rem;
    font-size: ${rem(30)};
    color: var(--color-black);
    font-weight: bold;

    @media ${breakpoints.desktop} {
      font-size: ${rem(45)};
    }
  }

  .column-cards {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .column-button {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
`;

const StyledColumnCard = styled.div`
  background: var(--color-white);
  box-shadow: rgba(116, 116, 196, 0.4) 0px 5px 19px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: ${rem(20)};
  padding-top: ${rem(40)};
  max-width: ${rem(1046)};

  @media ${breakpoints.smallDesktop} {
    padding: ${rem(50)};
    flex-direction: row;
    align-items: center;
    gap: 2rem;

    &:nth-child(even) {
      flex-direction: row-reverse;
      .column-card__image {
        justify-content: right;
      }
    }
  }

  @media ${breakpoints.desktop} {
    align-items: flex-start;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    10%,
    30%,
    50%,
    70% {
      transform: translateY(-7.5px);
    }
    20%,
    40%,
    60%,
    80% {
      transform: translateY(0);
    }
    90% {
      transform: translateY(-7.5px);
    }
  }

  .column-card {
    &__img-container {
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;

      @media ${breakpoints.smallDesktop} {
        flex: 40%;
        padding-top: 0;
      }

      @media ${breakpoints.desktop} {
        align-self: center;
      }

      &--image {
        padding-top: ${rem(82)};

        @media ${breakpoints.smallDesktop} {
          padding-top: 0;
        }

        &:before {
          content: '';
          background: url(${ImgBaseShadow.src}) no-repeat bottom;
          background-size: contain;
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: 0;
          left: 0;

          @media ${breakpoints.tablet} {
            bottom: -${rem(82)};
          }
        }
      }
    }

    &__icon {
      object-fit: contain;
      position: relative;
      width: ${rem(200)};
      height: ${rem(200)};

      @media ${breakpoints.smallDesktop} {
        width: ${rem(250)};
        height: ${rem(250)};
        bottom: 0;
      }
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${rem(175)};
      margin: 0 auto;
      position: relative;
      bottom: ${rem(78)};

      animation: bounce 7s infinite;

      img {
        object-fit: contain;
      }

      @media ${breakpoints.smallDesktop} {
        justify-content: left;
        width: ${rem(200)};
        bottom: 0;

        & > span {
          width: 100% !important;
          height: 100% !important;
          max-height: 200px;
        }
      }
    }

    &__text {
      @media ${breakpoints.smallDesktop} {
        flex: 60%;
      }
    }

    &__title {
      font-size: ${rem(25)};
      font-weight: bold;
      color: var(--color-primary);
      margin-bottom: 0.5rem;
      margin-top: 2rem;

      @media ${breakpoints.desktop} {
        font-size: ${rem(35)};
        margin-top: 0;
      }
    }

    &__body {
      font-size: ${rem(20)};
      color: var(--color-black);
      margin-bottom: 0.5rem;

      a[href^='tel:'] {
        text-decoration: underline;
        color: var(--color-primary);
      }

      @media ${breakpoints.desktop} {
        font-size: ${rem(25)};
      }
    }

    &__sub-body {
      font-size: ${rem(17)};

      & ul {
        padding-left: 2rem;
        list-style-type: disc;
        color: var(--color-grey);
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }
`;

const ColumnCard: FC<ColumnCardI> = ({ image, cardTitle, cardBody, cardSubBody }) => {
  const isGif = image && image.url && image.url.includes('.gif');

  return (
    <StyledColumnCard className="column-card">
      {isGif ? (
        <div className="column-card__img-container column-card__img-container--icon">
          <Icon icon={image.url} altText={image.alternativeText} className="column-card__icon" />
        </div>
      ) : (
        <div className="column-card__img-container column-card__img-container--image">
          {renderImage({
            className: `column-card__image`,
            alternativeText: image.alternativeText,
            formats: image.formats,
            format: Format.MEDIUM,
          })}
        </div>
      )}

      <div className="column-card__text">
        <h3 className="column-card__title">{cardTitle}</h3>
        <p className="column-card__body">{fromMarkdown({ text: cardBody, isHeading: false })}</p>
        <div className="column-card__sub-body">
          {fromMarkdown({ text: cardSubBody, isHeading: false })}
        </div>
      </div>
    </StyledColumnCard>
  );
};

export const CardColumn: FC<CardColumnT> = ({ display, title, cards }) => {
  if (!display) return null;

  const ColumnCards = cards?.map((card, key) => {
    const Component = <ColumnCard key={key} {...card} />;

    return Component;
  });

  return (
    <StyledCardColumn id="whats-included" className="slice">
      <div className="flex-col slice__inner">
        <h2 className="column-title">{title}</h2>
        <div className="column-cards">{ColumnCards}</div>
      </div>
    </StyledCardColumn>
  );
};
