/* eslint-disable react/display-name */
import { StyledButton } from './styled';

export const Download = ({ label = null, href, ext }) => {
  return (
    <StyledButton className={`button button--primary`} href={href} download target="_blank">
      <div>
        {label ? label : `Download ${ext.toUpperCase().substring(1)}`}
        <div className="w-[0.8rem] ml-2">
          <svg fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 7h-3V0H6v7H3v1l5 5 5-5V7ZM14 14H2v2h12v-2Z"
              fill="#ffffff"
              className="fill-030708"
            ></path>
          </svg>
        </div>
      </div>
    </StyledButton>
  );
};
