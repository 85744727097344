import DOMPurify from 'isomorphic-dompurify';
import parse from 'html-react-parser';
import { marked } from 'marked';
import { match, wrapTags } from '@lib/utils';

/**
 * Parses a markdown string and returns a corresponding markup mapping
 *
 * @param {string} text The markdown string to parse
 * @param {boolean} isHeading Whether the text string is a heading or not
 * @returns The parsed markup
 */
export const fromMarkdown = ({ isHeading = true, text }: { isHeading?: boolean; text: string }) => {
  if (!isHeading) {
    const html = (text && marked.parse(text)) || '';
    const sanitized = DOMPurify.sanitize(html);

    return parse(sanitized);
  }

  const parsedText = match(text)
    .on(
      (text: string) => text?.match(/\*\*([^-\s].+?[^-\s])\*\*/),
      () =>
        wrapTags({
          text,
          regex: /\*\*([^-\s].+?[^-\s])\*\*/g,
          className: 'md__bold--blue',
        }),
    )
    .otherwise(() => text);

  return parsedText;
};
