import { NextRouter } from 'next/router';

const CHECKOUT_ROUTES = [
  '/address-finder',
  '/service-property-confirmation',
  '/service-available',
  '/service-preorder',
  '/checkout',
  '/extras',
  '/checkout/additional-details',
  '/checkout/installation',
  '/checkout/review-order',
  '/checkout/review-payment',
  '/checkout/payment',
];

const isCheckingOut = (router: NextRouter) => {
  if (!router) return false;

  // order conf and error pages
  if (router.pathname.includes('/checkout/order')) return true;

  return CHECKOUT_ROUTES.includes(router.pathname);
};

export default isCheckingOut;
