import React, { useState, FC, Key } from 'react';
import Link from 'next/link';

import { Card } from '@components/Molecules';
import { Button, Chip } from '@components/Atoms';

import { match, renderImage, renderParagraphs } from '@lib/utils';

import { ServiceStatus, Format, Layout, CoverageRegion } from '@lib/types';
import { CardType } from '@components/Molecules/Card/types';
import { CoverageListingItemT, CoverageListingT, FilterT } from './types';
import { ChipType } from '@components/Atoms/Chip/types';

import { StyledCoverageListing } from './styled';
import { StyledCoverageGrid } from './styled/StyledCoverageGrid';
import { StyledCoverageGridCard } from './styled/StyledCoverageGridCard';
import { ButtonType } from '@components/Atoms/Button/types';
import { StyledFilters } from './styled/StyledFilters';

export const CoverageListingItem: FC<CoverageListingItemT> = ({
  availability,
  thumbnail,
  name,
  excerpt,
  type = CardType.COVERAGE,
}) => {
  const block = `card-${CardType.COVERAGE}`;

  const serviceStatusStyle = match(availability)
    .on(
      (availability: ServiceStatus) => availability === ServiceStatus.AVAILABLE,
      () => `${block}__service-status--green`,
    )
    .on(
      (availability: ServiceStatus) => availability === ServiceStatus.BUILDING,
      () => `${block}__service-status--blue`,
    )
    .on(
      (availability: ServiceStatus) => availability === ServiceStatus.COLLECTING_INTEREST,
      () => `${block}__service-status--red`,
    )
    .on(
      (availability: ServiceStatus) => availability === ServiceStatus.COMING_SOON,
      () => `${block}__service-status--yellow`,
    )
    .otherwise(() => `hidden`);

  return (
    <Card type={type}>
      {renderImage({
        formats: [thumbnail],
        format: Format.SMALL,
        layout: Layout.FILL,
        className: `${block}__image`,
        alternativeText: name,
      })}

      <div className={`${block}__body ml-4`}>
        <div className={`${block}__title`}>{name}</div>
        <div className={`${block}__desc`}>{renderParagraphs({ body: excerpt })}</div>
        <div className={`${block}__cta`}>
          {availability === ServiceStatus.COLLECTING_INTEREST ? (
            <Button as="div" buttonType={ButtonType.PRIMARY} size="sm">
              Register interest
            </Button>
          ) : (
            <Button as="div" buttonType={ButtonType.PRIMARY} size="sm">
              Package selection
            </Button>
          )}
        </div>
        <span className={`${block}__service-status ${serviceStatusStyle} mb-4`}>
          {availability.replace(/([a-z])([A-Z])/, '$1 $2')}
        </span>
      </div>
    </Card>
  );
};

export const CoverageListing: FC<CoverageListingT> = ({ items, filters, heading, text }) => {
  const [listings, setListings] = useState(
    items.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
  );

  const [showListingIndex, setShowListingIndex] = useState(6);

  const showLoadMoreButton = listings.length > showListingIndex;

  const [listingFilters, setListingFilters] = useState(filters);

  const filterListings = ({ selectedRegion }: { selectedRegion: CoverageRegion }) => {
    const res =
      (selectedRegion !== CoverageRegion.ALL &&
        items?.filter(({ county }) => county === selectedRegion)) ||
      items;

    const updatedFilters = filters?.map(({ selected, tag, label }) => {
      if (selected) selected = false;
      if (tag === selectedRegion) selected = true;

      return { selected, tag, label };
    });

    setListingFilters(updatedFilters);
    setListings(res.sort((a, b) => a.name.localeCompare(b.name)));
  };

  return (
    <StyledCoverageListing className="container coverage-listing">
      <h2 className="coverage-listing__title">{heading}</h2>
      <div className="coverage-listing__text">{text}</div>
      <div className="flex flex-col justify-center mb-8 coverage-listing__filters md:items-center">
        <StyledFilters>
          {listingFilters?.map(({ label, tag, selected }: FilterT, key: Key) => {
            return (
              <Chip
                chipType={ChipType.COVERAGE}
                as="button"
                className={`coverage-listing__filter ${selected ? 'active' : ''}`}
                onClick={() => {
                  filterListings({ selectedRegion: tag });
                }}
                key={key}
              >
                {label}
              </Chip>
            );
          })}
        </StyledFilters>
      </div>
      <StyledCoverageGrid>
        {listings
          ?.slice(0, showListingIndex)
          .map(({ availability, image, slug, name, excerpt }, key: Key) => {
            const { formats } = image || {};
            const url =
              availability.availability === ServiceStatus.COLLECTING_INTEREST
                ? '/service-unavailable'
                : `/coverage/location/${slug}`;
            return (
              <StyledCoverageGridCard key={key}>
                <Link href={url}>
                  <a href={url}>
                    <CoverageListingItem
                      availability={availability.availability}
                      thumbnail={formats?.thumbnail}
                      slug={slug}
                      name={name}
                      excerpt={excerpt}
                    />
                  </a>
                </Link>
              </StyledCoverageGridCard>
            );
          })}
      </StyledCoverageGrid>
      {showLoadMoreButton && (
        <div className="flex justify-center mt-10">
          <Button
            buttonType={ButtonType.PRIMARY}
            onClick={() => {
              setShowListingIndex(showListingIndex + 6);
            }}
          >
            Load more
          </Button>
        </div>
      )}
    </StyledCoverageListing>
  );
};
