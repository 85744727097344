const light = {
  'background': '#fcfcfc',
  'primary': '#2C2CDE',
  'primary-bright': '#0000FF',
  'primary-30': '#B9C7F7',
  'primary-15': '#DCE3FB',
  'primary-8': '#ECF0FD',
  'primary-3': '#F8F9FE',
  'secondary': '#D8454E',
  'secondary-15': '#F9E3E4',
  'secondary-5': '#FDF6F6',
  'tertiary': '#039855',
  'success': '#039855',
  'gradient-primary': 'linear-gradient(180deg, #2C2CDE 0%, #D8454C 100%)',
  'gradient-secondary': 'linear-gradient(180deg, #2C2CDE 0%, #74449A 100%)',
  'gradient-tertiary': 'linear-gradient(360deg, #7C2890 0%, #B45D92 100%)',
  'gradient-tertiary-2': 'linear-gradient(140.22deg, #0000FF -113%, #D8454C 100%);',
  'grey-100': '#2E2E44',
  'grey-50': '#565661',
  'grey-30': '#D3D3DC',
  'white': '#fff',
};

export default light;
