import styled from 'styled-components';
import { DefaultHeroContent } from '@components/Molecules/Hero/variants/Default/styled';
import { breakpoints } from '@theme/breakpoints';

export const StyledHeroMSM = styled.div`
  background-image: var(--colors-gradient-secondary);
  &,
  p {
    color: var(--colors-white);
  }
  ${DefaultHeroContent};
  .hero-content {
    &__description {
      opacity: 0.8;
    }
  }
  padding-top: 11rem;
  @media ${breakpoints.desktop} {
    padding-top: 13rem;
  }
`;
