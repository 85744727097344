import { MDUStatus, UprnStatus } from '@lib/types';

export const chooseBenefitTagByPoscode = ({
  postcodeItem,
  isCurrentPostcodePreorder,
  display_preorder_benefit_tag,
  display_benefit_tag,
}) => {
  if (!isCurrentPostcodePreorder) {
    return display_benefit_tag;
  } else {
    if (postcodeItem?.ismdu === true && postcodeItem.coverage === UprnStatus.INSTALLED) {
      switch (postcodeItem?.mdu_status) {
        case MDUStatus.ADVANCED:
        case MDUStatus.RFS:
          return display_benefit_tag;
        case MDUStatus.STARTED:
        default:
          return display_preorder_benefit_tag;
      }
    } else {
      return display_preorder_benefit_tag;
    }
  }
};
