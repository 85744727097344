import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;

  @media (${breakpoints.mobilelg}) {
    flex-direction: row;
    gap: 1.5rem;
  }
`;
