import styled from 'styled-components';
import { rem } from 'polished';
import { Cta } from '@components/Molecules';

export const StyledButtonContainer = styled.span`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const StyledCta = styled(Cta)`
  width: fit-content;
`;
