import React, { FC, useEffect, useRef, useState } from 'react';
import Image from 'next/image';

import { fromMarkdown } from '@lib/utils';

import { CheckAvailabilityT } from './types';

import { StyledCheckAvailabilityBottomImageWrapper } from '@components/Organisms/CheckAvailability/styled/StyledCheckAvailabilityBottomImageWrapper';

import BottomRiderImage from '@public/bottom-benny-rider.png';
import { PostcodeSearch } from '@components/Molecules/PostcodeSearch';

export const CheckAvailability: FC<CheckAvailabilityT> = ({ heading, subHeading, display }) => {
  const [animationDirection, setAnimationDirection] = useState<'forward' | 'backward'>('backward');
  const animationElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function listener() {
      if (!animationElementRef.current) return;

      const windowTop = window.scrollY;
      const windowBottom = windowTop + window.innerHeight;

      const bottomTolerance = 150;
      const elementTop = animationElementRef.current.offsetTop;
      const elementBottom = elementTop + animationElementRef.current.clientHeight - bottomTolerance;

      if (elementTop >= windowTop && elementBottom <= windowBottom) {
        setAnimationDirection('forward');
      } else {
        setAnimationDirection('backward');
      }
    }

    window.addEventListener('scroll', listener);

    return () => window.removeEventListener('scroll', listener);
  }, []);

  if (!display) return null;

  return (
    <section className="flex flex-col pb-0 overflow-hidden slice">
      <div className="flex-col slice__inner">
        <div className="text-center">
          <h2 className="text-3xl slice__title">{fromMarkdown({ text: heading })}</h2>
          {subHeading && <h3 className="slice__subtitle">{fromMarkdown({ text: subHeading })}</h3>}
        </div>

        <div className="flex justify-center w-full">
          <PostcodeSearch />
        </div>
      </div>
      <StyledCheckAvailabilityBottomImageWrapper
        ref={animationElementRef}
        animationDirection={animationDirection}
      >
        <Image src={BottomRiderImage} alt="Rider" />
      </StyledCheckAvailabilityBottomImageWrapper>
    </section>
  );
};
