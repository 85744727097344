import React from 'react';

/**
 * Takes in a text string and checks against a regex pattern, if it finds any occurence of a match
 * then a span tag around it with a given className
 *
 * @param {string} text The original text string
 * @param {RegExp} regex The regex pattern
 * @param {string} className The class to add to the span tag
 * @returns The string with a span tag wrapped around it if the regex matches,
 *          else the original string
 */
export const wrapTags = ({
  text,
  regex,
  className,
}: {
  text: string;
  link?: string;
  regex: RegExp;
  className?: string;
}) => {
  const match = text?.match(regex);
  const textArray = text.split(regex);

  return textArray.map((str) => {
    if (match && match.find((res) => res.match(str))) {
      if (str === '') return;

      return (
        <span key={str} data-testid="test-span" className={className}>
          {str}
        </span>
      );
    }

    return str;
  });
};
