import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from '@theme/breakpoints';
import { Button } from '@components/Atoms';

export const StyledSpeedTestBlock = styled.div`
  background-color: var(--color-lightest-blue);
  .speed-test-block {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media ${breakpoints.desktop} {
        justify-content: center;
        flex-wrap: wrap;
        height: 36rem;
      }
    }

    &__title,
    &__body,
    &__dropdown,
    &__footnote {
      width: 100%;
      @media ${breakpoints.desktop} {
        width: 50%;
        padding: 0 4rem 0 0;
      }
    }

    &__title {
      order: 0;
    }
    &__body {
      order: 1;
    }
    &__dropdown {
      order: 3;
    }
    &__chart {
      order: 2;
      @media ${breakpoints.desktop} {
        order: 5;
      }
    }
    &__footnote {
      order: 4;
    }

    &__chart {
      display: flex;
      justify-content: center;
      margin-bottom: 3rem;
      margin-top: 2rem;
      width: 100%;
      @media ${breakpoints.desktop} {
        width: 50%;
        padding: 0 0 0 4rem;
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    &__dropdown {
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;
      & > select {
        background-color: #fff;
        margin-right: 1rem;
      }
    }

    &__footnote {
      color: var(--unnamed-color-55516094);
      text-align: left;
      font: normal normal normal 17px/25px Roboto;
      letter-spacing: 0px;
      color: #55516093;
      opacity: 1;
      margin: 0;
    }
  }

  .fast-path {
    transition: stroke-dashoffset 0.5s linear;
    &--animate {
      &--1 {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 23s linear;
      }
      &--2 {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 37s linear;
      }
      &--3 {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 3s linear;
      }
    }
  }

  .slow-path {
    transition: stroke-dashoffset 0.5s linear;
    &--animate {
      &--1 {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 460s linear;
      }
      &--2 {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 740s linear;
      }
      &--3 {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 60s linear;
      }
    }
  }
`;

export const SpeedChart = styled.div`
  position: relative;
  background-color: var(--color-lightest-blue);
  .chart {
    max-width: 577px;
    &__labels {
      position: absolute;
      left: 50%;
      transform: translate(-110%, -7%);
      @media ${breakpoints.tablet} {
        transform: translate(-110%, 0%);
      }
      @media ${breakpoints.desktopLarge} {
        transform: translate(-110%, -3%);
      }
    }
    &__label {
      display: block;
      font-family: var(--fonts-family-secondary);
      font-weight: normal;
      text-align: right;

      &--large {
        color: var(--colors-primary);
        padding-bottom: 0.5rem;
        @media ${breakpoints.mobileMax} {
          @media (orientation: landscape) {
            padding-bottom: 2.5rem;
          }
        }

        @media screen and (min-width: 350px) {
          padding-bottom: 1.5rem;
        }

        @media screen and (min-width: 450px) {
          padding-bottom: 2.2rem;
        }

        @media screen and (min-width: 500px) {
          padding-bottom: 3rem;
        }

        @media screen and (min-width: 600px) {
          padding-bottom: 3.8rem;
        }

        @media ${breakpoints.mobilelg} {
          padding-bottom: 4rem;
        }

        @media ${breakpoints.tablet} {
          padding-bottom: 3.5rem;
        }

        @media ${breakpoints.desktop} {
          padding-bottom: 2.3rem;
        }

        @media ${breakpoints.desktopLarge} {
          padding-bottom: 3.8rem;
        }
      }
      &--small {
        font-size: ${rem(14)};
        color: var(--colors-grey-50);
      }
    }
    &__path {
    }
  }
`;

export const StyledDropdown = styled.select`
  padding: ${rem(10)} ${rem(35)} ${rem(10)} ${rem(16)};
  border: 1px solid rgba(0, 0, 255, 0.3);
  border-radius: 8px;
  color: var(--colors-grey-100);
  line-height: 1.8;
  appearance: none;
  position: relative;
  background: url('/icons/Arrow-Down-dark.svg') no-repeat 98% 50% transparent;

  @media ${breakpoints.mobilelgMax} {
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  min-width: 90px;
`;
