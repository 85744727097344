/**
 * Global Content Controller
 *
 * @description Provides a link layer between the cms global content and the web application
 *
 */

import { getCoveragePages } from '@controllers/PageController/PageController';
import { getPostcodeList } from '@lib/utils';
import { logger, LOG } from '@lib/logger';

const root = process.env.CMS_ROOT_URL;

/**
 * Fetches the promo data from the cms api
 *
 * @returns {Promise<any>} The promo data
 */
export const getPromoData = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/promo-banner`);

    const data = await response.json();

    return data;
  } catch (err) {
    logger.warn(err, LOG.ERROR.CONTROLLER.GLOBAL_CONTENT.GET_PROMO_BANNER);

    return null;
  }
};

/**
 * Fetches the footer data from the cms api
 *
 * @returns {Promise<any>} The footer data
 */
export const getFooter = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/footer`);

    const data = await response?.json();

    return data;
  } catch (err) {
    logger.warn(err, LOG.ERROR.CONTROLLER.GLOBAL_CONTENT.GET_FOOTER);

    return null;
  }
};

export const getRegisterInterestData = async () => {
  const coveragePages = await getCoveragePages();

  return coveragePages.map(({ slug, rows }) => {
    const postcodes = rows?.find(({ type }) => type === 'registerInterestForm')?.postcodes || '';

    const postcodeList = getPostcodeList({ csv: postcodes });

    return {
      slug,
      postcodes: postcodeList,
    };
  });
};

/**
 * Fetches the sales countdown data from the cms api
 *
 * @returns {Promise<any>} The sales countdown data
 */
export const getSalesCountdownData = async (): Promise<any> => {
  try {
    const response = await fetch(`${root}/sales-countdown-component`);

    const data = await response.json();

    return data;
  } catch (err) {
    logger.warn(err, LOG.ERROR.CONTROLLER.GLOBAL_CONTENT.GET_SALES_COUNTDOWN);

    return null;
  }
};
