import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';

export default styled.div`
  display: flex;
  width: 100%;

  @media ${breakpoints.mobilelg} {
    width: fit-content;
  }

  span,
  p {
    color: #fff;
    font-family: var(--font-geomanist);
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
