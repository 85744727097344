/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FC } from 'react';
import Link from 'next/link';

import { Card, SectionHeading } from '@components/Molecules';
import { CardType } from '@components/Molecules/Card/types';

import { Format, Layout } from '@lib/types';
import { renderImage, renderComponents } from '@lib/utils';

import { SuggestionsT } from './types';

export const Suggestions: FC<SuggestionsT> = ({ heading, ctas }) => {
  if (!ctas?.length) return null;

  const SuggestionCards = ctas?.map(({ image, label, link, display = true }) => {
    if (!display) return;

    const { alternativeText, formats } = image || {};

    const block = `card-${CardType.RECOMMENDATION}`;

    const SuggestionCard = (
      <Link href={link}>
        <a>
          <Card type={CardType.RECOMMENDATION}>
            {renderImage({
              className: `${block}__image`,
              alternativeText,
              formats: formats,
              format: Format.LARGE,
              layout: Layout.RESPONSIVE,
            })}

            <span className={`${block}__link`}>{label}</span>
          </Card>
        </a>
      </Link>
    );

    return SuggestionCard;
  });

  const className = SuggestionCards.length > 1 ? 'lg:grid-cols-2' : 'lg:grid-cols-1';

  return (
    <>
      <section className="suggestions container mx-auto flex flex-col my-14">
        <SectionHeading heading={heading} />

        <div className={`flex flex-col lg:grid lg:gap-4 lg:items-center ${className}`}>
          {renderComponents({ components: SuggestionCards })}
        </div>
      </section>
    </>
  );
};
