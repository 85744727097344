import React, { FC, useState } from 'react';

import { ButtonType } from '@components/Atoms/Button/types';
import { StyledDropdown, StyledSpeedTestBlock, SpeedChart, StyledButton } from './styled';
import * as gtag from '@lib/utils/gtag';

import { DropDownItems, Props } from './types';
import useGAEvent from '@hooks/useGAEvent';

export const SpeedTestBlock: FC<Props> = ({ data }) => {
  const [selected, setSelected] = useState<number>(0);
  const [current, setCurrent] = useState<number>(0);
  const [reset, setReset] = useState<boolean>(true);
  const [start, setStart] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  const gaEvent = useGAEvent();

  const startAnimation = () => {
    if (start) {
      setReset(true);
      setStart(false);

      gaEvent.send({
        action: 'Speed Test Reset',
        parameters: { event_category: 'Speed Test' },
      });
    } else {
      setReset(false);
      setStart(true);

      gaEvent.send({
        action: 'Speed Test Start',
        parameters: { event_category: 'Speed Test' },
      });
    }
  };

  const getLabel = () => {
    if (!start) {
      return data?.cta?.label1;
    } else {
      return data?.cta?.label2;
    }
  };

  const DropDown: FC<any> = () => {
    const list = data.dropdown;

    return (
      <StyledDropdown
        onChange={(e: any) => {
          const val = parseInt(e.target.value);
          setSelected(val);

          if (val !== current) {
            setReset(true);
            setCurrent(val);
            setStart(false);
          }

          if (val === 0) {
            setDisabled(true);
            setReset(true);
            setStart(false);
          } else {
            setDisabled(false);
          }
        }}
        value={selected}
      >
        {list?.map((listItem: DropDownItems, index: number) => {
          return (
            <option key={listItem.label} value={index}>
              {listItem.label}
            </option>
          );
        })}
      </StyledDropdown>
    );
  };

  return (
    <StyledSpeedTestBlock className="slice speed-test-block">
      <div className="slice__inner speed-test-block__content">
        <h2 className="slice__title speed-test-block__title">{data.heading}</h2>
        <p className="slice__text speed-test-block__body">{data.body}</p>
        <div className="speed-test-block__dropdown">
          <DropDown />
          <StyledButton
            buttonType={ButtonType.PRIMARY}
            disabled={disabled}
            className="speed-test-block__start-button"
            onClick={startAnimation}
          >
            {getLabel()}
          </StyledButton>
        </div>
        <p className="speed-test-block__footnote">{data.smallPrint}</p>
        <div className="speed-test-block__chart">
          <SpeedChart className="w-full speed__chart chart">
            <div className="chart__labels">
              <span className="chart__label chart__label--large">{data.speedChart?.label1}</span>
              <span className="chart__label chart__label--small">{data.speedChart?.label2}</span>
            </div>
            <svg
              viewBox="0 0 577 577"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="px-4 mx-auto chart lg:px-0"
            >
              <path
                d="M12.2117 299.471C14.2043 350.365 30.2235 399.799 58.5986 442.266C88.9808 487.736 132.164 523.176 182.688 544.103C233.212 565.031 288.807 570.507 342.442 559.838C396.078 549.169 445.346 522.835 484.015 484.166C522.684 445.496 549.018 396.229 559.687 342.593C570.356 288.957 564.88 233.362 543.953 182.839C523.025 132.315 487.585 89.1315 442.115 58.7493C399.649 30.3742 350.215 14.355 299.32 12.3624"
                stroke="#DCE3FB"
                strokeWidth="23"
                strokeLinecap="round"
              />
              <path
                d="M12.2117 299.471C14.2043 350.365 30.2235 399.799 58.5986 442.266C88.9808 487.736 132.164 523.176 182.688 544.103C233.212 565.031 288.807 570.507 342.442 559.838C396.078 549.169 445.346 522.835 484.015 484.166C522.684 445.496 549.018 396.229 559.687 342.593C570.356 288.957 564.88 233.362 543.953 182.839C523.025 132.315 487.585 89.1315 442.115 58.7493C399.649 30.3742 350.215 14.355 299.32 12.3624"
                stroke="#2C2CDE"
                strokeWidth="23"
                strokeLinecap="round"
                strokeDasharray="1350"
                strokeDashoffset="-1280"
                className={`fast-path ${!reset ? `fast-path--animate--${current}` : ''}`}
              />
              <path
                d="M93.0714 296.225C94.0207 331.544 104.413 366.044 123.268 396.093C144.219 429.483 174.664 455.855 210.7 471.83C246.736 487.806 286.721 492.656 325.532 485.759C364.342 478.862 400.209 460.533 428.534 433.121C456.86 405.708 476.355 370.461 484.52 331.898C492.684 293.334 489.147 253.211 474.361 216.671C459.576 180.131 434.215 148.839 401.53 126.805C372.114 106.976 337.973 95.458 302.705 93.3519"
                stroke="#DCE3FB"
                strokeWidth="23"
                strokeLinecap="round"
              />
              <path
                d="M93.0714 296.225C94.0207 331.544 104.413 366.044 123.268 396.093C144.219 429.483 174.664 455.855 210.7 471.83C246.736 487.806 286.721 492.656 325.532 485.759C364.342 478.862 400.209 460.533 428.534 433.121C456.86 405.708 476.355 370.461 484.52 331.898C492.684 293.334 489.147 253.211 474.361 216.671C459.576 180.131 434.215 148.839 401.53 126.805C372.114 106.976 337.973 95.458 302.705 93.3519"
                stroke="#2C2CDE"
                strokeWidth="23"
                strokeLinecap="round"
                strokeDasharray="1350"
                strokeDashoffset="-914"
                className={`slow-path ${!reset ? `slow-path--animate--${current}` : ''}`}
              />
            </svg>
          </SpeedChart>
        </div>
      </div>
    </StyledSpeedTestBlock>
  );
};
