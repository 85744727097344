/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react';
import Link from 'next/link';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { init, send } from '@emailjs/browser';
import { StyledContactForm, StyledContactFormWrapper, StyledResponse } from './styled';
import { StyledInput } from '@components/Atoms/Input/styled';
import { Button, Icon } from '@components/Atoms';
import { Props } from './types';
import { renderImage, renderParagraphs } from '@lib/utils';
import { Format, Layout } from '@lib/types';
import { ButtonType } from '@components/Atoms/Button/types';
import SuccessIcon from '@public/icons/Featured.svg';
import FailureIcon from '@public/icons/Featured-1.svg';
import { FormCheckBox } from '@components/Atoms/CheckBox';
import { InputType } from '@components/Atoms/Input/types';
import { productController } from '@controllers/index';

init('user_kpWsSBhCezkaPPptoqvqP');
export const ContactForm: FC<Props> = ({ data }) => {
  const {
    formImage,
    name,
    city,
    phone,
    email,
    message,
    formHeading,
    submitButton,
    checkbox,
    successMessage,
    errorMessage,
    address,
    postcode,
    birthdate,
    emailTemplate,
    checkboxSubscription,
  } = data || {};
  const [responseMessage, setResponseMessage] = useState<string>(successMessage);
  const [showResponse, setShowResponse] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isSubscriptionChecked, setIsSubscriptionChecked] = useState<boolean>(false);
  const [responseIcon, setResponseIcon] = useState(SuccessIcon);

  const validation: any = {
    user_name: Yup.string().required(`${name.error}`),
    user_email: Yup.string()
      .required(`${email.error}`)
      .matches(/^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/),
    user_phone: Yup.string()
      .required(`${phone.error}`)
      .matches(/^(?:0|\+?44)(?:\d\s?){9,10}$/),
    user_agree: Yup.string().required(`${checkbox.error}`),
    user_subscription: Yup.string().required(`${checkbox.error}`),
  };

  if (city.display) {
    validation.user_city = Yup.string().required(`${city.error}`);
  }

  if (address?.display) {
    validation.user_address = Yup.string().required(`${address.error}`);
  }

  if (postcode?.display) {
    validation.user_postcode = Yup.string()
      .required(`${postcode.error}`)
      .matches(/^[a-zA-Z]{1,2}[0-9R][0-9a-zA-Z]? [0-9][a-zA-Z]{2}$/, {
        message: 'Invalid postcode format',
      })
      .test({
        name: 'isValidPostCode',
        test: async (value, ctx) => {
          if (value != undefined && value != '') {
            const postcodes = await productController.getUprnListServer({ postcode: value });
            return postcodes && postcodes.length > 0
              ? true
              : ctx.createError({ message: 'Postcode not found' });
          }
          return true;
        },
      });
  }

  if (birthdate?.display) {
    validation.user_birthdate = Yup.string()
      .required(`${birthdate.error}`)
      .matches(/^(19\d\d|20[0-2]\d)-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/);
  }

  if (message.display) {
    validation.user_message = Yup.string().required(`${message.error}`);
  }

  const validationSchema = Yup.object().shape(validation);

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<IFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const ResponseMessage = () => {
    return (
      <StyledResponse>
        <div className="mb-8">
          <Icon lg icon={responseIcon} />
        </div>
        <h3>{responseMessage}</h3>
      </StyledResponse>
    );
  };

  const onSubmit = (data) => {
    const service = process.env.NEXT_PUBLIC_EMAIL_SERVICE || 'service_2cgv65g';
    const user = process.env.NEXT_PUBLIC_EMAIL_SERVICE_USER;

    send(service, emailTemplate, data, user).then(
      // todo: fix this - not always returning response even though returning 200 and email sending
      (response) => {
        setShowResponse(true);
        // setResponseIcon(SuccessIcon);
        // setResponseMessage(successMessage);
        // reset();
      },
      (error) => {
        setShowResponse(true);
        setResponseIcon(FailureIcon);
        setResponseMessage(errorMessage);
      },
    );
    reset();
    setShowResponse(true);
    window.location.href = '#contact_card';
  };

  interface IFormInputs {
    user_name: string;
    user_email: string;
    user_city: string;
    user_phone: string;
    user_message: string;
    user_agree: boolean;
    user_address: string;
    user_postcode: string;
    user_birthdate: string;
    user_subscription: boolean;
  }

  return (
    <StyledContactFormWrapper className="container lg:w-4/5 mx-auto mb-20">
      {formHeading && <h1 className="large mb-8">{formHeading}</h1>}
      <div className="flex flex-col lg:flex-row">
        <div className="pb-8 lg:w-1/2 lg:pb-0">
          {!showResponse && (
            <StyledContactForm onSubmit={handleSubmit(onSubmit)}>
              {name && (
                <>
                  <StyledInput fullWidth>
                    <input placeholder={name.label} type={name.type} {...register('user_name')} />
                    {errors.user_name && <p className="error">{name.error}</p>}
                  </StyledInput>
                </>
              )}
              {email && (
                <>
                  <StyledInput fullWidth className="mt-4">
                    <input
                      placeholder={email.label}
                      type={email.type}
                      {...register('user_email')}
                    />
                    {errors.user_email && <p className="error">{email.error}</p>}
                  </StyledInput>
                </>
              )}
              {birthdate?.display && (
                <>
                  <StyledInput fullWidth className="mt-4">
                    <input
                      placeholder={birthdate.label}
                      type={InputType.TEXT}
                      {...register('user_birthdate')}
                      onBlur={(e) => {
                        if (e.target.value) {
                          const date = new Date(e.target.value);
                          e.target.value = date.toLocaleDateString('en-GB', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          });
                        }
                      }}
                    />
                    {errors.user_birthdate && <p className="error">{birthdate.error}</p>}
                  </StyledInput>
                </>
              )}
              {phone && (
                <>
                  <StyledInput fullWidth className="mt-4">
                    <input
                      placeholder={phone.label}
                      type={phone.type}
                      {...register('user_phone')}
                    />
                    {errors.user_phone && <p className="error">{phone.error}</p>}
                  </StyledInput>
                </>
              )}
              {city.display && (
                <>
                  <StyledInput fullWidth className="mt-4">
                    <input placeholder={city.label} type={city.type} {...register('user_city')} />
                    {errors.user_city && <p className="error">{city.error}</p>}
                  </StyledInput>
                </>
              )}
              {address?.display && (
                <>
                  <StyledInput fullWidth className="mt-4">
                    <input
                      placeholder={address.label}
                      type={address.type}
                      {...register('user_address')}
                    />
                    {errors.user_address && <p className="error">{address.error}</p>}
                  </StyledInput>
                </>
              )}
              {postcode?.display && (
                <>
                  <StyledInput fullWidth className="mt-4">
                    <input
                      placeholder={postcode.label}
                      type={postcode.type}
                      {...register('user_postcode')}
                    />
                    {errors.user_postcode && (
                      <p className="error">{errors.user_postcode.message}</p>
                    )}
                  </StyledInput>
                </>
              )}
              {message.display && (
                <>
                  <StyledInput fullWidth className="mt-4">
                    <textarea placeholder={message.label} {...register('user_message')}></textarea>
                    {errors.user_message && <p className="error">{message.error}</p>}
                  </StyledInput>
                </>
              )}
              {/* {inputFields &&
              inputFields.map((input, index) => {
                return (
                  <>
                    <StyledInput fullWidth className="mt-4">
                      <input
                        // fullWidth
                        // textArea={input.textArea}
                        key={input.label}
                        placeholder={input.label}
                        type={input.type}
                        {...register(`user_test.${index}` as const, {
                          required: true,
                          pattern: getRegexPattern(input.type),
                        })}
                      />
                      {errors.exampleRequired && <span>This field is required</span>}
                    </StyledInput>
                  </>
                );
              })} */}

              {checkbox && (
                <StyledInput className="mt-4">
                  <FormCheckBox
                    id={checkbox.id}
                    onClick={() => setIsChecked(!isChecked)}
                    name="user_agree"
                    control={control}
                    value={checkbox.value}
                    label={
                      <>
                        I agree to{' '}
                        <Link href="/legal" passHref>
                          <a>Terms & Conditions</a>
                        </Link>
                      </>
                    }
                  />
                </StyledInput>
              )}
              {checkboxSubscription && (
                <StyledInput className="mt-4">
                  <FormCheckBox
                    control={control}
                    id={checkboxSubscription.id}
                    onClick={() => setIsSubscriptionChecked(!isSubscriptionChecked)}
                    name="user_subscription"
                    value={checkboxSubscription.value}
                    label={renderParagraphs({
                      body: checkboxSubscription.label ? checkboxSubscription.label.toString() : '',
                      hasMarkdown: true,
                    })}
                  />
                </StyledInput>
              )}
              {submitButton && (
                <Button
                  className="mt-8"
                  disabled={!isChecked || !isSubscriptionChecked}
                  buttonType={ButtonType.PRIMARY}
                  type="submit"
                >
                  {submitButton.label}
                </Button>
              )}
            </StyledContactForm>
          )}
          {showResponse && <ResponseMessage />}
        </div>
        <div className="lg:w-1/2 relative">
          {formImage &&
            renderImage({
              className: `contact-form__image`,
              alternativeText: formImage.alternativeText,
              formats: formImage.formats,
              format: Format.LARGE,
              layout: Layout.RESPONSIVE,
            })}
        </div>
      </div>
    </StyledContactFormWrapper>
  );
};
