import { createGlobalStyle } from 'styled-components';
import generateGlobalStyle from './generateTheme';
import colors from './colors';
import typography from './typography';
import { breakpoints } from './breakpoints';

// Global Styles generated from theme properties and hard coded values

export const themeProperties = {
  colors: colors['dark'],
  fonts: typography,
  breakpoints: breakpoints,
};

export const Theme = createGlobalStyle`
  // Generate CSS variables
  :root {
    ${generateGlobalStyle(themeProperties)}
  }
  // resets and default webfonts
  html,
  body {
    padding: 0;
    margin: 0;
    color: var(--colors-grey-100);
    font-family: BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: var(--fonts-sizing-primary);
    font-family: var(--fonts-family-primary);
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
    scroll-behavior: smooth;
    &.no-scroll {
      overflow: hidden;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  * {
    box-sizing: border-box;
  }
  p {
    color: var(--colors-grey-50);
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
  }

  // Default typography
  h1, .xx-large {
    font-size: var(--fonts-sizing-h1Small);
    font-family: var(--fonts-family-secondary);
    line-height: 1.2;

    @media ${breakpoints.desktop} {
      font-size: var(--fonts-sizing-h1Large);
    }
  }
  h2, .x-large {
    font-size: var(--fonts-sizing-h2Small);
    font-family: var(--fonts-family-secondary);
    line-height: 1.2;
    @media ${breakpoints.desktop} {
      font-size: var(--fonts-sizing-h2Large);
    }

  }
  h3, .large {
    font-size: var(--fonts-sizing-h3Large);
    font-family: var(--fonts-family-secondary);
    line-height: 1.2;
    font-weight: normal;
  }
  h4, .medium {
    font-size: var(--fonts-sizing-h4);
    font-family: var(--fonts-family-secondary);
    line-height: 1.3;
  }
  h5, .small {
    font-size: var(--fonts-sizing-h5);
    font-family: var(--fonts-family-secondary);
    line-height: 1.3;
  }
  h6, .x-small {
    font-size: var(--fonts-sizing-h6);
    font-family: var(--fonts-family-secondary);
    line-height: 1.6;
  }

  .xx-small {
    font-size: var(--fonts-sizing-primary);
    font-family: var(--fonts-family-secondary);
    font-weight: bold;
  }

  p, .body {
    font-size: var(--fonts-sizing-primary);
    font-family: var(--fonts-family-primary);
    font-weight: normal;
    line-height: 1.5;
  }

  canvas {
    width: 100% !important;
    height: 100% !important;
    margin: 0;
  }

  .md {
    &__bold {
      font-weight: 600;
      &--blue {
        color: var(--colors-primary);
      }
    }
  }
  div#CybotCookiebotDialog {
    scale: 65%;
    @media ${breakpoints.desktop} {
      scale: 100%;
    }
  }
`;
