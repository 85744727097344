import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledLogo = styled.div`
  position: relative;
  display: none;
  @media ${breakpoints.desktop} {
    display: block;
    width: 130px;
    height: 50px;
  }
`;

export const StyledMobileLogo = styled.div`
  display: block;
  width: 64px;
  height: 40px;
  align-self: flex-start;
  span {
    position: relative !important;
    img {
      position: relative !important;
      height: unset !important;
      width: unset !important;
    }
  }
  @media ${breakpoints.desktop} {
    display: none;
  }
`;
