import React, { FC } from 'react';

import { renderParagraphs } from '@lib/utils';

import { TextElementProps } from './types';
import { StyledTextElement } from './styled';

export const TextElement: FC<TextElementProps> = ({ text }) => {
  return (
    <StyledTextElement>{renderParagraphs({ body: text, hasMarkdown: true })}</StyledTextElement>
  );
};
