import { FC } from 'react';
import Script from 'next/script';

export function saveConversionTrackerRequestId(): void {
  const urlParams = new URLSearchParams(location.search);
  const requestId = urlParams.get('dtt_reqid');

  if (!requestId) return;

  const expDays = 45;
  const d = new Date();
  d.setTime(d.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = `_dtt=${requestId};${expires};domain=${location.hostname};path=/`;
}

export function getConversionTrackerRequestId(): string | null {
  return document.cookie.match('(^|;)\\s*_dtt\\s*=\\s*([^;]+)')?.pop() || null;
}

export function storeAWCCookieForAWIN(value): void {
  const d = new Date();
  d.setTime(d.getTime() + 60 * 60 * 24 * 365);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = `awc=${value};${expires};domain=${window.location.hostname};path=/`;
}

// get AWC cookie
export function getAWCCookie(): string {
  return document.cookie.match('(^|;)\\s*awc\\s*=\\s*([^;]+)')?.pop() || '';
}

interface ConversionDoneScriptT {
  orderNumber?: string | null;
  productId?: string | null;
}

export const ConversionDoneScript: FC<ConversionDoneScriptT> = ({ orderNumber, productId }) => {
  if (!orderNumber || !productId) return null;

  const reqId = getConversionTrackerRequestId();

  return null;

  // return (
  //   <Script
  //     src={`https://affsecure.com/p.ashx?a=${process.env.NEXT_PUBLIC_CONVERSION_TRACKER_ADVERTISER_ID}&t=${orderNumber}&f=js&ecsk=${productId}&ecqu=1&ecpr=0&ect=0&r=${reqId}`}
  //     strategy="afterInteractive"
  //   />
  // );
};
