const recaptchaCallback = ({ token, onError, onSuccess }) => {
  if (token) {
    fetch('/api/verify-recaptcha', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: token }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (window.location.host === 'localhost:3000') {
          onSuccess();
          return;
        }

        if (!res.success) {
          console.error('Recaptcha not verified');
          return onError();
        }

        if (res.score < 0.7) {
          console.error('Recaptcha score too low');
          return onError();
        }

        onSuccess();
      });
  } else {
    console.error('Recaptcha not verified');
    return onError();
  }
};

export default recaptchaCallback;
