import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const StyledContactDetailsAlternate = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-items: center;

  @media ${breakpoints.tablet} {
    flex-direction: row;
    gap: 3rem;
  }

  p {
    color: var(--colors-primary);
  }
`;
